.profile-container {
  overflow-y: auto;
  .my-profile-delete-btn {
    width: 8.4375rem;
    height: 2.25rem;
    color: $red-12;
    border-color: $red-12;

    span {
      white-space: nowrap;
    }

    &:hover,
    &:active,
    &:focus {
      color: $red-12;
      border-color: $red-12;
      background-color: $red-17;
    }
  }

  .alert {
    margin-top: 1rem;
    margin-bottom: 0 !important;
  }

  .form-container form {
    margin: 0rem 0 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.75rem;

    .input-with-cta-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 1.25rem;

      width: 100%;

      .shd-button.change-email-btn {
        height: 2.25rem;
        color: $gray-15;
        border-color: $gray-13;

        &.password-btn {
          width: 9.625rem;
        }

        span {
          white-space: nowrap;
        }

        &:hover,
        &:active,
        &:focus {
          color: #1d4ed8;
          background-color: transparent;
          border-color: #1d4ed8;
        }
      }
    }

    .timezone-wrapper {
      .bs-select-box {
        gap: 0.5rem;
        width: 18.75rem !important;

        .bs-select-box-icon-wrapper {
          margin: 0 !important;
          justify-content: center;
          color: $gray-11;
        }
      }
    }

    .my-profile-submit-btn {
      margin-top: 0.5rem;
    }
  }
  .change-password {
    padding-top: 1rem;
    .btn-solid-link {
      padding: 0 !important;
    }
  }

  .danger-zone-header {
    display: flex;
    flex-direction: column;

    padding-top: 1.25rem;
    border-radius: 0.0625rem;
    border-top: 0.125rem solid $gray-13;

    h1 {
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: $font-weight-semibold;
      line-height: 1.25rem;
      margin: 0;
      margin-bottom: 0.25rem;
    }
    p {
      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1.125rem;
    }
  }
}

.my-profile-input-container {
  width: 100%;
}

.my-profile-input {
  min-height: 3.5rem;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-bottom: 0 !important;

  &.bs-input-container {
    max-width: 18.75rem !important;
  }

  &.modal-input {
    max-width: 100% !important;
    .bs-input-affix-wrapper {
      max-width: 100% !important;
    }
  }

  .bs-input-label-prefix {
    color: $gray-15 !important;
    font-size: 0.75rem !important;
    font-weight: $font-weight-medium;
    line-height: 1rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bs-input-affix-wrapper,
  .bs-select-box {
    max-width: 18.75rem !important;
    height: 2rem;

    padding: 0.375rem 1rem;

    border-radius: 0.25rem;
    border: 0.0625rem solid $gray-13;
    background: $white;

    .bs-input,
    .bs-select-box-text {
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: $font-weight-normal;
      line-height: 1.25rem;
      background-color: transparent !important;
    }

    &:focus,
    &:focus-within {
      outline: none !important;
      box-shadow: none !important;
      border-color: $blue-11;
    }

    &.input-error {
      &:focus,
      &:focus-within {
        border-color: $red-20;
      }
    }

    &.bs-input-affix-wrapper-disabled {
      background: $gray-19;
      .bs-input {
        color: $gray-11;
      }
    }
  }

  .bs-input-label-suffix {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: $red-12 !important;
    font-size: 0.75rem !important;
    font-weight: $font-weight-medium;
    line-height: 1.125rem !important;
    margin-top: 0.25rem !important;
  }
}

.my-profile-input-error {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: $red-12 !important;
  font-size: 0.75rem !important;
  font-weight: $font-weight-medium;
  line-height: 1.125rem !important;
  margin-top: 0.25rem !important;
}

.link-cta-btn {
  height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  display: inline-flex;

  &.link-inline {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: $font-weight-medium !important;
  }
}

.timezone-select {
  .transfer-container .transfer-body .transfer-content {
    max-height: 21.75rem;
    min-height: 8.75rem;
    height: calc(100vh - 38rem);
  }
}

.change-email-modal {
  .modal-dialog {
    width: 28.125rem !important;
    max-width: unset;
    min-height: auto;
    margin-top: 9rem;
    margin-bottom: 0;
  }
  .bs-modal-body {
    padding: 0 !important;
  }

  &--title {
    padding: 1.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.25rem 0.25rem 0 0;
    border: 0.125rem solid $gray-16;
    border-bottom: none;

    h1 {
      color: $blue-15;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: $font-weight-bold;
      margin: 0;
    }

    .delete-account-title {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      .delete-account-title-icon {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: 100%;
          color: $red-12;
        }
      }
      h1 {
        font-weight: $font-weight-semibold;
      }
    }
  }

  &--description {
    padding: 0 1.5rem 1.25rem 1.5rem;

    display: flex;
    align-items: center;

    border: 0.125rem solid $gray-16;
    border-top: none;
    border-bottom: none;
  }

  &--title,
  &--description {
    p {
      color: $blue-15;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin: 0;

      span.bold {
        font-weight: $font-weight-semibold;
      }
    }
  }

  .alert-container {
    padding: 0 1.5rem 1.25rem 1.5rem;
    .alert {
      width: 25.125rem;
      height: 3.375rem;

      display: flex;
      gap: 0.5rem;

      border-radius: 0.25rem;
      background: $yellow-11;
      border: 0.0625rem solid $orange-18 !important;

      margin: 0;
      padding: 0.5rem 1rem;

      &--icon {
        width: 1rem;
        height: 1.25rem;

        display: flex;
        justify-content: center;
        align-items: center;
        color: $orange-12;
      }
      &--content {
        color: $orange-19;
        font-size: 0.75rem;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;

        margin: 0;
      }
    }
  }

  &--action {
    display: flex;
    align-items: center;

    padding: 0 1.5rem 1.5rem 1.5rem;

    border: 0.125rem solid $gray-16;
    border-top: none;
    border-bottom: none;
  }

  &--divider {
    display: flex;
    align-items: center;

    width: 25.125rem;
    height: 0.125rem;

    border-radius: 0.25rem;
    margin: 0 24px;

    background-color: $gray-16;
  }

  &--footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    height: 4.5rem;
    padding: 1.25rem 1.5rem;

    border-radius: 0 0 0.25rem 0.25rem;
    border: 0.125rem solid $gray-16;
    border-top: none;

    .shd-button--secondary {
      width: 6.25rem;
      color: $gray-15;
      border-color: $gray-13;

      &:hover,
      &:active,
      &:focus {
        color: #1d4ed8;
        background-color: transparent;
        border-color: #1d4ed8;
      }
    }
  }

  .otp-input {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
  }

  .otp-input-label {
    color: $gray-15;
    text-align: center;
    font-size: 0.75rem;
    font-weight: $font-weight-medium;
    line-height: 1rem;
    margin: 0;
  }

  .otp-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.9375rem;

    input {
      width: 2.25rem;
      height: 2.25rem;

      border-radius: 0.25rem;
      border: 0.0625rem solid $gray-13;
      background: $white;

      display: flex;
      justify-content: center;
      align-items: center;

      color: $blue-15;
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.25rem;

      &:hover,
      &:active,
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $blue-11;
      }
    }

    &.error {
      input {
        border-color: $red-20;

        &:hover,
        &:active,
        &:focus {
          border-color: $red-20;
        }
      }
    }
  }
  .otp-input-error {
    color: $red-12;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    margin: 0;
  }
}
.delete-account-email {
  font-weight: bold;
}

.delete-oauth-modal {
  .btn-solid-primary {
    background-color: $red-12;
    border: $red-12;
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
      background-color: $red-12;
      border: $red-12;
    }
  }
}

.oauth-modal-title {
  font-size: $text-14;
  line-height: $text-style-1-line-height;
  font-weight: $font-weight-normal;
  &--bold {
    font-weight: $font-weight-semibold;
  }
}

.oauth-change-email-modal {
  .modal-header {
    min-height: auto !important;
  }
  .oauth-change-email-modal-content {
    .oauth-buttons-container {
      .oauth-button {
        &.microsoft-button {
          margin-bottom: 0rem !important;
        }
      }
    }
  }
}
