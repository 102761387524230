.unfiend-inbox-alert-modal {
  &.modal-dialog {
    width: 100% !important;
  }
  .modal-dialog {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    min-height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto !important;
    position: relative;
  }
  .modal-content {
    height: auto !important;
    width: 28.125rem !important;
    position: absolute;
    .modal-content-container {
      height: fit-content;
      .modal-header {
        min-height: 0 !important;
        border-bottom: none;
        padding: 0px 1.5rem;
        position: relative;
      }
      button.close {
        padding: 0;
        position: absolute;
        top: 0.9375rem;
        right: 1.875rem;
      }
      .unfiend-inbox-alert-modal--title {
        padding-top: 1.5rem;
        display: flex;
        align-items: start !important;
        gap: 0.625rem;
        &__icon {
          color: $blue-11;
          line-height: 1.5rem !important;
          font-size: 1.5rem !important;
          width: 1.5rem !important;
          height: 1.5rem !important;
        }
        h2 {
          line-height: 1.5rem !important;
          align-items: center !important;
          margin-bottom: 0 !important;
          padding: 0 !important;
          font-size: 1rem;
          font-weight: 600;
          color: $blue-15;
        }
      }
    }
    .bs-modal-body {
      padding: 1rem 1.5rem 1.5rem 1.5rem !important;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $blue-15;

      .unfiend-inbox-alert-modal--body__contents {
        p {
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    .client-header {
      .title {
        font-size: 1rem;
        font-weight: 600;
        color: $blue-15;
      }
      margin-bottom: 1rem;
    }
    .agency-sub-title-two {
      margin-top: 1rem;
    }
    .bs-modal-footer {
      min-height: 4.5rem;
      max-height: 4.5rem;
      .btn-ghost {
        border: 1px solid $gray-13 !important;
        line-height: 0;
        width: 6.25rem;
        padding-top: 0.2rem !important;
      }
      .btn-solid {
        width: auto;
        min-width: 6.25rem;
      }
      .btn-loading.btn-solid-primary:disabled {
        background-color: $blue-12 !important;
        border-color: $blue-12 !important;
      }
      .action-btn-unified.btn-solid-primary {
        background-color: $blue-11;
        border-color: $blue-11;
        border-radius: 4px;
        height: 2rem;

        &:hover,
        &:focus,
        &:active,
        &.btn-solid-primary:not(:disabled):not(.disabled):active {
          border-color: $blue-20;
          background-color: $blue-20;
        }
        &.btn-solid-primary:not(:active):not(.active):disabled {
          border-color: $blue-12;
          background-color: $blue-12;
          color: #ffffff;
        }
      }
      .action-btn-cancel.btn-ghost-outlined {
        color: #6b7280;
        border-radius: 4px;
        border: none;
        height: 2rem;

        font-weight: $font-weight-medium;
        font-size: 0.875rem;
        line-height: 1.5rem;

        &:hover,
        &:focus,
        &:active,
        &.btn-ghost-outlined:not(:disabled):not(.disabled):active {
          color: #6b7280;
          background-color: #f3f4f6;
        }
      }
    }
  }
  .custom-jsx-element {
    margin-top: 1rem;
  }
}
