@import './components/create-email-infrastructure/create-email-infrastructure';
@import './components/email-accounts-content/email-account-content';

.email-accounts-container {
  padding: 0;
  height: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  height: 100%;
  background: $gray-19;
  overflow: auto;
}

.create-connect-email-account-container {
  display: flex;
  flex-direction: row;
  width: min-content;
  background: white;
  border-radius: 0.5rem;
  margin-top: 94px;
  padding: 2.5rem;

  border: 1px solid #e5e7eb;
  box-shadow: 0px 2px 20px 0px rgba(4, 21, 76, 0.1);

  p {
    margin: 0 !important;
  }

  .email-section-one {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
  }

  .email-section-two {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
    width: 27.5rem;
    height: 21.25rem;
  }

  .vertical-divider {
    background-color: $gray-13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    height: 21.25rem;
    width: 0.0625rem;
  }

  .email-section-one-btn {
    width: 12.25rem;
    height: 2rem;
    margin-top: 1.5rem;

    span {
      color: white;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
    }
  }

  .email-section-two-btn {
    width: 11.5625rem;
    height: 2rem;
    margin-top: 1.5rem;

    span {
      color: white;

      font-size: 0.875rem;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
    }
  }

  .create-connect-email-account-title {
    display: flex;
    align-items: center;
    color: black;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: $font-weight-semibold;
    line-height: 1.25rem;
    margin-top: 0.75rem !important;
    gap: 0.25rem;

    svg {
      color: $gray-11;
    }
  }

  .create-connect-email-account-description {
    color: black;

    font-size: 0.875rem;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 1.25rem;
    width: 27rem;
    height: auto;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .create-connect-email-account-learn-more {
    display: flex;
    align-items: center;
    color: $blue-11;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 1.25rem;
    gap: 0.25rem;
    outline: none;
  }

  .features-title {
    color: $blue-11;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 1.25rem;
    margin-top: 1.5rem !important;
    gap: 0.25rem;
  }

  .features-list {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0.75rem 0 0 0;
    list-style: none;
  }

  .features-group {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    width: 100%;
  }

  .features-list li {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      display: block;
    }
  }

  .feature-text {
    color: $gray-15;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 1rem;
    text-wrap: nowrap;
  }
}

.recommendation-container {
  display: flex;
  align-items: flex-start;
  background: $indigo-1;
  padding: 0.5rem 0.75rem;
  width: fit-content;
  border: 0.0625rem solid $indigo-2;
  border-radius: 0.25rem;
  margin-bottom: 1.875rem;

  p {
    margin: 0;
  }

  .recommendation-icon {
    color: $indigo-7;
    margin-right: 0.5rem;
    display: block;
  }

  .recommendation-content {
    .recommendation-header {
      color: $blue-15;

      font-size: 0.75rem;
      font-style: normal;
      font-weight: $font-weight-semibold;
      line-height: 1rem;
    }

    .recommendation-description {
      color: $blue-15;
      font-size: 12px;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: 1rem;
      width: 57.6875rem;
      height: 2rem;
      margin-top: 0.25rem;
    }
  }
}

.create-new-sending-infrastructure-modal {
  align-content: center;

  .modal-dialog {
    max-width: 33.375rem;
  }

  .email-account-method-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .email-account {
    cursor: pointer;
    width: 9.375rem;
    height: 7.625rem;
    background: $gray-16;
    border: 1px solid $gray-16;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .email-label {
      margin-left: 0.375rem;
    }

    &__description {
      width: 7.375rem;
      text-align: center;
      line-height: 0.875rem;
      margin-top: 0.5rem;
    }

    &:hover {
      border-color: $blue-5;
      background: $blue-17;
      .email-label {
        color: $blue-11;
      }
    }
    &.active {
      border-color: $blue-5;
    }
  }
  .email-account-select-service {
    color: $popover-arrow-color;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
  }
  .email-account-method {
    display: flex;
    justify-content: space-around;
  }
  .email-account-message {
    letter-spacing: 0em;
    text-align: left;
    color: $gray-15;
  }
  .email-account-note {
    letter-spacing: 0em;
    text-align: left;
    color: $gray-15;
  }
}

.service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $gray-16;
  border-radius: 0.25rem;
  padding: 1rem 2rem;
  border: 1px solid $gray-16;

  .service-card-logo {
    margin-bottom: 0.5rem;
    width: 1.875rem;
    height: 1.875rem;
    img {
      width: auto;
      height: auto;
    }
  }

  .service-card-title {
    color: $blue-16;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: $font-weight-semibold;
    line-height: 1rem;
    margin-bottom: 0.5rem;
  }

  .service-card-subtitle {
    color: $gray-15;
    text-align: center;
    width: 5.125rem;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 0.75rem;
  }

  &:hover {
    border-color: $blue-5;
    background: $blue-17;

    .service-card-title {
      color: $blue-11;
    }
  }
}

.coming-soon-service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $gray-16;
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;

  .coming-soon-service-card-logo {
    margin-bottom: 0.5rem;
    img {
      width: 1.875rem;
      height: 1.875rem;
    }
  }

  .coming-soon-service-card-title {
    color: $gray-11;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: $font-weight-semibold;
    line-height: 1rem;
    margin-bottom: 1rem;
    width: 6.125rem;
  }

  .coming-soon-tag {
    display: flex;
    background-color: $blue-13;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6.25rem;
    padding: 0.125rem 0.5rem;
    height: 1.125rem;
    color: $blue-15;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 1rem;
    text-wrap: nowrap;
    width: 6rem;
  }
}

.shd-modal.forwarding-domain-modal .modal-content {
  border: none !important;
}

.forwarding-domain-modal {
  .modal-dialog {
    max-width: 28.125rem !important;
  }
  .shd-modal--header {
    padding-bottom: 1.5rem !important;
    .shd-modal--title {
      line-height: 1.5rem !important;
    }
  }

  .bs-input-affix-wrapper {
    background-color: $gray-19;
    max-width: 402px !important;
  }

  .bs-input-affix-wrapper:focus,
  .bs-input-affix-wrapper:focus-within {
    box-shadow: none;
    border-color: $blue-11;
  }

  .bs-input-affix-wrapper.input-error:focus,
  .bs-input-affix-wrapper.input-error:focus-within {
    box-shadow: none;
    border-color: #f5222d;
  }

  .bs-input {
    background-color: $gray-19;
    color: $blue-15;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 1.25rem;

    &::placeholder {
      color: $gray-11;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: 1.25rem;
    }
  }

  .shd-modal--body {
    margin-bottom: 1.5rem !important;
    p {
      margin-bottom: 0 !important;
    }

    .forwarding-domain-error-message {
      color: $red-12;
      font-size: 10px;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 0.75rem;
      margin-top: 0.25rem;
    }
  }

  .shd-modal--divider {
    height: 1px !important;
  }

  .shd-modal--footer {
    padding: 1.25rem 1.5rem !important;
  }
}

.watch-video-modal {
  .modal-dialog {
    width: 60%;
    max-width: 42.5rem;
    .modal-content {
      height: 37.625rem;
      max-height: 37.625rem;
      border-radius: 1rem !important;
      overflow: hidden;
    }
    .bs-modal-body {
      max-height: 100% !important;
      padding: 0px;
    }
    .watch-video-modal-text-container {
      padding: 2.5rem 5.375rem;
    }
    .watch-video-modal-body {
      .watch-video-modal-text {
        font-family: $font-family-sans-serif;
        display: flex;
        align-items: center;
        color: $gray-12;
        line-height: 1.25rem;
        font-variant-numeric: stacked-fractions;
        font-feature-settings: 'hlig' on, 'dlig' on;
      }
    }
  }
}
.video-modal {
  margin-left: 1.25rem;
  .modal-dialog {
    max-width: 42.5rem;
    max-height: 24.1875rem;
    .modal-content {
      max-height: 37.625rem;
      border-radius: 1rem !important;
      overflow: hidden;
    }
    .bs-modal-body {
      max-height: 100% !important;
      padding: 0px;
    }
    .watch-video-modal-text-container {
      padding: 2.5rem 5.375rem;
    }
  }
}
