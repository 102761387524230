.download-usage-report-history__popover .bs-popover-inner,
.download-usage-report-history__popover .bs-popover-inner-content {
  padding: 0px !important;
}

.download-usage-report-history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  &__trigger {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: $gray-15;
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    line-height: 1.25rem;
    cursor: pointer;

    svg {
      display: block;
      width: 1.25rem;
      height: 1.25rem;
    }

    &:hover,
    &.is-active {
      color: $blue-11;
    }
  }

  &__popover {
    width: 270px;
    display: flex;
    flex-direction: column;
    border-radius: 4px !important;
    border: 1px solid #e5e7eb !important;
    background: #fff;
    box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
      8px 16px 56px -24px rgba(156, 157, 161, 0.24) !important;
    padding: 16px !important;

    .arrow {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__date-selector {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .date-filter-select-wrap {
      width: 100%;

      .bs-select-box {
        border-radius: 4px;
        border: 1px solid #d1d5db;
        background: #f9fafb;
      }
    }

    .bs-input-label-prefix {
      display: flex;
      color: #6b7280;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 0 !important;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
}
