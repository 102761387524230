.bs-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $gray-8;
  list-style: none;
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: $text-12;
  line-height: $text-style-1-line-height;
  white-space: nowrap;
  background: $gray-2;
  border: 1px solid $gray-5;
  border-radius: $border-radius;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  a {
    color: rgba(0, 0, 0, 0.65);
    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  &.site-tag-plus {
    background: #fff;
    border-style: dashed;
    cursor: pointer;
  }
  .bs-tag-close-icon {
    color: $gray-8;
    cursor: pointer;
    i {
      position: relative;
      top: 1px;
    }
    &:hover {
      color: $gray-9;
    }
    &:focus {
      outline: 0;
    }
  }
  > a {
    &:first-child {
      &:last-child {
        display: inline-block;
        margin: 0 -8px;
        padding: 0 8px;
      }
    }
  }
  .anticon-close {
    display: inline-block;
    font-size: 10px;
    margin-left: 3px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    &:hover {
      color: rgba(0, 0, 0, 0.85);
    }
  }
  > .anticon {
    + {
      span {
        margin-left: 7px;
      }
    }
  }
  > span {
    + {
      .anticon {
        margin-left: 7px;
      }
    }
  }
}
.bs-tag-has-color {
  border-color: transparent;
  color: #fff;
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .anticon-close {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
.bs-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  &:not(.bs-tag-checkable-checked) {
    &:hover {
      color: #1890ff;
    }
  }
  &:active {
    color: #fff;
    background-color: #096dd9;
  }
}
.bs-tag-checkable-checked {
  color: #fff;
  background-color: #1890ff;
}
.bs-tag-hidden {
  display: none;
}
.bs-tag-gray {
  color: $popover-arrow-color;
  background: $gray-13;
  border-width: 0px;
  border-radius: 6.25rem;
  font-size: 14px;
  line-height: 20px;
}
.bs-tag-gray-2 {
  color: $popover-arrow-color;
  background: $gray-13;
  border-width: 0px;
  border-radius: 6.25rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.bs-tag-gray-3 {
  color: $popover-arrow-color;
  background: $gray-16;
  border-width: 0px;
  border-radius: 6.25rem;
  font-size: 14px;
  line-height: 20px;
}
.bs-tag-geekblue {
  color: $geekblue-6;
  background: $geekblue-1;
  border-color: $geekblue-3;
}
.bs-tag-blue {
  color: $blue-6;
  background: $blue-1;
  border-color: $blue-3;
}
.bs-tag-blue-2 {
  color: $popover-arrow-color;
  background: $blue-13;
  border-width: 0px;
  border-radius: 6.25rem;
  font-size: 14px;
  line-height: 20px;
}
.bs-tag-blue-3 {
  background: $blue-11;
  color: $white;
}
.bs-tag-red {
  color: $red-6;
  background: $red-1;
  border-color: $red-3;
}
.bs-tag-red-2 {
  color: $popover-arrow-color;
  background: $red-19;
  border-width: 0px;
  border-radius: 6.25rem;
  font-size: 14px;
  line-height: 20px;
}
.bs-tag-volcano {
  color: $volcano-6;
  background: $volcano-1;
  border-color: $volcano-3;
}
.bs-tag-orange {
  color: $orange-6;
  background: $orange-1;
  border-color: $orange-3;
}
.bs-tag-orange-2 {
  color: $popover-arrow-color;
  background: $orange-11;
  border-width: 0px;
  border-radius: 6.25rem;
  font-size: 14px;
  line-height: 20px;
}
.bs-tag-orange-3 {
  color: $popover-arrow-color;
  background: $orange-15;
  border-width: 0px;
  border-radius: 6.25rem;
  font-size: 14px;
  line-height: 20px;
}
.bs-tag-gold {
  color: $gold-6;
  background: $gold-1;
  border-color: $gold-3;
}
.bs-tag-lime {
  color: $lime-6;
  background: $lime-1;
  border-color: $lime-3;
}
.bs-tag-green {
  color: $green-6;
  background: $green-1;
  border-color: $green-3;
}
.bs-tag-green-2 {
  color: $popover-arrow-color;
  background: $green-11;
  border-width: 0px;
  border-radius: 6.25rem;
  font-size: 14px;
  line-height: 20px;
}
.bs-tag-cyan {
  color: $cyan-6;
  background: $cyan-1;
  border-color: $cyan-3;
}
.bs-tag-magenta {
  color: $magenta-6;
  background: $magenta-1;
  border-color: $magenta-3;
}
.bs-tag-blue-13 {
  color: $popover-arrow-color;
  background-color: $blue-13;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  padding: 4px 8px;
  border-width: 0;
}
.bs-tag-indigo {
  color: $indigo-9;
  background: $indigo-3;
}
.bs-tag-indigo-2 {
  color: $popover-arrow-color;
  background: $indigo-2;
  border-width: 0px;
  border-radius: 6.25rem;
  font-size: 14px;
  line-height: 20px;
}

.bs-tag-blue-17 {
  padding: 0.125rem 0.5rem !important;
  color: $popover-arrow-color;
  background: $blue-17;
  border-width: 0px;
}

.bs-tag-pause {
  width: 4.125rem;
  height: 1.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background: $red-19;
  border-radius: 6.25rem;
  border: none;

  color: $popover-arrow-color;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal;
}

.priority {
  border-radius: 1.25rem;
  padding: 0.125rem 0.5rem;
  display: inline-block;
  text-align: center;

  p {
    margin: 0px;
    line-height: 1.125rem;
  }
}
