/* Dropdown Container */
.profile-dropdown-view {
  position: absolute;
  right: 1px; /* Align with the right edge */
  top: 2.5rem; /* Position dropdown below the trigger */
  z-index: 999; /* Ensure it's above other elements */

  /* Dimensions */
  min-height: 10rem;
  min-width: 320px;
  max-width: auto; /* Match Figma width */

  /* Border and Background */
  border: 1px solid $gray-13; /* Light gray border */
  border-radius: 8px; /* Smooth rounded corners */
  background-color: $gray-1; /* Clean white background */
  overflow: hidden;
  box-sizing: border-box; /* Include padding in width/height calculations */

  ul {
    list-style: none;
    margin-bottom: 12px !important;
    font-family: 'inter';
  }

  .divider-dropdown-above-profile {
    border-bottom: 1px solid $gray-16;
  }
}

.user-profile-dropdown-row {
  display: flex;
  align-items: center;
  padding: 4px;

  .user-dropdown-option {
    display: flex;
    align-items: center;
    margin: 10px;

    .user-initials-avatar {
      width: 32px;
      height: 32px;
      font-family: 'inter';
    }

    .user-avatar-dropdown {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $blue-24;

      .text-primary {
        font-family: 'inter';
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: $blue-24 !important;
      }
    }

    .user-label-dropdown {
      margin-left: 8px;
      white-space: normal;
      overflow-wrap: break-word;
      word-wrap: break-word;
      overflow: hidden;

      .user-label-fullname {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $blue-15;
        display: flex;
        justify-content: flex-start;
        white-space: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
        overflow: hidden;
        font-family: 'inter';
      }

      .user-label-email {
        font-weight: 500;
        font-size: 12px;
        line-height: 19.2px;
        color: $gray-15;
        white-space: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
        overflow: hidden;
        word-break: break-all;
        text-align: left;
        font-family: 'inter';
      }
    }
  }
}

.unordered-list-dropdown {
  padding-left: 0px;

  .list--dropdown-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
    gap: 8px;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;

    .list--dropdown-option {
      display: flex;
      align-items: center;
      width: 100%;
      .list--dropdown-icon {
        display: flex;
        color: $blue-15;
        height: 16px;
        width: 16px;

        .graduation-hat {
          height: 16px !important;
          width: 16px !important;
          color: $blue-15;
        }

        .headset {
          height: 16px !important;
          width: 16px !important;
          color: $blue-15;
        }
      }

      .list--dropdown-label {
        display: flex;
        height: 20px;
        margin-left: 8px;
        align-items: center;

        .list--dropdown-label-text {
          display: inline-block;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $blue-15;
        }

        .new-badge {
          margin-left: 8px;
          background: #f59e0b;
          color: white;
          padding: 1px 3px;
          border-radius: 3px;
          font-size: 10px;
          font-weight: 500;
          line-height: 12px;
        }
      }
    }
  }

  .list--dropdown-row:hover {
    background-color: $gray-16;
    border-radius: 4px;
  }

  .class-for-logout {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    padding-left: 4px;
  }

  .divider-above-option {
    margin-left: 16px;
    margin-right: 16px;
    border-top: 1px solid $gray-16;
  }

  .added-features-dropdown {
    padding: 4px;
  }

  .dropdown-original-options {
    padding: 4px;
  }

  .credits-box {
    border-radius: 4px;
    border: 1px solid $gray-13;
    gap: 12px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 8px;
    padding-right: 12px;
    padding-left: 12px;
    // height: 165px;
    font-family: 'inter';

    .feature-credit-title {
      display: inline-block;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $blue-15;
      font-family: 'inter';
    }

    .section-without-progress-bar {
      .lead-finder-margin {
        margin-top: 11px !important  ; /* Apply reduced margin for Lead Finder Credits */
      }

      .feature-without-progress-bar {
        height: 16px;
        margin-top: 16px;
        margin-bottom: 12px;

        .credit-available-option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          font-family: 'inter';
        }

        .credit-available-red {
          color: $red-21;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          font-family: 'inter';
        }

        .credit-available-normal {
          color: $gray-10;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          font-family: 'inter';
        }

        .add-credits-link {
          color: $blue-11;
          text-decoration: none;
          line-height: 16px;
          height: 16px;
          font-family: 'inter';
        }
      }
    }

    .divider-inside-credit-box {
      width: 100%;
      border-bottom: 1px solid $gray-16;
    }

    .section-with-progress-bar {
      padding-bottom: 10px;

      .feature-with-progress-bar {
        margin-bottom: 3px;
        margin-top: 12px;
        text-decoration: none;
        list-style: none;

        .totalandused-credit-option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;
          list-style: none;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          font-family: 'inter';
          height: 16px;

          .number-link-part {
            .upgrade-link-feature-dropdown {
              color: $blue-11;
              text-decoration: none;
              line-height: 16px;
              height: 16px !important;
              font-family: 'inter';
            }

            .number-part-whole {
              display: inline-block;
              line-height: 16px;

              .credit-used-number {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: $gray-10;
                display: inline-block;
                font-family: 'inter';
              }

              .credit-feature-total {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: $gray-15;
                display: inline-block;
                font-family: 'inter';
              }

              .class-for-slash {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: $gray-15;
                height: 16px;
                font-family: 'inter';
              }
            }
          }
        }
      }
      .progress-bar {
        height: 6px;
        width: 100%;
        border-radius: 30px;
        background-color: $gray-16;
        display: block;
        margin-top: 6px;
      }

      .progress-fill {
        height: 100%;
        border-radius: 30px;
      }

      .progress-fill.normal {
        background-color: $green-23; /* Green */
      }

      .progress-fill.warning {
        background-color: $red-21; /* Red */
      }
    }
  }
}

.upgrade-link-dropdown {
  list-style: none;
  text-decoration: none;
  color: $blue-11 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-family: 'inter';
  padding: 0; /* Prevent padding-related shifts */
  margin: 0; /* Prevent margin-related shifts */
  height: 16px; /* Consistent height */
}

.upgrade-link-dropdown:hover {
  list-style: none;
  text-decoration: none;
}
.addcredits-link {
  font-family: 'inter';
  list-style: none;
  text-decoration: none;
  color: $blue-11 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.addcredits-link:hover {
  list-style: none;
  text-decoration: none;
}
.upgrade-link-feature-dropdown {
  color: $blue-11;
  text-decoration: none;
  line-height: 16px;
  height: 16px;
}

.multichannel-beta-access-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-top: 0.75rem;
  padding: 0 0.875rem;

  width: 20rem;

  .multichannel-beta-access-switch {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .switchToggle {
      width: 2rem;
      height: 1rem;
      display: flex;

      label {
        min-width: 2rem;
        margin: 0;
      }

      input:checked + label {
        background: $blue-11;
      }
    }

    .multichannel-beta-access-switch-label {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      color: #1f2937;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;

      .beta-tag {
        width: 2.125rem;
        height: 1.125rem;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 0.25rem;
        background: #f3f4f6;

        color: #6b7280;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
      }
    }
  }

  .multichannel-beta-access-switch-description {
    color: #6b7280;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
    text-align: left;
  }
  .multichannel-beta-access-switch-link {
    color: #1d4ed8;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.125rem;
    text-align: left;
  }
}

.shd-modal.activate-multichannel-confirmation-modal .modal-content {
  border: none !important;
}

.activate-multichannel-confirmation-modal {
  .modal-dialog {
    max-width: 28.125rem !important;
  }
  .shd-modal--header {
    padding-bottom: 1rem !important;
    .shd-modal--title {
      line-height: 1.5rem !important;
    }
  }
  .shd-modal--body {
    padding-bottom: 1.5rem !important;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      margin: 0;
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }
  .shd-modal--footer {
    padding: 1.25rem 1.5rem !important;
  }
}
