.blue-1 {
  background: $blue-1;
}
.blue-2 {
  background: $blue-2;
}
.blue-3 {
  background: $blue-3;
}
.blue-4 {
  background: $blue-4;
}
.blue-5 {
  background: $blue-5;
}
.blue-6 {
  background: $blue-6;
}
.blue-7 {
  background: $blue-7;
}
.blue-8 {
  background: $blue-8;
}
.blue-9 {
  background: $blue-9;
}
.blue-10 {
  background: $blue-10;
}
.blue-11 {
  background: $blue-11;
}
.blue-12 {
  background: $blue-12;
}
.blue-13 {
  background: $blue-13;
}
.blue-14 {
  background: $blue-14;
}
.blue-15 {
  background: $blue-15;
}
.blue-16 {
  background: $blue-16;
}
.blue-17 {
  background: $blue-17;
}
.blue-18 {
  background: $blue-18;
}
.blue-19 {
  background: $blue-19;
}
.blue-20 {
  background: $blue-20;
}
.blue-21 {
  background: $blue-21;
}
.blue-22 {
  background: $blue-22;
}
.blue-txt-23 {
  color: $blue-23;
}
.blue-txt-24 {
  color: $blue-24;
}
.blue-txt-25 {
  color: $blue-23;
}
.blue-txt-29 {
  color: $blue-29;
}

.gray-1 {
  background: $gray-1;
}
.gray-2 {
  background: $gray-2;
}
.gray-3 {
  background: $gray-3;
}
.gray-4 {
  background: $gray-4;
}
.gray-5 {
  background: $gray-5;
}
.gray-6 {
  background: $gray-6;
}
.gray-7 {
  background: $gray-7;
}
.gray-8 {
  background: $gray-8;
}
.gray-9 {
  background: $gray-9;
}
.gray-10 {
  background: $gray-10;
}
.gray-11 {
  background: $gray-11;
}
.gray-12 {
  background: $gray-12;
}
.gray-13 {
  background: $gray-13;
}
.gray-14 {
  background: $gray-14;
}
.gray-15 {
  background: $gray-15;
}
.gray-16 {
  background: $gray-16;
}
.gray-17 {
  background: $gray-17;
}
.gray-18 {
  background: $gray-18;
}
.gray-19 {
  background: $gray-19;
}
.gray-20 {
  background: $gray-20;
}
.gray-21 {
  background: $gray-21;
}
.gray-22 {
  background: $gray-22;
}
.gray-23 {
  background: $gray-23;
}
.gray-24 {
  background: $gray-24;
}
.gray-25 {
  background: $gray-25;
}
.gray-26 {
  background: $gray-26;
}
.gray-27 {
  background: $gray-27;
}

.red-1 {
  background: $red-1;
}
.red-2 {
  background: $red-2;
}
.red-3 {
  background: $red-3;
}
.red-4 {
  background: $red-4;
}
.red-5 {
  background: $red-5;
}
.red-6 {
  background: $red-6;
}
.red-7 {
  background: $red-7;
}
.red-8 {
  background: $red-8;
}
.red-9 {
  background: $red-9;
}
.red-10 {
  background: $red-10;
}
.red-11 {
  background: $red-11;
}
.red-12 {
  background: $red-12;
}
.red-13 {
  background: $red-13;
}
.red-14 {
  background: $red-14;
}
.red-15 {
  background: $red-15;
}
.red-16 {
  background: $red-16;
}
.red-17 {
  background: $red-17;
}
.red-18 {
  background: $red-18;
}
.red-19 {
  background: $red-19;
}

.volcano-1 {
  background: $volcano-1;
}
.volcano-2 {
  background: $volcano-2;
}
.volcano-3 {
  background: $volcano-3;
}
.volcano-4 {
  background: $volcano-4;
}
.volcano-5 {
  background: $volcano-5;
}
.volcano-6 {
  background: $volcano-6;
}
.volcano-7 {
  background: $volcano-7;
}
.volcano-8 {
  background: $volcano-8;
}
.volcano-9 {
  background: $volcano-9;
}
.volcano-10 {
  background: $volcano-10;
}

.orange-1 {
  background: $orange-1;
}
.orange-2 {
  background: $orange-2;
}
.orange-3 {
  background: $orange-3;
}
.orange-4 {
  background: $orange-4;
}
.orange-5 {
  background: $orange-5;
}
.orange-6 {
  background: $orange-6;
}
.orange-7 {
  background: $orange-7;
}
.orange-8 {
  background: $orange-8;
}
.orange-9 {
  background: $orange-9;
}
.orange-10 {
  background: $orange-10;
}

.gold-1 {
  background: $gold-1;
}
.gold-2 {
  background: $gold-2;
}
.gold-3 {
  background: $gold-3;
}
.gold-4 {
  background: $gold-4;
}
.gold-5 {
  background: $gold-5;
}
.gold-6 {
  background: $gold-6;
}
.gold-7 {
  background: $gold-7;
}
.gold-8 {
  background: $gold-8;
}
.gold-9 {
  background: $gold-9;
}
.gold-10 {
  background: $gold-10;
}

.yellow-1 {
  background: $yellow-1;
}
.yellow-2 {
  background: $yellow-2;
}
.yellow-3 {
  background: $yellow-3;
}
.yellow-4 {
  background: $yellow-4;
}
.yellow-5 {
  background: $yellow-5;
}
.yellow-6 {
  background: $yellow-6;
}
.yellow-7 {
  background: $yellow-7;
}
.yellow-8 {
  background: $yellow-8;
}
.yellow-9 {
  background: $yellow-9;
}
.yellow-10 {
  background: $yellow-10;
}
.yellow-11 {
  background: $yellow-11;
}

.lime-1 {
  background: $lime-1;
}
.lime-2 {
  background: $lime-2;
}
.lime-3 {
  background: $lime-3;
}
.lime-4 {
  background: $lime-4;
}
.lime-5 {
  background: $lime-5;
}
.lime-6 {
  background: $lime-6;
}
.lime-7 {
  background: $lime-7;
}
.lime-8 {
  background: $lime-8;
}
.lime-9 {
  background: $lime-9;
}
.lime-10 {
  background: $lime-10;
}

.green-1 {
  background: $green-1;
}
.green-2 {
  background: $green-2;
}
.green-3 {
  background: $green-3;
}
.green-4 {
  background: $green-4;
}
.green-5 {
  background: $green-5;
}
.green-6 {
  background: $green-6;
}
.green-7 {
  background: $green-7;
}
.green-8 {
  background: $green-8;
}
.green-9 {
  background: $green-9;
}
.green-10 {
  background: $green-10;
}
.green-11 {
  background: $green-11;
}
.green-12 {
  background: $green-12;
}
.green-13 {
  background: $green-13;
}
.green-14 {
  background: $green-14;
}
.green-15 {
  background: $green-15;
}

.cyan-1 {
  background: $cyan-1;
}
.cyan-2 {
  background: $cyan-2;
}
.cyan-3 {
  background: $cyan-3;
}
.cyan-4 {
  background: $cyan-4;
}
.cyan-5 {
  background: $cyan-5;
}
.cyan-6 {
  background: $cyan-6;
}
.cyan-7 {
  background: $cyan-7;
}
.cyan-8 {
  background: $cyan-8;
}
.cyan-9 {
  background: $cyan-9;
}
.cyan-10 {
  background: $cyan-10;
}
.cyan-11 {
  background: $cyan-11;
}

.geekblue-1 {
  background: $geekblue-1;
}
.geekblue-2 {
  background: $geekblue-2;
}
.geekblue-3 {
  background: $geekblue-3;
}
.geekblue-4 {
  background: $geekblue-4;
}
.geekblue-5 {
  background: $geekblue-5;
}
.geekblue-6 {
  background: $geekblue-6;
}
.geekblue-7 {
  background: $geekblue-7;
}
.geekblue-8 {
  background: $geekblue-8;
}
.geekblue-9 {
  background: $geekblue-9;
}
.geekblue-10 {
  background: $geekblue-10;
}

.purple-1 {
  background: $purple-1;
}
.purple-2 {
  background: $purple-2;
}
.purple-3 {
  background: $purple-3;
}
.purple-4 {
  background: $purple-4;
}
.purple-5 {
  background: $purple-5;
}
.purple-6 {
  background: $purple-6;
}
.purple-7 {
  background: $purple-7;
}
.purple-8 {
  background: $purple-8;
}
.purple-9 {
  background: $purple-9;
}
.purple-10 {
  background: $purple-10;
}
.purple-14 {
  background: $purple-14;
}

.magenta-1 {
  background: $magenta-1;
}
.magenta-2 {
  background: $magenta-2;
}
.magenta-3 {
  background: $magenta-3;
}
.magenta-4 {
  background: $magenta-4;
}
.magenta-5 {
  background: $magenta-5;
}
.magenta-6 {
  background: $magenta-6;
}
.magenta-7 {
  background: $magenta-7;
}
.magenta-8 {
  background: $magenta-8;
}
.magenta-9 {
  background: $magenta-9;
}
.magenta-10 {
  background: $magenta-10;
}

////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

/*Text */
.blue-txt-1 {
  color: $blue-1;
}
.blue-txt-2 {
  color: $blue-2;
}
.blue-txt-3 {
  color: $blue-3;
}
.blue-txt-4 {
  color: $blue-4;
}
.blue-txt-5 {
  color: $blue-5;
}
.blue-txt-6 {
  color: $blue-6;
}
.blue-txt-7 {
  color: $blue-7;
}
.blue-txt-8 {
  color: $blue-8;
}
.blue-txt-9 {
  color: $blue-9;
}
.blue-txt-10 {
  color: $blue-10;
}
.blue-txt-11 {
  color: $blue-11;
}
.blue-txt-12 {
  color: $blue-12;
}
.blue-txt-13 {
  color: $blue-13;
}
.blue-txt-14 {
  color: $blue-14;
}
.blue-txt-15 {
  color: $blue-15;
}
.blue-txt-12 {
  color: $blue-12;
}
.blue-txt-13 {
  color: $blue-13;
}
.blue-txt-14 {
  color: $blue-14;
}
.blue-txt-15 {
  color: $blue-15;
}
.blue-txt-16 {
  color: $blue-16;
}
.blue-txt-23 {
  color: $blue-23;
}
.blue-txt-24 {
  color: $blue-24;
}
.blue-txt-25 {
  color: $blue-23;
}
.blue-txt-31 {
  color: $blue-31;
}

.gray-txt-1 {
  color: $gray-1;
}
.gray-txt-2 {
  color: $gray-2;
}
.gray-txt-3 {
  color: $gray-3;
}
.gray-txt-4 {
  color: $gray-4;
}
.gray-txt-5 {
  color: $gray-5;
}
.gray-txt-6 {
  color: $gray-6;
}
.gray-txt-7 {
  color: $gray-7;
}
.gray-txt-8 {
  color: $gray-8;
}
.gray-txt-9 {
  color: $gray-9;
}
.gray-txt-10 {
  color: $gray-10;
}
.gray-txt-11 {
  color: $gray-11;
}
.gray-txt-12 {
  color: $gray-12;
}
.gray-txt-13 {
  color: $gray-13;
}
.gray-txt-14 {
  color: $gray-14;
}
.gray-txt-15 {
  color: $gray-15;
}
.gray-txt-16 {
  color: $gray-16;
}
.gray-txt-17 {
  color: $gray-17;
}
.gray-txt-18 {
  color: $gray-18;
}
.gray-txt-19 {
  color: $gray-19;
}
.gray-txt-20 {
  color: $gray-20;
}
.gray-txt-21 {
  color: $gray-21;
}
.gray-txt-22 {
  color: $gray-22;
}
.gray-txt-23 {
  color: $gray-23;
}
.gray-txt-24 {
  color: $gray-24;
}
.gray-txt-25 {
  color: $gray-25;
}
.gray-txt-26 {
  color: $gray-26;
}
.gray-txt-27 {
  color: $gray-27;
}
.gray-txt-28 {
  color: $gray-28;
}
.gray-txt-29 {
  color: $gray-29;
}
.gray-txt-30 {
  color: $gray-30;
}

.red-txt-1 {
  color: $red-1;
}
.red-txt-2 {
  color: $red-2;
}
.red-txt-3 {
  color: $red-3;
}
.red-txt-4 {
  color: $red-4;
}
.red-txt-5 {
  color: $red-5;
}
.red-txt-6 {
  color: $red-6;
}
.red-txt-7 {
  color: $red-7;
}
.red-txt-8 {
  color: $red-8;
}
.red-txt-9 {
  color: $red-9;
}
.red-txt-10 {
  color: $red-10;
}
.red-txt-11 {
  color: $red-11;
}
.red-txt-12 {
  color: $red-12;
}
.red-txt-13 {
  color: $red-13;
}
.red-txt-14 {
  color: $red-14;
}
.red-txt-15 {
  color: $red-15;
}
.red-txt-16 {
  color: $red-16;
}
.red-txt-17 {
  color: $red-17;
}
.red-txt-18 {
  color: $red-18;
}

.volcano-txt-1 {
  color: $volcano-1;
}
.volcano-txt-2 {
  color: $volcano-2;
}
.volcano-txt-3 {
  color: $volcano-3;
}
.volcano-txt-4 {
  color: $volcano-4;
}
.volcano-txt-5 {
  color: $volcano-5;
}
.volcano-txt-6 {
  color: $volcano-6;
}
.volcano-txt-7 {
  color: $volcano-7;
}
.volcano-txt-8 {
  color: $volcano-8;
}
.volcano-txt-9 {
  color: $volcano-9;
}
.volcano-txt-10 {
  color: $volcano-10;
}

.orange-txt-1 {
  color: $orange-1;
}
.orange-txt-2 {
  color: $orange-2;
}
.orange-txt-3 {
  color: $orange-3;
}
.orange-txt-4 {
  color: $orange-4;
}
.orange-txt-5 {
  color: $orange-5;
}
.orange-txt-6 {
  color: $orange-6;
}
.orange-txt-7 {
  color: $orange-7;
}
.orange-txt-8 {
  color: $orange-8;
}
.orange-txt-9 {
  color: $orange-9;
}
.orange-txt-10 {
  color: $orange-10;
}
.orange-txt-11 {
  color: $orange-11;
}
.orange-txt-12 {
  color: $orange-12;
}
.orange-txt-13 {
  color: $orange-13;
}
.orange-txt-14 {
  color: $orange-14;
}
.orange-txt-15 {
  color: $orange-15;
}
.orange-txt-16 {
  color: $orange-16;
}
.orange-txt-17 {
  color: $orange-17;
}
.orange-txt-18 {
  color: $orange-18;
}
.orange-txt-19 {
  color: $orange-19;
}
.orange-txt-20 {
  color: $orange-20;
}
.orange-txt-21 {
  color: $orange-21;
}

.gold-txt-1 {
  color: $gold-1;
}
.gold-txt-2 {
  color: $gold-2;
}
.gold-txt-3 {
  color: $gold-3;
}
.gold-txt-4 {
  color: $gold-4;
}
.gold-txt-5 {
  color: $gold-5;
}
.gold-txt-6 {
  color: $gold-6;
}
.gold-txt-7 {
  color: $gold-7;
}
.gold-txt-8 {
  color: $gold-8;
}
.gold-txt-9 {
  color: $gold-9;
}
.gold-txt-10 {
  color: $gold-10;
}

.yellow-txt-1 {
  color: $yellow-1;
}
.yellow-txt-2 {
  color: $yellow-2;
}
.yellow-txt-3 {
  color: $yellow-3;
}
.yellow-txt-4 {
  color: $yellow-4;
}
.yellow-txt-5 {
  color: $yellow-5;
}
.yellow-txt-6 {
  color: $yellow-6;
}
.yellow-txt-7 {
  color: $yellow-7;
}
.yellow-txt-8 {
  color: $yellow-8;
}
.yellow-txt-9 {
  color: $yellow-9;
}
.yellow-txt-10 {
  color: $yellow-10;
}
.yellow-txt-12 {
  color: $yellow-12;
}

.lime-txt-1 {
  color: $lime-1;
}
.lime-txt-2 {
  color: $lime-2;
}
.lime-txt-3 {
  color: $lime-3;
}
.lime-txt-4 {
  color: $lime-4;
}
.lime-txt-5 {
  color: $lime-5;
}
.lime-txt-6 {
  color: $lime-6;
}
.lime-txt-7 {
  color: $lime-7;
}
.lime-txt-8 {
  color: $lime-8;
}
.lime-txt-9 {
  color: $lime-9;
}
.lime-txt-10 {
  color: $lime-10;
}

.green-txt-1 {
  color: $green-1;
}
.green-txt-2 {
  color: $green-2;
}
.green-txt-3 {
  color: $green-3;
}
.green-txt-4 {
  color: $green-4;
}
.green-txt-5 {
  color: $green-5;
}
.green-txt-6 {
  color: $green-6;
}
.green-txt-7 {
  color: $green-7;
}
.green-txt-8 {
  color: $green-8;
}
.green-txt-9 {
  color: $green-9;
}
.green-txt-10 {
  color: $green-10;
}
.green-txt-11 {
  color: $green-11;
}
.green-txt-12 {
  color: $green-12;
}
.green-txt-13 {
  color: $green-13;
}
.green-txt-14 {
  color: $green-14;
}
.green-txt-15 {
  color: $green-15;
}
.green-txt-16 {
  color: $green-16;
}
.green-txt-23 {
  color: $green-23;
}
.green-txt-24 {
  color: $green-24;
}
.green-txt-25 {
  color: $green-25;
}

.cyan-txt-1 {
  color: $cyan-1;
}
.cyan-txt-2 {
  color: $cyan-2;
}
.cyan-txt-3 {
  color: $cyan-3;
}
.cyan-txt-4 {
  color: $cyan-4;
}
.cyan-txt-5 {
  color: $cyan-5;
}
.cyan-txt-6 {
  color: $cyan-6;
}
.cyan-txt-7 {
  color: $cyan-7;
}
.cyan-txt-8 {
  color: $cyan-8;
}
.cyan-txt-9 {
  color: $cyan-9;
}
.cyan-txt-10 {
  color: $cyan-10;
}

.geekblue-txt-1 {
  color: $geekblue-1;
}
.geekblue-txt-2 {
  color: $geekblue-2;
}
.geekblue-txt-3 {
  color: $geekblue-3;
}
.geekblue-txt-4 {
  color: $geekblue-4;
}
.geekblue-txt-5 {
  color: $geekblue-5;
}
.geekblue-txt-6 {
  color: $geekblue-6;
}
.geekblue-txt-7 {
  color: $geekblue-7;
}
.geekblue-txt-8 {
  color: $geekblue-8;
}
.geekblue-txt-9 {
  color: $geekblue-9;
}
.geekblue-txt-10 {
  color: $geekblue-10;
}

.purple-txt-1 {
  color: $purple-1;
}
.purple-txt-2 {
  color: $purple-2;
}
.purple-txt-3 {
  color: $purple-3;
}
.purple-txt-4 {
  color: $purple-4;
}
.purple-txt-5 {
  color: $purple-5;
}
.purple-txt-6 {
  color: $purple-6;
}
.purple-txt-7 {
  color: $purple-7;
}
.purple-txt-8 {
  color: $purple-8;
}
.purple-txt-9 {
  color: $purple-9;
}
.purple-txt-10 {
  color: $purple-10;
}
.purple-txt-11 {
  color: $purple-11;
}
.purple-txt-12 {
  color: $purple-12;
}
.purple-txt-13 {
  color: $purple-13;
}
.purple-txt-14 {
  color: $purple-14;
}
.purple-txt-15 {
  color: $purple-15;
}
.purple-txt-16 {
  color: $purple-16;
}

.magenta-txt-1 {
  color: $magenta-1;
}
.magenta-txt-2 {
  color: $magenta-2;
}
.magenta-txt-3 {
  color: $magenta-3;
}
.magenta-txt-4 {
  color: $magenta-4;
}
.magenta-txt-5 {
  color: $magenta-5;
}
.magenta-txt-6 {
  color: $magenta-6;
}
.magenta-txt-7 {
  color: $magenta-7;
}
.magenta-txt-8 {
  color: $magenta-8;
}
.magenta-txt-9 {
  color: $magenta-9;
}
.magenta-txt-10 {
  color: $magenta-10;
}

.indigo-txt-1 {
  color: $indigo-1;
}
.indigo-txt-2 {
  color: $indigo-2;
}

.popover-arrow-color-txt {
  color: $popover-arrow-color;
}

.black-txt-1 {
  color: $black-1;
}
