.shd-modal.import-contacts-csv-modal .modal-content {
  border: none !important;
}

.import-contacts-csv-modal {
  .modal-dialog {
    max-width: 45rem;
    overflow: hidden;

    align-items: center;

    .modal-content {
      min-height: 522px;
      max-height: 585px;
      flex: 1;

      .modal-body {
        padding: 0 !important;
        overflow: hidden;
        display: grid;
      }
    }
  }

  &--content {
    height: 100%;
    overflow: hidden;
  }

  &--flow {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    height: 100%;
    overflow: hidden;

    .import-stage-tabs {
      &--container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        margin: 1.25rem 1.5rem 0 1.5rem;

        height: 2.5rem;
      }

      &--tab {
        width: 100%;
        min-width: 10.375rem;
        max-width: 10.9375rem;
        height: 2.5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 0.25rem;
        padding: 0.5rem 1rem;
        cursor: pointer;

        p {
          margin: 0;
          color: #6b7280;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.25rem;
        }

        &.selected {
          background: #eff6ff;
          p {
            color: #1d4ed8;
            font-weight: 600;
          }
        }

        &.disabled {
          cursor: not-allowed;
        }
      }

      &--icon {
        width: 1.5rem;
        height: 1.5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          color: #9ca3af;
        }
      }
    }
  }

  &--stages {
    display: flex;
    flex-direction: column;
    flex: 1;

    height: 100%;
    overflow: hidden;

    .import-contact-stage {
      display: flex;
      flex-direction: column;
      height: 100%;

      .import-contact-stage-content {
        flex: 1;
        border-radius: 0.25rem;
        margin: 0rem 1.5rem 1.25rem 1.5rem;
      }

      .import-contact-stage-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 1.25rem 1.5rem;
        border-top: 0.0625rem solid #e5e7eb;

        &--left,
        &--right {
          display: flex;
          align-items: center;
          gap: 1rem;

          height: 100%;

          button {
            min-width: 6.25rem;
          }

          .download-import-contacts-sample-file {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;

            height: 1.5rem;

            text-decoration: none;
            color: $blue-11;
            transition: all 0.2s ease;

            &:hover {
              color: #1e40af;
            }

            span {
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.25rem;

              &:last-of-type {
                margin-top: 0.125rem;
              }
            }

            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
  }

  .import-flow-stage-wrapper {
    .pre-select-stage {
      .select-file-content {
        background: $gray-19;
      }
    }

    .post-select-stage {
      .select-file-content {
        background: $green-21;
        border: 0.0625rem solid $green-15;
      }
    }

    .csv-error {
      .select-file-content {
        background: $red-17;
        border: 0.0625rem solid $red-11;
      }
    }

    .select-file {
      // margin-bottom: 1rem;
      // border-radius: 0.5rem;
      .select-file-content {
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .shd-drag-and-drop {
          flex-direction: row;
          justify-content: center;

          border-radius: 0.5rem;
          border: 0.0625rem dashed $gray-23;
          &.dragging {
            border: none;
          }
        }
      }

      .pre-select {
        display: flex;
        flex-direction: column;
        align-items: center;

        .empty-data-icon-wrapper img {
          width: 1.875rem;
        }

        .pre-select-tagline {
          font-weight: $font-weight-medium;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: $blue-15;

          margin: 1.5rem 0 1rem 0;
        }

        .pre-select-info {
          color: $gray-15;
          font-weight: $font-weight-normal;

          font-size: 0.75rem;
          line-height: 1.25rem;
        }

        .pre-select-file-uploader {
          width: 7.5rem;
          height: 2rem;

          margin-top: 1rem;
          background-color: $gray-1;
        }

        .file-uploader {
          width: 7.7rem;
          height: 2rem;
          border: 1px solid $blue-11;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > input {
            display: none;
          }

          .select-file-placeholder {
            padding: 0 0.75rem;
            color: $gray-6;
          }

          .choose-file {
            height: calc(100% + 2px);
            display: flex;
            align-items: center;
            border-radius: 4px;
            color: $blue-11;
            justify-content: center;
            width: 100%;
            font-weight: $font-weight-semibold;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .post-select {
        .post-select-icon-wrapper {
          width: 2.5rem;
          height: 2.5rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .post-select-filename {
          font-weight: $font-weight-medium;
          font-size: 1rem;
          line-height: 1.5rem;
          color: $blue-15;
          margin-top: 0.875rem;
          margin-bottom: 1rem;
        }

        .post-select-success,
        .post-select-delete {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
        }

        .post-select-success {
          font-weight: $font-weight-normal;
          font-size: 0.875rem;
          line-height: 1rem;
          color: $blue-15;
        }

        .csv-error-message {
          color: $blue-15;
          text-align: center;
          font-size: 0.875rem;
          line-height: 1.25rem;
          margin: 0;
          margin-top: 0.875rem;
          span {
            display: block;
          }
        }

        .post-select-delete {
          width: 8.625rem;
          height: 2rem;

          margin-top: 1.125rem;
          color: $gray-15;
          transition: all 0.2s ease;

          span {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: $font-weight-medium;
          }

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }

          i {
            font-size: 1.25rem;
          }

          &:hover {
            color: $blue-15;
            i {
              &::before {
                color: $blue-15;
              }
            }
          }
        }

        .file-preview {
          width: 13.7rem;
          height: 2rem;
          border: 1px solid $gray-5;
          border-radius: 4px;
          display: flex;
          align-items: center;
          user-select: none;
          margin-top: 1.75rem;

          .filename {
            flex: 1;
            overflow-x: hidden;
            text-overflow: ellipsis;
            padding: 0 0.75rem;
            color: $gray-6;
            white-space: nowrap;
          }

          .remove-file {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 0.3125rem;
            cursor: pointer;
            color: $gray-7;
            font-size: 1.3125rem;
          }
        }
      }
    }

    .map-fields {
      .map-fields-content {
        padding-bottom: 1rem;
      }

      .map-list {
        width: 100%;
        height: 100%;
        border: 1px solid $gray-5;
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        .map-list-header,
        .map-list-item {
          display: flex;
        }

        .map-list-header {
          padding: 0.625rem 1.5rem;
          color: $gray-9;
        }

        .map-list-item {
          padding: 0.45rem 1.5rem;
          align-items: center;

          .map-list-item-child-left {
            flex: 1;
            display: flex;
            color: #1f2937;
            overflow: hidden;
            margin-right: 1rem;

            .csv-header-label,
            .csv-row-value {
              flex: 1;

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: block;
              max-width: 100%;
            }

            .csv-header-label {
              flex: 3;
              max-width: 9rem;
            }

            .csv-row-value {
              flex: 3;
              margin-left: 6rem;
            }
          }

          .map-list-item-child-right {
            display: flex;
            align-items: center;
            width: 13rem;
            justify-content: flex-end;

            .map-list-item-remove-icon-wrapper {
              padding: 5px;
              margin-right: 0.5rem;
              border-radius: 100%;
              display: flex;
              cursor: pointer;
            }

            .bs-select-box {
              width: 10rem;
              color: #1f2937;
            }
          }
        }

        .map-list-header {
          display: flex;
          justify-content: space-between;
          background-color: $gray-100;
          color: $gray-12;
          font-weight: $font-weight-semibold;
        }

        .map-list-content {
          flex: 1;
          overflow-y: auto;
        }
        .map-list-footer {
          display: flex;
          height: 3.125rem;
          justify-content: space-between;
          background: $gray-100;
        }
        .map-list-scroll {
          height: 21.875rem;
          flex: auto;
          margin-top: 0.625rem;
        }
        .map-list-footer-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-left: 3px !important;
          margin-top: 0.5rem;
        }
      }

      .map-fields-config-wrapper {
        display: flex;

        .map-fields-config {
          display: flex;
          align-items: center;

          &:not(:first-child) {
            margin-left: 0.75rem;
          }

          .map-fields-config-label {
            color: #1f2937;
            flex-shrink: 0;
            margin-right: 0.5rem;
          }
        }

        .map-fields-step {
          .bs-select-box {
            width: 7rem;
          }
        }

        .map-fields-conflict-action {
          .bs-select-box {
            width: 12.5rem;
          }
          .info-circle {
            margin-bottom: 0.25rem;
            margin-right: 0.25rem;
          }
        }
      }

      .modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .sample-map-field {
      margin-left: 8.7rem;
    }

    .import-config {
      .import-config-content {
        padding: 0.625rem 2px 0 2px;

        .import-config-step-wrapper {
          width: 14rem;
        }
      }

      .import-config-footer {
        .modal-footer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 5px;
      height: 4.93rem;
    }

    ::-webkit-scrollbar-track {
      background: #fff;
    }

    ::-webkit-scrollbar-thumb {
      background: $gray-23;
      border-radius: 4px;
    }

    .email-verification {
      margin: 0 !important;
      padding: 0rem 1.5rem 1.25rem 1.5rem !important;
      .content-loader {
        align-self: center;
        width: 15rem;
      }

      .tags-container {
        display: none;
      }

      .tags-autosuggest-container {
        .tags-autosuggest-width {
          width: 100%;
          .tag-input-container {
            flex: 1;
            min-width: 10rem;
          }
        }
        input {
          width: 100%;
          margin: 0 !important;
        }
      }

      .email-verification-content-footer {
        flex: 1;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 1rem;
        .verification-checkbox {
          flex: 1;
          display: flex;
          flex-direction: row;
          padding-left: 0.5rem;
          margin-bottom: 1rem;
        }
        .text {
          color: $blue-15;
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
          margin-top: 0.15rem;
        }
      }

      .review-section-container {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;

        &.mt-2 {
          margin-top: 0 !important;
        }

        .review-section-title-container {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .review-section-title {
            color: #1f2937;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
          }
        }

        .review-section-tags-container {
          &.mt-n2 {
            margin-top: 0rem !important;
          }

          .tags-autosuggest-container {
            & > div:first-child {
              margin-top: 0 !important;

              .bs-input {
                height: 2.5rem;
                border: 0.0625rem solid #d1d5db;
                background: #f9fafb;
                input {
                  background: #f9fafb;
                }
              }

              #react-autowhatever-1 {
                margin-top: 0.25rem !important;
              }
            }
          }
        }
      }

      .email-verification-info-container {
        margin-top: 1.75rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        .email-verification-desc-container {
          display: flex;
          flex-direction: column;
          gap: 0.625rem;

          p {
            margin: 0;
            color: #6b7280;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;

            a {
              color: $blue-11;
            }
          }
        }

        .buy-credit-alert {
          height: 2rem;
          .shd-alert--icon {
            display: flex;
          }
          .buy-credit-alert-desc {
            gap: 0.5rem;

            p {
              font-size: 0.75rem;
              line-height: 1rem;
              font-style: normal;

              &:first-of-type {
                color: $blue-15;
                font-weight: 400;
              }

              &:last-of-type {
                color: $blue-11;
                font-weight: 500;
              }
            }
          }
        }

        .email-verification-info {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          .icon {
            width: 1.25rem;
            height: 1.25rem;
          }

          .status-name {
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            margin-left: 0.5rem;
          }

          .status-content {
            color: #4b5563;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
            margin-left: 1rem;
          }

          .valid-contact {
            height: 1.5rem;
            .icon {
              color: #059669;
            }
            .valid-status-name {
              color: #059669;
            }
          }

          .risky-contact {
            height: 1.5rem;
            .icon {
              color: #dc2626;
            }
            .risky-status-name {
              color: #dc2626;
            }
          }
        }
      }

      .verify-prospect-checkbox {
        .bs-checkbox-wrapper {
          height: 1rem;
          line-height: 1rem;
        }
        input {
          width: 1rem;
          height: 1rem;

          border-radius: 0.25rem;
          border: 0.0625rem solid #9ca3af;
          background: #fff;
        }

        width: 100%;
        margin-top: 0.375rem;

        cursor: pointer;

        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem;

        color: #1f2937;

        display: flex;
        align-items: center;
        align-self: stretch;
        gap: 0.5rem;
      }

      .email-verification-error-container {
        margin-top: 0.375rem;
      }
      .email-verification-error-table {
        max-width: 18.75rem;
      }
    }
  }
}

@media (max-height: 645px) {
  .modal-content {
    min-height: 90vh !important;
    max-height: 90vh !important;
  }
}
