.transfer-select-btn {
  width: 12.25rem;
  height: 2rem;

  border-radius: 4px;
  border: 1px solid $gray-23;
  background: $gray-19;
  justify-content: flex-start;

  &.disabled {
    cursor: not-allowed;
  }

  span {
    width: 100%;
    justify-content: space-between;
  }


  .transfer-select-btn-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  &:hover,
  &:focus,
  &:focus-within {
    outline: none;
    box-shadow: none;
    border: 1px solid $blue-11;
  }

  color: $blue-15;
  font-weight: $font-weight-medium;
  font-size: 0.75rem;
  line-height: 1.125rem;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .select-icon {
    width: 1rem;
    height: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $gray-11;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .bs-select-box-icon-wrapper {
    justify-content: center;
    margin: 0;
    color: $gray-12;
  }

  &.btn-white {
    background: $white;
    border-color: $gray-23;
  }
  &.w-1 {
    width: 11.6875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    padding: 0.375rem 0.75rem;
  }
}

.transfer-select-btn {
  &:hover{
    border: 1px solid $blue-11;
  }
}

.team-filter-select {
  .transfer-body {
    padding-bottom: 0 !important;
  }
  .transfer-container {
    max-width: 16.75rem !important;
    min-width: 16.75rem !important;
    min-height: 22.5rem !important;

    .transfer-body {
      .transfer-search {
        height: 2rem;
        padding: 0.375rem 1rem;
        border: 0.0625rem solid $gray-16;
        margin-bottom: 0.5rem;
      }
      .loader-wrapper {
        min-height: 5.875rem;
      }
      .transfer-content {
        min-height: 16.4375rem;
        .transfer-empty {
          margin: 0;
          margin-top: 4.5625rem;
          img {
            width: 4.8475rem;
            height: 5rem;
            margin: 0;
          }
          h1,
          p {
            color: $blue-15;
            text-align: center;
            font-size: 0.75rem;
            font-weight: $font-weight-semibold;
            line-height: 1.125rem;
            margin: 1rem 0 0.5rem 0;
          }
          p {
            width: 12.125rem;
            color: $gray-11;
            font-weight: $font-weight-normal;
            margin: 0;
          }
        }
      }
    }

    .separator {
      align-items: flex-start !important;
      padding: 0 !important;
      line-height: 1.25rem !important;

      position: relative;

      .checkbox-wrapper {
        width: 1rem;
        height: 1rem;

        margin-right: 0 !important;

        position: absolute;
        left: 1rem;
        top: 0.5rem;
      }
      .bs-checkbox-wrapper {
        width: 1rem;
        height: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;

        .bs-checkbox {
          top: 0;
        }
      }

      .option-content {
        width: 100%;
      }

      &:hover {
        background: transparent !important;
      }
    }

    .shd-accordion {
      width: 100%;

      .accordion__item {
        padding: 0;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none;
        background-color: transparent !important;
      }

      .accordion__heading {
        padding: 0.375rem 1rem;
        transition: all 0.2s ease;
        &:hover {
          background: #eff6ff;
        }
      }

      .accordion__button {
        padding: 0;
        color: #1f2937;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        background-color: transparent !important;

        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-left: 1.5rem;

        &::before {
          display: none;
        }

        svg {
          color: #9ca3af;
        }
      }

      .accordion__panel {
        border: none;
        padding: 0.5rem 0rem;

        border-top: 1px solid #f3f4f6;
        border-bottom: 1px solid #f3f4f6;
        background: rgba(249, 250, 251, 0.6);

        .team-member {
          display: flex;
          align-items: center;
          align-self: stretch;
          padding: 0.375rem 1.75rem;
          transition: all 0.2s ease;

          .bs-checkbox-wrapper {
            margin-right: 0.5rem;

            .bs-checkbox {
              top: 0;
            }

            display: flex;
            align-items: center;
            justify-content: center;
          }

          .team-member-name {
            color: #1f2937;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:hover {
            background: #eff6ff;
          }
        }
      }
    }

    .single-team-member {
      padding: 0.375rem 1rem;

      color: #1f2937;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      margin-left: 1.5rem;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .transfer-footer {
    padding: 0.5rem 1rem !important;
    border-top: 0.0625rem solid #e5e7eb;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .team-filter-footer {
    .bs-checkbox-wrapper span:last-of-type {
      color: #1f2937;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
    }
    .apply-btn {
      line-height: 1.25rem;
    }
  }
}

.team-filter-select-onboarding {
  .transfer-container {
    min-height: 15rem !important;

    .transfer-body {
      .transfer-content {
        min-height: 8.8rem;
      }
    }
  }
}

.team-filter-button {
  color: #1f2937;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;

  width: 10.1875rem;
  height: 2rem;
  padding: 0.375rem 1rem;

  border-radius: 0.25rem;
  border: 0.0625rem solid #d1d5db;
  background: #fff;

  cursor: pointer;
  gap: 0.5rem;

  transition: all 0.2s ease;

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    border-color: #1d4ed8;
  }

  .select-icon {
    width: 1rem;
    height: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $gray-11;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
}
