.modify-subscription {
  &__highlight-container {
    background: $orange-15;
    height: 1rem;
    padding: 0.125rem 0.3125rem;
    border-radius: 0.25rem;
    margin-bottom: 0.475rem;
  }

  /* Safari  */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      &__highlight-container {
        min-width: 10.1875rem;
      }
    }
  }
  &__circle {
    position: absolute;
    right: -0.75rem;
    top: 0.125rem;
  }
  &__progress-bar-container {
    width: 21.3125rem;
    margin-top: 1.9063rem;
    position: relative;
    .step-icon {
      height: 1.875rem;
      width: 1.875rem;
      border-radius: 50%;
    }
    .active-step {
      .step-icon {
        background: $blue-11;
        color: $white;
      }
    }
    .done-step {
      .step-icon {
        background: $blue-12;
        color: $gray-19;
      }
      span {
        color: $gray-15;
      }
    }
    .disable-step {
      color: $blue-14;

      span {
        color: inherit;
        margin-top: 0.3438rem;
      }
    }
    .cursor-not-allowed {
      cursor: not-allowed;
      .step-icon {
        i {
          cursor: inherit;
        }
      }
    }
  }
  &__divider {
    position: absolute;
    left: 4.4125rem;
    top: 0.9375rem;
    width: 13rem;
    height: 0.125rem;
    background: $gray-13;
  }
  &__select-plan {
    width: 33.125rem;
    &-banner {
      background: $blue-17;
      padding: 0.75rem 1rem;
      border-radius: 0.125rem;
      .ghost-outline-new.btn-ghost-primary {
        border: 1px solid $gray-15;
        color: $gray-15;
      }
    }
  }
  &__select-plan-container {
    gap: 20px;
  }
}

.banner-announcement {
  background: $yellow-11;
  width: 61.25rem;
  padding: 0.625rem 0px;
  border: 1px solid $orange-18;
  border-radius: 0.25rem;
  border-left-width: 4px;
  margin-top: 1.5313rem;
  .coupon-code {
    background: $gray-16;
    border: 1px dashed $gray-23;
    border-radius: 0.25rem;
    padding: 0.25rem 0.75rem;
  }
  @media screen and (max-width: $screen-1366) {
    width: 50rem;
  }
  @media screen and (max-width: $screen-1280) {
    width: 46rem;
  }
}

.cold-email-plan-card {
  width: 56.5rem;
  .pricing-card {
    .card {
      height: 29.5625rem;
      width: 13.1875rem;
    }

    &__details-price-wrapper {
      height: 1.25rem;
    }

    &__features {
      ul > li {
        .feature-tooltip {
          text-decoration: underline;
          text-decoration-style: dotted;
        }
      }
    }

    .solid-primary-new.btn-solid-primary {
      &:hover {
        background-color: $blue-11;
        color: $white;
      }
    }
  }
}

.outreach-plans {
  height: 100%;
  overflow-x: auto;
  .premium-feature-card {
    width: 38.5rem;
    border-radius: $border-radius;
  }
  .lead-finder-plans-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin-top: 1.5rem;
    color: #202020;
    h1 {
      margin-bottom: 0px;
      line-height: 31px;
      color: #202020;

    }
    span {
      font-size: $text-16;
      line-height: 21px;
      color: #202020;

    }
  }

  .lead-finder-plans-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background: $gray-1;
    margin-top: -1.5rem;
    padding-top: 4rem;

    .lead-finder-plans-card-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    .credit-slider-container {
      width: 75.25rem;
      border: 1px solid $gray-13;
      border-radius: 0.25rem;

      .credit-slider-container-header {
        padding: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $gray-19;
        border-radius: 0.25rem 0.25rem 0px 0px;

        .credit-slider-container-header-title-wrapper {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          .credit-slider-container-header-title {
            font-size: $heading-2;
            line-height: 26px;
            font-weight: $font-weight-semibold;
            color: #202020;
          }

          .credit-slider-container-header-description {
            font-size: $text-14;
            line-height: $text-16;
            font-weight: $font-weight-normal;
            color: #202020;
          }
        }
      }

      .credit-slider-wrapper {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        border-top: 1px solid $gray-13;
        background: $gray-1;
        border-radius: 0px 0px 0.25rem 0.25rem;

        .credit-slider-selected-value-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .credit-slider-selected-value,
          .credit-slider-selected-price {
            font-size: $heading-1;
            line-height: 28px;
            font-weight: $font-weight-semibold;
            color: #202020;
          }
        }

        .range {
          margin-bottom: 1.5rem;
          .range__mark-text {
            display: block;
            position: absolute;
            font-size: $text-14;
            line-height: $text-14;
            font-weight: $font-weight-medium;
            color: $gray-15;
            margin-top: 24px;
            transform: translateX(-40%);
          }
        }
      }
    }
  }

  .secondary-banner-container {
    background: $gray-1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  .static-content-container {
    width: 75.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    gap: 3.75rem;
    .secondary-banner {
      display: flex;
      height: 50px;
      padding: 12px 273px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid $gray-13;
      background: $gray-16;
      width: 1206px;

      &-text {
        color: #1f2937;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }

      .link {
        color: $blue-11 !important;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }
    }
    .static-content-customers {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;
      align-self: stretch;

      &-text {
        color: #202020;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 28.8px */
      }

      &-image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 24px 54px;
        align-self: stretch;
        flex-wrap: wrap;

        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 48px;
        }
      }
    }

    .static-content-g2-rating-wrapper {
      display: flex;
      padding: 0px 278px;
      flex-direction: column;
      align-items: center;
      align-self: stretch;

      .static-content-g2-rating {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 310px;
        margin-bottom: 46px;

        &-divider {
          width: 1.628px;
          height: 22.787px;
          background: #d1d5db;
        }
      }
      .static-content-g2-certificates-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        margin-top: 60px;
  
        &-text {
          color: #202020;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 28.8px */
        }
      }
    }
    .static-content-testimonials {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;
      align-self: stretch;
      &-text {
        color: #202020;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 28.8px */
      }
      &-list {
        display: flex;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        margin-bottom: 48px;
        &-item {
          display: flex;
          padding: 24px;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          flex: 1 0 0;
          align-self: stretch;
          border-radius: 4px;
          background: $gray-19;

          &-message {
            color: $blue-15;
            font-family: Matter;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 24px */
            align-self: stretch;
          }

          &-user-info {
            display: flex;
            align-items: flex-end;
            gap: 28px;

            &-image {
              display: flex;
              width: 83px;
              height: 97px;
              flex-shrink: 0;

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }

            &-name-role {
              display: flex;
              height: 54.7px;
              flex-direction: column;
              align-items: flex-start;
            }

            &-name {
              color: $blue-15;
              font-family: Matter;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 134%; /* 21.44px */
            }

            &-role {
              color: $gray-15;
              font-family: Matter;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 16.8px */
            }
          }
        }

        & .testimonial-1 {
          .color {
            color: $blue-31;
          }
        }

        & .testimonial-2 {
          .color {
            color: #9652c5;
          }
        }

        & .testimonial-3 {
          .color {
            color: #37b489;
          }
        }
      }
    }
  }
  .bs-ml-15-rem {
    margin-left: 14.1rem !important;
  }
  .bs-ml-20-rem {
    margin-left: 20rem !important;
  }
  .bs-ml-23-rem {
    margin-left: 21.3125rem !important;
  }
}

.outreach-plans-card {
  width: 61.25rem;

  .pricing-card {
    .card {
      border-radius: 0px;
      height: 30.9375rem;
      width: 15.3125rem;
      padding: 1.5rem;
      border-right-width: 0px;
    }
    &:first-child {
      .card {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }
    &:last-child {
      .card {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-right-width: 1px;
      }
    }
    &__price-wrapper {
      margin-top: 1.25rem;
    }
    &__features {
      margin-top: 1rem;
      .range-wrap {
        margin-top: 1.875rem;
      }
      .range {
        &__mark {
          &:first-child {
            left: 2px !important;
          }
        }
        &__thumb {
          &-first {
            transform: translate(3px, -5.5px) !important;
          }
          &-last {
            transform: translate(179px, -5.5px) !important;
          }
        }
      }
      ul > li {
        .feature-tooltip {
          text-decoration: underline;
          text-decoration-style: dashed;
          text-decoration-color: $gray-11;
          text-underline-offset: 4px;
        }
      }
    }
    .ghost-secondary-new.btn-ghost-primary {
      border: 1px solid $gray-15;
      color: $gray-15;
      &:hover {
        color: $gray-15;
      }
    }
    .ghost-primary-new.btn-ghost-primary {
      &:hover {
        background: $blue-11;
      }
      &:active {
        color: $white;
      }
    }
    .ghost-primary-new-disable.btn-ghost-primary:disabled {
      opacity: 0.35;
      border: 1px solid $blue-11;
      color: $blue-11;
      &:hover {
        opacity: 0.35;
        color: $blue-11;
        background-color: transparent;
        cursor: not-allowed !important;
      }
    }
    @media screen and (max-width: $screen-1366) {
      .btn-ghost-primary,
      .btn-solid-primary {
        font-size: 0.8125rem;
      }
    }
  }

  .best-value-plan {
    .card {
      border-top-width: 1px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-right-width: 1px;
    }
    &__label-wrapper {
      width: 15.3125rem;
      margin-bottom: -1px;
      .bs-tag {
        cursor: default !important;
        margin-right: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border: 1px;
        font-size: 0.5rem;
      }
    }
  }
  @media screen and (max-width: $screen-1366) {
    width: 50rem;
    .pricing-card {
      .card {
        height: 27.5rem;
        width: 12rem;
      }
    }
    .best-value-plan {
      &__label-wrapper {
        width: 12rem;
      }
    }
  }
  @media screen and (max-width: $screen-1280) {
    width: 46rem;
    .pricing-card {
      .card {
        height: 27.5rem;
        width: 11.25rem;
      }
    }
    .best-value-plan {
      &__label-wrapper {
        width: 11.25rem;
      }
    }
  }
}

.billing-summary-modal {
  .modal-dialog {
    width: 25.5625rem;
    height: 23.5625rem;
  }
  .modal-header {
    border-bottom-width: 0px;
  }

  .modal-body {
    .divider-bottom {
      border-bottom: 2px solid $gray-13;
      padding-bottom: 0.9375rem;
      margin-bottom: 0.9375rem;
      margin-top: 0.625rem;

      .promo-code-container {
        .coupon-code-input-wrapper {
          padding-bottom: 0.625rem;
          .coupon-code-input.bs-input-affix-wrapper {
            background: $gray-25;
            border-color: $gray-16;
          }
          .coupon-code-input {
            height: 2.25rem;
            align-items: center;
            .bs-input {
              background: $gray-25;
            }
            .input-error {
              height: inherit;
              background: $gray-25;
              align-items: center;
            }
            .input-succeeded {
              height: inherit;
              background: $gray-25;
              align-items: center;
              input {
                pointer-events: none;
              }
              i {
                cursor: pointer;
              }
            }
            label {
              font-style: normal;
              font-weight: 500;
              font-size: 0.5rem;
              line-height: 14px;
              width: 100%;
              text-align: right;
            }

            .icon-loading {
              i {
                animation: spin 2s linear infinite;
              }
            }
          }
        }
      }
    }
    .loader-container {
      height: 10px;
      img {
        height: 1.5rem;
      }
    }
  }
}

.solid-primary-new.btn-solid-primary {
  background: $blue-11;
  &:hover {
    background: $blue-20;
  }
}
