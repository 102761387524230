.email-account-daily-sending-limit {
  .header-btn {
    &.disabled {
      cursor: not-allowed;
    }
  }
  .total-number-title {
    font-size: $text-14;
    font-weight: $font-weight-semibold;
    color: $blue-15;
  }

  .total-number-caption {
    font-size: $text-12;
    font-weight: $font-weight-normal;
    color: $gray-15;
  }

  .width-40 {
    width: 40% !important;
  }

  .transparent-background {
    background: transparent;
  }

  .link-button {
    color: $blue-6;
    text-decoration: underline;
    background: transparent;
    border: none;
  }

  .snackbar {
    position: absolute;
    bottom: 5%;
    width: 100%;
    left: 20%;
    z-index: 1;
  }

  .snackbar-div {
    display: flex;
    background: $blue-15;
    color: $gray-1;
    width: 70%;
    z-index: 100;
  }

  .snackbar-icon {
    width: 10%;
  }

  .snackbar-content {
    width: 90%;
    font-size: 14px;
  }

  .close-button {
    background: transparent;
    border: none;
  }

  .alert {
    margin: 0;
  }
}

.text-caplitalize {
  text-transform: capitalize;
}

.confirmation-modal {
  &.confirm-modal-width {
    .modal-dialog {
      .modal-content {
        .bs-modal-body {
          padding: 1.875rem !important;
        }
      }
    }
  }
  &.disable-confirmation-modal {
    .modal-dialog {
      width: 31.25rem !important;
    }
  }
  &.no-seat-confirmation-modal {
    .modal-dialog {
      width: 29rem !important;
    }
  }
  .modal-dialog {
    width: 28.125rem !important;
    .modal-header {
      border-bottom: none;
      padding: 0px 2rem !important;
      position: relative;
      button.close {
        padding: 0;
        position: absolute;
        top: 0.9375rem;
        right: 1.875rem;
      }
    }
    .modal-footer {
      padding: 0;
      padding-top: 0.938rem;
      margin: 0 1.875rem 0.938rem 1.875rem;
      border-top: 0.125rem solid $gray-16;
      border-radius: 0.0625rem;
      .bs-modal-footer-action-buttons {
        margin: 0;
      }
    }
  }
  .confirmation-modal-header {
    display: flex;
    padding-top: 1.5rem;
    padding-right: 0.3125rem;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
    h2 {
      font-weight: $font-weight-semibold;
      font-size: 1rem;
      color: $gray-10;
      margin: 0;
      margin-left: 0.9375rem;
    }
  }
  .confirmation-modal-content {
    .confirmation-modal-text {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $blue-15;
      margin-bottom: 0;

      &:nth-of-type(2) {
        display: flex;
        margin-top: 1.25rem;
        span.checkbox-text {
          margin-left: 0.625rem;
        }
      }
    }

    .bs-checkbox-wrapper .bs-checkbox-inner {
      border-color: $gray-11;
      border-radius: 0.25rem;

      &:after {
        top: 43%;
        width: 0.3125rem;
      }
    }
  }
}

.sending-limit-input {
  .bs-input-number-container {
    .bs-input-number-input {
      width: 5.5rem;
      background: $gray-19;
      color: $blue-15;
      border-radius: 0.25rem;
    }
    .bs-input-number-handler-wrap {
      background: $gray-16;
      border-radius: 0.25rem;
    }
  }
}
