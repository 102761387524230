.progress-container {
  display: inline-flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 8px;
  border: 1px solid $gray-13;
  background: $white-text;
  box-shadow: 3px 8px 24px -6px rgba(15, 34, 67, 0.16);
  position: absolute;
  z-index: 10;
  max-width: 19.375rem;

  .progress-skeleton-card {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 278px;
  }
  & .no-results-found-container {
    display: flex;
    align-items: center;
    height: 190px;
    & .no-results-found {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 12.5rem;
      height: 6rem;
      gap: 8px;
      &.no-results-found span {
        color: $blue-15;
        width: 100%;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: $font-weight-semibold;
        line-height: 24px;
      }
    }
  }
  .progress-main-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 17.375rem;
    gap: 0px 12px;

    .progress-single-tab {
      display: flex;
      align-items: center;
      gap: 8px;

      .progress-title-bar {
        display: flex;
        width: 11.875rem;
        align-items: center;
        gap: 0.5rem;

        .progress-rectangle-border {
          width: 0.1875rem;
          height: 1.5rem;
          flex-shrink: 0;
          border-radius: 4px;
        }

        .accordion-title {
          display: flex;
          gap: 0.5rem;
        }

        .progress-title-text {
          color: $blue-15;
          font-family: Inter;
          font-size: $text-14;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 1rem;
        }
        .chevron-down {
          display: flex;
        }
        // chevron
        .chevron-icon-down {
          transition: transform 0.5s ease-in-out;
        }

        .chevron-icon-down.rotate {
          transform: rotate(180deg);
        }
      }

      .progress-score {
        min-width: 5rem;
        display: flex;
        justify-content: flex-start;
        height: 1.125rem;
        align-items: center;
        gap: 4px;

        .progress-main-score {
          line-height: 1.125rem;
          color: $blue-15;
          font-family: Inter;
          font-size: $text-16;
          font-style: normal;
          font-weight: $font-weight-semibold;
        }

        .progress-percentage {
          color: $gray-15;
          font-family: Inter;
          font-size: $text-12;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 1.125rem;
          margin-top: 0.125rem;
        }
      }
    }

    .accordion-container-popup {
      max-height: 0px;
      overflow: hidden;
      margin-top: 0px;
      background: $gray-19;
      transition: max-height 0.4s ease-in-out, margin-top 0.5s ease-in-out;
      border-radius: 4px;

      &.open {
        margin-top: 8px;
        max-height: 500px;
      }

      // Accordion start
      .accordion-main-card {
        display: flex;
        padding: 0.5rem 0rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.75rem;

        .accordion-tab-bar {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 0.75rem;
          .accordion-single-tab {
            display: flex;
            width: 17.375rem;
            align-items: center;
            gap: 0.5rem;

            .accordion-title-bar {
              display: flex;
              width: 11.875rem;
              align-items: center;
              gap: 0.5rem;
              flex-shrink: 0;

              .accordion-rectangle-border {
                width: 0.1875rem;
                height: 1.5rem;
                flex-shrink: 0;
                border-radius: 4px;
                background: $gray-19;
              }
              .accordion-title-text {
                color: $blue-15;
                font-family: Inter;
                font-size: $text-14;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 1rem;
              }
            }
            .accordion-score {
              width: 5rem;
              align-items: center;
              display: flex;

              .accordion-main-score {
                line-height: 1.125rem;
                color: $blue-15;
                font-family: Inter;
                font-size: $text-16;
                font-style: normal;
                font-weight: $font-weight-semibold;
              }

              .accordion-percentage {
                color: $gray-15;
                font-family: Inter;
                font-size: $text-12;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 1.125rem;
                margin-left: 0.25rem;
                margin-top: 0.125rem;
              }
            }
          }
        }
      }
    }
  }
}
