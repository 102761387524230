.score-factor-details-wrapper {
  border: 0.0625rem solid $gray-13;
  border-bottom: 0;

  background: $gray-1;

  padding: 0.75rem 1.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-of-type {
    border-radius: 0.25rem 0.25rem 0px 0px;
  }

  &:last-of-type {
    border-bottom: 0.0625rem solid $gray-13;
    border-radius: 0px 0px 0.25rem 0.25rem;
  }

  .score-factor-details-content {
    max-width: 46.875rem;
  }

  .score-factor-details-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    p {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $blue-15;

      margin: 0;
    }

    img {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  .score-factor-details-description {
    p,
    a {
      font-weight: $font-weight-medium;
      font-size: 0.75rem;
      line-height: 1.25rem;

      color: $gray-15;

      text-decoration: none;
    }
    p {
      margin-left: 1.875rem;
      margin-top: 0.5rem;
    }
    a {
      color: $blue-11;
    }
  }

  .score-factor-details-actionable-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    max-width: 31.25rem;
    margin-left: 1.875rem;

    .score-factor-details-actionable-list-item {
      display: flex;
      align-items: center;
      gap: 1rem;

      height: 28px;
      transition: all 0.2s ease;
      padding: 0.25rem 0.5rem;

      &:hover {
        background: $blue-17;
      }

      h3 {
        padding: 0;
        margin: 0;

        font-style: normal;
        font-weight: $font-weight-medium;
        font-size: 0.75rem;
        line-height: 1.25rem;

        color: $blue-15;

        display: flex;
        align-items: center;
        gap: 0.5rem;

        img {
          width: 1rem;
          height: 1rem;
        }

        .smtp-label-container {
          padding: 1px 5px;

          width: 39px;
          height: 18px;

          span {
            font-weight: $font-weight-medium;
            font-size: 0.625rem;
            line-height: 1rem;
          }
        }
      }
      .score-factor-details-actionable {
        button {
          padding: 0;
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  .switchToggle {
    width: 40px;
    height: 20px;

    margin: 0;
    padding: 0;

    label {
      min-width: 100%;
      width: 100%;
      height: 100%;

      margin: 0;

      &::after {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.score-factor-details-actionable {
  button {
    line-height: 1.25rem !important;
  }
  .bs-select-box {
    width: 11.375rem;
    height: 1.875rem;

    background: #f9fafb;
    border: 0.0625rem solid #e5e7eb;
    border-radius: 0.25rem;

    gap: 0.5rem;

    .bs-select-box-text {
      font-weight: $font-weight-medium;
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: $blue-15;
    }
    .bs-select-box-icon-wrapper {
      margin: 0;
      justify-content: center;
      align-items: center;
      color: #9ca3af;
    }
  }

  &--select {
    li {
      font-weight: $font-weight-medium;
      font-size: 12px !important;
      line-height: 1.125rem !important;
      padding: 0.625rem 1rem !important;
    }
  }
}
