.email-account-health-score-container {
    display: flex;
    overflow: hidden;
    padding: 1.5rem;
    .table-responsive::-webkit-scrollbar {
        height: 25px;
    }
      
    .table-responsive::-webkit-scrollbar-track {
        background: #FFFFFF;
    }
    
    .table-responsive::-webkit-scrollbar-thumb {
        border: 9px solid #FFFFFF;
        border-radius: 30px;
    }
}

.setup-score-field {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.125rem 0.5rem;

    width: 2rem;
    height: 1.5rem;

    border: 1px solid transparent;
    border-radius: 20px;

    span {
        font-weight: $font-weight-semibold;
        font-size: 0.75rem;
        line-height: 1.25rem;
    }

    &.poor {
        background: $red-17;
        border-color: $red-18;

        span {
            color: $red-18;
        }
    }

    &.average {
        background: $yellow-11;
        border-color: $yellow-13;

        span {
            color: $yellow-13;
        }
    }

    &.excellent {
        background: $green-21;
        border-color: $green-22;

        span {
            color: $green-22;
        }
    }
}