.create-email-infrastructure-header,
.email-accounts-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;

  height: 1.25rem;

  &--title {
    color: $blue-15;
    font-weight: $font-weight-semibold;
    font-size: 1rem;
    line-height: 1.25rem;
    transition: all 0.2s ease;
  }

  a {
    cursor: pointer;
    text-decoration: none !important;
    &:hover {
      color: $blue-11;
    }
  }

  &--icon {
    color: $gray-11;

    svg {
      width: 1rem;
      height: 1rem;
    }

    img {
      width: 1.25rem;
      height: 0.875rem;
    }
  }
}

.create-email-infrastructure {
  height: 100%;
  position: relative;

  .bs-tabs-top > .bs-tabs-nav {
    margin: 0px !important;
    background: $white;
  }

  .bs-input-affix-wrapper:focus,
  .bs-input-affix-wrapper:focus-within {
    box-shadow: none;
    border-color: $blue-11;
  }

  &__tabs {
    height: 100%;

    .bs-tabs-nav {
      padding: 0 1.5rem;
      border-bottom: 1px solid $gray-14;
      box-shadow: 0px 2px 4px 0px $gray-13;

      .bs-tabs-nav-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.75rem;
      }

      .bs-tabs-tab {
        padding: 1.125rem 0 !important;
        margin-right: 0 !important;
      }

      .bs-tabs-ink-bar {
        height: 3px !important;
        background-color: $blue-11 !important;
        border-radius: 2px 2px 0px 0px !important;
      }
    }

    .bs-tabs-content {
      height: 100%;
    }

    &--item {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      height: 1.25rem;

      &--chevron {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1.25rem;

        color: $gray-11;
      }

      &--label {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .label-text {
          color: $gray-15;
          font-size: 0.875rem;
          font-weight: $font-weight-medium;
          line-height: 1.25rem;

          &.active {
            color: $blue-11;
            font-weight: $font-weight-semibold;
          }
        }

        .step-complete,
        .step-incomplete {
          width: 1.125rem;
          height: 1.125rem;
          border-radius: 50%;
        }

        .step-complete {
          background-color: $blue-11;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 0.875rem;
            height: 0.875rem;
            color: $white;
          }
        }
        .step-incomplete {
          border: 1.5px solid $blue-14;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 3.25rem;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 1.5rem;
    background-color: $white;
    box-shadow: 0px 0px 10px 0px rgba(4, 21, 76, 0.08);

    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;

    &.visible {
      transform: translateY(0);
    }

    &--container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 774px;
      width: 100%;
      flex: 1;
    }

    &--left {
      display: flex;
      justify-content: center;
      align-items: center;

      .view-cart-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.75rem;

        padding: 0.25rem 0.5rem;

        border-radius: 0.25rem;
        transition: all 0.2s ease;
        cursor: pointer;

        &.noDomains {
          cursor: default;
          &:hover {
            background: transparent;
          }
        }

        &:hover {
          background: $gray-16;
        }

        .view-cart-btn--icon {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 1.25rem;
          height: 1.25rem;

          svg {
            width: 100%;
            height: 100%;
            color: $gray-15;
          }
        }

        .view-cart-btn--text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.25rem;

          span {
            color: $blue-15;
            font-size: 0.75rem;
            font-weight: $font-weight-medium;
            line-height: 1rem;
          }
        }
      }
    }

    &--right {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }

    &--left--divider {
      width: 1px;
      height: 2rem;
      background-color: $gray-13;

      margin-left: 0.5rem;
      margin-right: 1rem;
    }

    &--left--total {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;

      span {
        color: $blue-15;
        font-size: 0.75rem;
        font-weight: $font-weight-semibold;
        line-height: 1rem;
      }
    }

    &--cta {
      min-width: 6.25rem;
      gap: 0.25rem;
      &.continue-btn-lg {
        width: 12.0625rem;
      }
    }
  }

  .purchase-domains-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    background-color: $gray-19;

    .search-bar-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      border-bottom: 1px solid #eaedef;

      padding: 1rem 1.5rem;

      .input-container {
        display: flex;
        align-items: center;

        .bs-input-affix-wrapper {
          height: 2.25rem;
          margin-right: 0.75rem;
          max-width: 31.25rem;
          width: 31.25rem;

          padding: 0.375rem 1rem;

          .bs-input {
            line-height: 20px;
            color: $blue-15;

            &::placeholder {
              color: $gray-11;
            }
          }
        }

        .search-button {
          height: 2.25rem;
          width: 8.75rem;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 1.25rem;
        }
      }

      .info-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;

        .info-item {
          display: flex;
          align-items: center;
          color: $gray-15;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 1.125rem;
          text-wrap: nowrap;
        }

        .number-circle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: $gray-16;
          color: $blue-15;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 0.75rem;
          margin-right: 0.25rem;
        }
      }
    }
  }

  .create-manual-email-account-container {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid #eaedef;

    .inputs {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 12px;
      align-self: stretch;
      background-color: white;

      .bs-input {
        line-height: 20px;
        color: $blue-15;
        &::placeholder {
          color: $gray-11;
        }
      }

      .bs-input-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 0 !important;
        width: 196px !important;
      }

      .bs-input-affix-wrapper {
        padding: 6px 16px;
        line-height: 20px;
        height: 2rem;

        &.input-error {
          border-color: $red-6;

          &:focus,
          &:focus-within {
            border-color: $red-6 !important;
            box-shadow: none;
          }
        }
      }

      .shd-input--label {
        margin-bottom: 0 !important;
      }

      .bs-input {
        line-height: 20px;
      }

      .bs-input-label-prefix {
        color: $gray-15;
        font-size: 12px;
        font-style: normal;
        font-weight: $font-weight-semibold;
        line-height: 16px;
        margin-bottom: 4px;
      }

      .profile-image-btn {
        border: 1px solid $gray-13;
        width: 8.75rem;
        height: 2rem;

        .upload-icon {
          color: $gray-15;
        }

        span {
          color: $gray-15;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 1.25rem;
          text-wrap: nowrap;
          gap: 0.25rem;
        }

        &.uploaded {
          background-color: #f9fafb;
        }
      }

      .add-btn {
        width: 6.25rem;
        height: 32px;

        span {
          color: $blue-11;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 1.25rem;
        }

        .add-icon {
          margin-left: 0.5rem;
        }
      }

      > div:nth-child(1) {
        position: relative;

        .email-limit-error {
          position: absolute;
          top: 100%;
          left: 0;
          color: #f5222d;
          font-size: 0.625rem;
          max-width: 20rem;
          line-height: 1rem;
          text-wrap: nowrap;
          overflow: hidden;
        }
      }

      > div:nth-child(2) {
        position: relative;

        .email-limit-error {
          position: absolute;
          top: 100%;
          left: 0;
          color: #f5222d;
          font-size: 0.625rem;
          max-width: 20rem;
          line-height: 1rem;
          text-wrap: nowrap;
          overflow: hidden;
        }
      }

      > div:nth-child(3) {
        position: relative;

        .email-limit-error {
          position: absolute;
          top: 100%;
          left: 0;
          color: #f5222d;
          font-size: 0.625rem;
          max-width: 20rem;
          line-height: 1rem;
          text-wrap: nowrap;
          overflow: hidden;
        }
      }
    }

    .create-email-accounts-email-address-component.email-address-border-error {
      .bs-input-affix-wrapper,
      .email-domains-select-btn {
        border-color: $red-6 !important;
      }

      &:hover,
      &:focus-within {
        .bs-input-affix-wrapper,
        .email-domains-select-btn {
          border-color: $red-6 !important;
        }
      }
    }

    .create-email-accounts-email-address-component {
      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: end;

      &:hover,
      &:focus-within {
        .bs-input-affix-wrapper,
        .email-domains-select-btn {
          border-color: $blue-11;
        }
      }

      .bs-input-affix-wrapper {
        border: 1px solid $blue-14;
        border-right: none;
        border-radius: 4px 0 0 4px;
      }

      .bs-input-affix-wrapper > input.bs-input {
        width: 100% !important;
        border: none;
        border-radius: 0;
      }

      .email-with-select-domain {
        width: auto !important;
      }

      .shd-button:disabled {
        opacity: 1;
        cursor: default;
      }

      .email-domains-select-btn {
        border: 0.0625rem solid $blue-14;
        border-left: none;

        border-radius: 0 0.25rem 0.25rem 0;
        min-width: 9.875rem;
        height: 2rem;
        background-color: transparent !important;
        height: 2rem;

        &:focus,
        &:focus-within {
          outline: none;
        }

        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 4px;
          left: 0;
          width: 1px;
          height: 22px;
          background-color: $blue-14;
          border-radius: 1px;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 0.25rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:hover {
          background-color: transparent;
        }

        .email-domains-select-content {
          border: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $blue-15;
          font-size: 0.875rem;
          line-height: 1.25rem;

          .email-domains-select-no-domains {
            color: $gray-15;
            font-weight: $font-weight-normal;
          }
        }

        .email-domains-select-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1rem;
          height: 1rem;

          svg {
            width: 100%;
            height: 100%;
            color: $gray-15;
          }
        }
      }
    }
  }

  .create-emails-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $gray-19;

    .emails-header {
      display: inline-flex;
      align-items: center;
      gap: 8px;

      p {
        color: $blue-15;
        font-size: 16px;
        font-style: normal;
        font-weight: $font-weight-semibold;
        line-height: 24px;
      }

      .emails-count {
        display: flex;
        padding: 2px 6px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border-radius: 4px;
        background: $gray-13;

        span {
          color: $gray-15;
          font-size: 12px;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 16px;
        }
      }
    }
  }

  .contact-form-wrapper {
    padding: 24px;
    background-color: $gray-19;
    height: calc(100% - 52px);

    .contact-form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      background: transparent;
      border-radius: 4px;
      border: none;
      height: 100%;

      .notification-box {
        display: flex;
        padding: 4px 8px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid $blue-13;
        background: $blue-17;
        margin-bottom: 8px;

        .notification-content {
          display: flex;
          align-items: flex-start;
          gap: 4px;

          .info-icon {
            color: #2563eb;
          }

          .notification-message {
            color: $blue-15;
            font-size: 12px;
            line-height: 16px;
            font-style: normal;
            font-weight: 400;
            margin: 0;
          }
        }
      }

      .form-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 24px;
        background: white;
        border: 1px solid #eaedef;
        border-radius: 4px;

        .bs-input-container {
          display: flex;
          flex-direction: column;
          margin: 0;
        }

        .bs-input-affix-wrapper {
          padding: 6px 16px;
          line-height: 1.25rem;
          height: 2rem;
        }

        .bs-input-affix-wrapper:focus-within {
          box-shadow: none;
          border-color: $blue-11;
        }

        .bs-input-affix-wrapper.input-error:focus,
        .bs-input-affix-wrapper.input-error:focus-within {
          box-shadow: none;
          border-color: #f5222d;
        }

        .bs-input-label-prefix {
          color: $gray-15;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          margin-bottom: 0.25rem !important;
        }

        .bs-input {
          line-height: 1.25rem;
        }

        .input-error-container .bs-input-label-suffix {
          font-size: 10px;
        }

        .stdropdown-container {
          border: 1px solid $blue-14 !important;
          border-radius: 4px !important;
          background: white;
        }

        .stsearch-box {
          padding: 0 !important;
        }

        .stdropdown-tool {
          padding-right: 16px !important;
        }

        .stsearch-box input {
          border: none !important;
          padding-left: 16px !important;
          padding-right: 0px !important;
          padding-top: 5px !important;
          padding-bottom: 5px !important;
          color: $blue-15;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          &::placeholder {
            color: #9ca3af;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }

        .contact-form {
          display: flex;
          gap: 1.5rem;
          width: 100%;

          .bs-input {
            color: $blue-15;
            line-height: 20px;

            &::placeholder {
              color: $gray-11;
            }
          }

          .name-fields {
            display: flex;
            gap: 0.8125rem;
            flex: 2;
            width: 50%;
            input:first-child {
              flex: 1;
            }

            input:last-child {
              flex: 1;
            }
          }

          .company-name {
            flex: 2;
          }
        }

        .address-field {
          display: flex;
          width: 774px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .bs-input {
            line-height: 20px;
            color: $blue-15;

            &::placeholder {
              color: $gray-11;
            }
          }
        }

        .country-city-state-value {
          color: $blue-15;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .country-city-state-placeholder {
          color: $gray-11;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .country-state-fields {
          display: flex;
          align-items: flex-start;
          gap: 1.5rem;

          .bs-input-label-suffix {
            color: $red-6;
            font-size: 10px;
            margin-bottom: 0;
            line-height: 20px;
            margin-top: 0.125rem;
          }

          .country-field {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 375px;

            .country-select-btn {
              border: 1px solid $blue-14;
              background: white;
              color: $blue-15;
              width: 375px;
              span {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
              }

              &:hover {
                border-color: $blue-5;
              }

              &:focus-within {
                border-color: $blue-5;
              }

              &.has-error {
                border-color: $red-6;
              }
            }

            .country-filter-dropdown {
              .country-filter-dropdown.email-filter .transfer-container {
                width: 16.5rem !important;
              }

              &.transfer-overlay {
                padding: 0.125rem 0 0 0;
              }
              .transfer-container {
                padding: 0;
                border: 0.0625rem solid $gray-13;
                box-shadow: 0.5rem 1rem 3.5rem -1.5rem rgba(156, 157, 161, 0.24),
                  0.125rem 0.125rem 1rem -0.1875rem rgba(4, 21, 76, 0.16);

                .transfer-body {
                  .transfer-search {
                    height: 2rem;
                    padding: 0.375rem 1rem;
                    border: 0.0625rem solid $gray-16;
                    margin-bottom: 0.5rem;
                  }

                  .transfer-list {
                    li {
                      height: 2rem;
                      padding: 0.375rem 1rem !important;

                      line-height: 20px !important;

                      &:hover,
                      &.active {
                        background: $blue-17;
                      }

                      .option-content {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
              }

              &.country-filter {
                .transfer-container {
                  width: 16.5rem !important;
                  .transfer-body {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }

          .state-field {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 375px;

            .state-select-btn {
              border: 1px solid $blue-14;
              background: white;
              color: $blue-15;
              width: 375px;
              span {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
              }

              &:hover {
                border-color: $blue-5;
              }

              &:focus-within {
                border-color: $blue-5;
              }

              &.has-error {
                border-color: $red-6;
              }
            }

            .state-filter-dropdown {
              .state-filter-dropdown.state-filter .transfer-container {
                width: 16.5rem !important;
              }

              &.transfer-overlay {
                padding: 0.125rem 0 0 0;
              }
              .transfer-container {
                padding: 0;
                border: 0.0625rem solid $gray-13;
                box-shadow: 0.5rem 1rem 3.5rem -1.5rem rgba(156, 157, 161, 0.24),
                  0.125rem 0.125rem 1rem -0.1875rem rgba(4, 21, 76, 0.16);

                .transfer-body {
                  .transfer-search {
                    height: 2rem;
                    padding: 0.375rem 1rem;
                    border: 0.0625rem solid $gray-16;
                    margin-bottom: 0.5rem;
                  }

                  .transfer-list {
                    li {
                      height: 2rem;
                      padding: 0.375rem 1rem !important;

                      line-height: 20px !important;

                      &:hover,
                      &.active {
                        background: $blue-17;
                      }

                      .option-content {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
              }

              &.state-filter {
                .transfer-container {
                  width: 16.5rem !important;
                  .transfer-body {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
        }

        .city-zip-fields {
          display: flex;
          align-items: flex-start;
          gap: 1.5rem;
          margin: 0;

          .bs-input {
            line-height: 20px;
            color: $blue-15;
            &::placeholder {
              color: $gray-11;
            }
          }

          .bs-input-label-suffix {
            color: $red-6;
            font-size: 10px;
            margin-bottom: 0;
            line-height: 22px;
            margin-top: 0.125rem;
          }

          .city-field {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 375px;

            .city-select-btn {
              border: 1px solid $blue-14;
              background: white;
              color: $blue-15;
              width: 375px;
              span {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
              }

              &:hover {
                border-color: $blue-5;
              }

              &:focus-within {
                border-color: $blue-5;
              }

              &.has-error {
                border-color: $red-6;
              }
            }

            .city-filter-dropdown {
              .city-filter-dropdown.city-filter .transfer-container {
                width: 16.5rem !important;
              }

              &.transfer-overlay {
                padding: 0.125rem 0 0 0;
              }
              .transfer-container {
                padding: 0;
                border: 0.0625rem solid $gray-13;
                box-shadow: 0.5rem 1rem 3.5rem -1.5rem rgba(156, 157, 161, 0.24),
                  0.125rem 0.125rem 1rem -0.1875rem rgba(4, 21, 76, 0.16);

                .transfer-body {
                  .transfer-search {
                    height: 2rem;
                    padding: 0.375rem 1rem;
                    border: 0.0625rem solid $gray-16;
                    margin-bottom: 0.5rem;
                  }

                  .transfer-list {
                    li {
                      height: 2rem;
                      padding: 0.375rem 1rem !important;

                      line-height: 20px !important;

                      &:hover,
                      &.active {
                        background: $blue-17;
                      }

                      .option-content {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
              }

              &.city-filter {
                .transfer-container {
                  width: 16.5rem !important;
                  .transfer-body {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }

          .zip-field {
            width: 375px !important;
          }
        }

        .phone-field-container {
          display: flex;
          width: 23.4375rem;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;

          .phone-field {
            display: flex;
            flex-direction: column;
          }

          .bs-input-label-suffix {
            color: $red-6;
            font-size: 10px;
            margin-bottom: 0;
            line-height: 22px;
            margin-top: 0.125rem;
          }

          .phone {
            display: flex;
            flex-direction: column;
            .phone-form-input {
              display: flex;
              align-items: center;

              .form-control {
                position: absolute;
                width: 327px;
                height: 2rem;
                padding-left: 0.75rem;
                background: $gray-1;
                border: 0.0625rem solid $blue-14;
                border-radius: 0 0.25rem 0.25rem 0;
                line-height: 1.25rem;
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;
                margin-left: 48px;

                font-weight: $font-weight-medium;
                font-size: 0.875rem;
                color: $blue-15;

                &::placeholder {
                  color: $gray-23;
                }

                &:focus,
                &:focus-within {
                  outline: none;
                  box-shadow: none;
                  border-color: $blue-11;
                }
              }
              .flag-dropdown {
                border: 0.0625rem solid $blue-14;
                border-right: none;
                border-radius: 0.25rem 0 0 0.25rem;
                background-color: transparent;
                padding: 0.5rem;
                width: 3rem;
                height: 2rem;
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;
                z-index: 1;
                position: relative;

                &:focus,
                &:focus-within {
                  outline: none;
                  box-shadow: none;
                  border-color: $blue-11;

                  & + .form-control {
                    border-left-color: $blue-11;
                  }
                }

                .selected-flag {
                  width: 2rem;
                  padding: 0;
                  border-radius: 0;
                  .flag {
                    .arrow {
                      left: 1.625rem;
                    }
                  }
                }
              }

              &.input-error {
                .form-control,
                .flag-dropdown {
                  border-color: $red-20;

                  &:focus,
                  &:focus-within {
                    outline: none;
                    box-shadow: none;
                    border-color: $red-20;

                    & + .form-control {
                      border-color: $red-20;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.suggested-domains-emails-container {
  margin: 1.5rem;
  margin-bottom: 3.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background: transparent;
  border-radius: 4px;
  border: none;

  .suggested-domains-emails {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 43.375rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 42.375rem;
    p {
      color: $blue-15;
      font-size: 1rem;
      font-style: normal;
      font-weight: $font-weight-semibold;
      line-height: 1.5rem;
      margin: 0;
    }

    .set-forwarding-link {
      color: $blue-11;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 1rem;

      &.disabled {
        opacity: 0.35;
        cursor: not-allowed;
      }
    }

    .update-forwarding-link {
      display: flex;
      flex-direction: row;
      width: 10.5rem;
      align-items: center;
      gap: 8px;
      p {
        color: $green-16;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1rem;
      }

      svg {
        display: block;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .upload-profile-image-link {
      display: flex;
      height: 20px;
      justify-content: center;
      align-items: center;

      .upload-icon {
        margin-right: 4px;
        color: $blue-11;
        height: 16px;
        width: 16px;
      }

      span {
        color: $blue-11;
        font-size: 12px;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 16px;
      }
    }

    .upload-profile-image-link.disabled {
      opacity: 0.35;
      pointer-events: none;
    }

    .update-forwarding-link .disabled {
      opacity: 0.35;
    }
  }

  .suggested-domains-emails-table-outer-container {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding-right: 8px;
  }

  .purchase-domains-height {
    height: calc(100vh - 330px - var(--banner-height, 0px));
  }

  .create-emails-height {
    height: calc(100vh - 312px - var(--banner-height, 0px));
  }

  .suggested-domains-emails-table-container {
    border: 0.0625rem solid $gray-16;
    border-radius: 0.25rem;
    width: 42.5rem;
  }

  .domains-emails-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;

    th,
    td {
      padding: 0;
      text-align: left;
      font-size: 0.875rem;
      border-bottom: 0.0625rem solid $gray-16;
      border-right: 0.0625rem solid $gray-16;
    }

    th:last-child,
    td:last-child {
      border-right: none;
    }

    th:nth-child(1),
    td:nth-child(1) {
      min-width: 31.25rem;
      max-width: 31.25rem;
    }

    th:nth-child(2),
    td:nth-child(2) {
      min-width: 8.625rem;
      max-width: 8.625rem;
    }

    th:nth-child(3),
    td:nth-child(3) {
      padding: 10px;
      min-width: 2.5rem;
      max-width: 2.5rem;

      span {
        padding: 0;
      }
    }

    td {
      padding: 0;
      height: 40px;
      span {
        color: $blue-15;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
        padding: 0 1rem;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .email-with-icon {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        padding: 0 1rem;

        img.email-accounts-profile-image {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          object-fit: cover;
        }

        .email-with-icon-text {
          padding: 0;
        }
      }
    }

    th {
      padding: 0;
      height: 46px;
      span {
        display: flex;
        color: $gray-15;
        padding: 0 1rem;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: $font-weight-bold;
        line-height: 1rem;
        text-wrap: nowrap;
      }
    }

    .add-to-cart-icon {
      display: block;
      cursor: pointer;
      color: $gray-15;
      border-radius: 0.25rem;
      transition: all 0.2s ease;
      &:hover {
        color: $blue-11;
        background-color: $blue-13;
      }
    }

    .remove-from-cart-icon {
      display: block;
      cursor: pointer;
      color: #059669;
      border-radius: 0.25rem;
      transition: all 0.2s ease;
      &:hover {
        color: $gray-15;
        background-color: $gray-13;
      }
    }

    .row-selected {
      background-color: $gray-19;
    }

    .remove-email-from-cart-icon {
      display: block;
      cursor: pointer;

      &:hover {
        border-radius: 4px;
        background-color: $red-19;
        color: $red-12;
      }
    }
  }
}

.create-email-infrastructure-cart {
  ::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.4375rem;
  }

  ::-webkit-scrollbar-track {
    background: $gray-1;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-23;
    border-radius: 0.25rem;
  }

  .modal-dialog {
    width: 581px;
    max-width: none;
    height: 100%;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 0px;
    border-radius: 1.25rem;
  }

  .modal-content {
    height: 100%;
    max-height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-header {
    display: flex;
    padding: 1.25rem 1.875rem;
    min-height: auto;
    border-bottom: 2px solid $gray-13;
    .modal-title {
      color: $black;
      line-height: 1.25rem;
    }
    .close-icon-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: $blue-15;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-body {
    padding: 0.875rem 1.875rem 0 1.875rem;

    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .cart-accordion {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      .accordion__item {
        border: none !important;
      }
      .accordion__button {
        padding: 0;
        background-color: transparent;
        &::before {
          display: none;
        }
        .accordion-item-title {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          .accordion-item-title-text {
            color: $black;
            font-size: 1rem;
            font-weight: $font-weight-semibold;
            line-height: 1.25rem;
          }
          .domains-badge {
            display: flex;
            padding: 0.125rem 0.375rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.25rem;
            border-radius: 0.25rem;
            background-color: $gray-13;
            height: 1.25rem;
            span {
              color: $gray-15;
              font-size: 0.75rem;
              font-weight: $font-weight-semibold;
              line-height: 1rem;
            }
          }
          svg {
            width: 1.25rem;
            height: 1.25rem;
            color: $gray-15;
          }
        }
      }
      .accordion__panel {
        padding: 0;
        padding-top: 0.5rem;

        .cart-data-list {
          display: flex;
          flex-direction: column;

          .cart-data-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            gap: 0.5rem;

            padding: 1rem 0;
            border-bottom: 1px solid $gray-13;

            .cart-data-item-name {
              color: $blue-15;
              font-weight: $font-weight-medium;
              line-height: 1.25rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .cart-data-item-info {
              display: flex;
              align-items: center;
              gap: 1rem;

              .cart-data-item-price {
                color: $blue-15;
                font-size: 1rem;
                font-weight: $font-weight-medium;
                line-height: 1.25rem;
              }

              .cart-data-item-remove {
                width: 1.25rem;
                height: 1.25rem;
                cursor: pointer;
                color: $gray-15;
                border-radius: 0.25rem;
                transition: all 0.2s ease;

                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  width: 100%;
                  height: 100%;
                }

                &:hover {
                  color: $red-12;
                  background-color: $red-19;
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-footer {
    padding: 1rem 1.875rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    max-height: unset;
    min-height: unset;

    box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
      8px 16px 56px -24px rgba(156, 157, 161, 0.24);

    .cart-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;

      .cart-info-left {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        p {
          margin: 0;
          color: $blue-15;
          font-size: 0.75rem;
          font-weight: $font-weight-medium;
          line-height: 1rem;
        }
      }

      .cart-info-right {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        p {
          margin: 0;
          color: $blue-15;
          font-size: 0.875rem;
          font-weight: $font-weight-semibold;
          line-height: 1.125rem;
        }
      }
    }
    .cart-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;

      .clear-all-btn {
        padding: 0;
        text-decoration: none;
        width: 3.4375rem;
      }
      .cart-next-button {
        min-width: 6.25rem;
        gap: 0.25rem;
        &.continue-btn-lg {
          width: 12.0625rem;
        }
      }
    }
  }

  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &.show {
    transform: translateX(0);
  }

  &.hide {
    transform: translateX(100%);
  }
}

.email-filter-dropdown {
  .email-filter-dropdown.email-filter .transfer-container {
    width: 16.5rem !important;
  }

  &.transfer-overlay {
    padding: 0.125rem 0 0 0;
  }
  .transfer-container {
    padding: 0;
    border: 0.0625rem solid $gray-13;
    box-shadow: 0.5rem 1rem 3.5rem -1.5rem rgba(156, 157, 161, 0.24),
      0.125rem 0.125rem 1rem -0.1875rem rgba(4, 21, 76, 0.16);

    .transfer-body {
      .transfer-search {
        height: 2rem;
        padding: 0.375rem 1rem;
        border: 0.0625rem solid $gray-16;
        margin-bottom: 0.5rem;
      }

      .transfer-list {
        li {
          height: 2rem;
          padding: 0.375rem 1rem !important;

          line-height: 20px !important;

          &:hover,
          &.active {
            background: $blue-17;
          }

          .option-content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .transfer-footer {
        display: flex;
        width: 264px;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        border: 0.0625rem solid $gray-13;

        .bs-checkbox-wrapper {
          color: $blue-15;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }

        .shd-button {
          display: flex;
          height: 32px;
          padding: 6px 16px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  &.email-filter {
    .transfer-container {
      width: 16.5rem !important;
      .transfer-body {
        padding-bottom: 0;
      }
    }
  }
}

.shd-modal.create-email-infrastructure-confirmation-modal .modal-content {
  border: 1px solid #e5e7eb !important;
  min-width: 28.25rem !important;
  min-height: auto !important;
}

.create-email-infrastructure-confirmation-modal {
  .modal-dialog {
    max-width: 28.125rem !important;
  }

  .shd-modal--header {
    padding-bottom: 1rem !important;
    .shd-modal--title {
      line-height: 1.5rem !important;
    }
  }
  .shd-modal--body {
    padding-bottom: 1.5rem !important;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      margin: 0;
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }

  .shd-modal--divider {
    height: 1px !important;
  }

  .shd-modal--footer {
    padding: 1.25rem 1.5rem !important;
  }
}

.shd-modal.upload-profile-image-modal .modal-content {
  border: none !important;
}

.upload-profile-image-modal {
  .modal-dialog {
    max-width: 28.125rem !important;
  }

  .shd-modal--header {
    padding-bottom: 1.5rem !important;
    .shd-modal--title {
      line-height: 1.5rem !important;
    }
  }
  .shd-modal--body {
    .upload-profile-image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      align-self: stretch;

      p {
        margin-bottom: 0;
      }

      .avatar-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        align-self: stretch;
        height: 7.8125rem;
      }

      .info-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        align-self: stretch;

        .image-note {
          width: 246px;
          color: $gray-15;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 18px;
        }

        .alert-box {
          display: flex;
          padding: 4px 8px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid $blue-13;
          background: $blue-17;
          margin-bottom: 24px;

          .alert-content {
            display: flex;
            align-items: flex-start;
            gap: 4px;
            text-wrap: nowrap;

            .icon-info {
              display: block;
              color: $blue-31;
            }

            .alert-note {
              color: $blue-15;
              font-size: 12px;
              font-style: normal;
              font-weight: $font-weight-normal;
              line-height: 16px;
              text-wrap: nowrap;
            }
          }
        }
      }
    }
  }

  .shd-modal--divider {
    height: 1px !important;
  }

  .shd-modal--footer {
    padding: 1.25rem 1.5rem !important;
  }

  .profile-image-crop-container {
    width: 100%;
    height: 100%;
    max-width: 25.125rem;
    max-height: 25.125rem;

    margin-bottom: 1.5rem;

    .cropper-container {
      margin: 0 auto;
    }

    .cropper-modal {
      background-color: $black;
    }

    .cropper-view-box,
    .cropper-face {
      border-radius: 50%;
    }

    .cropper-view-box {
      outline: 0;
    }

    .cropper-face {
      background-color: inherit !important;
    }
    .cropper-line {
      background-color: $white;
      opacity: 1;

      &.line-e {
        width: 0.1875rem;
      }
      &.line-s {
        height: 0.1875rem;
      }
      &.line-w {
        width: 0.1875rem;
      }
      &.line-n {
        height: 0.1875rem;
      }
    }

    .cropper-point {
      background: transparent;

      &.point-ne {
        width: 1.25rem;
        height: 1.25rem;

        right: -6px;
        top: -6px;

        border-top: 0.375rem solid $white;
        border-right: 0.375rem solid $white;
      }
      &.point-nw {
        width: 1.25rem;
        height: 1.25rem;

        left: -6px;
        top: -6px;

        border-top: 0.375rem solid $white;
        border-left: 0.375rem solid $white;
      }
      &.point-sw {
        width: 1.25rem;
        height: 1.25rem;

        bottom: -6px;
        left: -6px;

        border-bottom: 0.375rem solid $white;
        border-left: 0.375rem solid $white;
      }

      &.point-se {
        width: 1.25rem;
        height: 1.25rem;

        bottom: -6px;
        right: -6px;

        border-bottom: 0.375rem solid $white;
        border-right: 0.375rem solid $white;
      }
    }

    .cropper-center {
      display: none;
    }

    .cropper-crop-box {
      background: rgba(255, 255, 255, 0);
    }
  }
}

.going-back-to-email-accounts-confirmation-modal {
  .modal-dialog {
    max-width: 28.125rem !important;
  }
  .shd-modal--header {
    padding-bottom: 1rem !important;
    .shd-modal--title {
      line-height: 1.5rem !important;
    }
  }
  .shd-modal--body {
    padding-bottom: 1.5rem !important;
    p {
      margin: 0;
    }

    .warning-note {
      color: $blue-15;
      font-size: 14px;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: 20px;
    }

    .confirmation-text {
      margin-top: 16px;
      color: $blue-15;
      font-size: 14px;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: 20px;
    }
  }
  .shd-modal--footer {
    padding: 1.25rem 1.5rem !important;
  }
}

.shd-modal.billing-summary-confirmation-modal .modal-content {
  border: none !important;
}

.billing-summary-confirmation-modal {
  .modal-dialog {
    max-width: 25.5625rem !important;
  }
  .shd-modal--header {
    padding: 1.25rem 1.5rem !important;
    .shd-modal--title {
      line-height: 1.5rem !important;
    }
  }
  .shd-modal--body {
    padding-bottom: 1.5rem !important;

    .billing-summary {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      p {
        margin-bottom: 0 !important;
      }

      .pricing {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;

        .price-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;
          align-self: stretch;
          margin-top: 24px;

          .price-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;

            .price-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;

              .label {
                color: #4b5563;

                font-size: 12px;
                font-weight: 400;
                line-height: normal;
              }

              .value {
                color: $blue-15;
                text-align: right;

                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
              }
            }
          }

          .price-details-divider {
            width: 100%;
            height: 0.125rem;
            background: #e5e7eb;
            display: flex;
            align-self: center;
          }
        }

        .total-section {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          align-self: stretch;

          .total-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .total-label {
              display: flex;
              height: 20px;
              padding: 2px 89px 3px 0px;
              align-items: center;
              color: $blue-15;

              font-size: 12px;
              font-weight: 600;
              line-height: normal;
            }

            .total-value {
              color: $blue-15;
              text-align: right;

              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
            }
          }

          .policy-text {
            width: 100%;
            color: $gray-15;
            text-align: center;

            font-size: 0.625rem;
            font-weight: $font-weight-normal;
            line-height: 0.875rem;

            .refund-policy-link {
              color: $blue-11;
              font-size: 0.625rem;
              font-style: normal;
              font-weight: $font-weight-normal;
              line-height: 0.875rem;
              text-decoration-line: underline;
              text-decoration-style: solid;
              text-decoration-skip-ink: none;
              text-decoration-thickness: auto;
              text-underline-offset: auto;
              text-underline-position: from-font;
            }
          }
        }
      }

      .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        gap: 12px;

        .footer-divider {
          width: 100%;
          height: 0.0625rem;
          background: #e5e7eb;
          display: flex;
          align-self: center;
        }

        .policy-list {
          margin: 0;
          padding-left: 24px;
          color: $gray-15;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }

        .pay-now {
          background-color: #1e40af !important;
          margin-top: 12px;
          width: 100%;
        }
      }
    }
  }

  .shd-modal--divider {
    display: none !important;
  }
}

.country-filter .transfer-container,
.state-filter .transfer-container,
.city-filter .transfer-container {
  min-width: 23.25rem !important;
}

.suggested-domains-emails-empty-list {
  margin: 24px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 4px;
  border: none;

  .empty-list {
    display: flex;
    min-width: 396px;
    height: auto;

    flex-direction: column;
    align-items: center;
    background-color: transparent;

    .empty-list-header {
      width: 382px;
      margin-top: 24px;
      margin-bottom: 16px;
      color: $blue-15;
      text-align: center;

      font-size: 18px;
      font-style: normal;
      font-weight: $font-weight-semibold;
      line-height: 20px;
      text-wrap: nowrap;
    }

    .empty-list-note {
      width: 396px;
      color: $gray-15;
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: 20px;
    }
  }
}

.searching-domains {
  .suggested-domains-emails-container {
    margin-bottom: 0;
  }
  .searching-domains-text {
    margin: 0 auto;
    margin-top: 1rem;

    width: max-content;

    color: #1f2937;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    text-wrap: nowrap;
  }
}
