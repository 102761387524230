.total-credits {
  display: flex;
  justify-content: right;
  align-items: center;
  background: white;
  padding-right: 1.738rem;

  .credit-number {
    color: $blue-15;
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    border: 1px solid $gray-23;
    min-width: 6.75rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .diamond-icon {
      height: 1.125rem;
      width: 1.125rem;
      color: $blue-11;
      margin-right: 0.5rem;
    }

    p {
      color: $blue-15;
      margin: 0px;
    }
  }
}

.leads-filters {
  border-bottom: 1px solid $gray-13;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 3;

  .lead-button-container {
    margin-left: 2rem;
  }

  .btn-leads-user-details {
    height: 1.75rem;
    padding: 0.375rem 0.75rem;
    &.export-button {
      height: 1.938rem;
    }

    .mail-icon {
      height: 1.25rem;
      width: 1rem;
    }

    .mail-phone-icon {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .gap-2 {
    gap: 0.5rem;
  }

  .checkbox-lable {
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    color: $black;
  }
}

.select-checkbox-lead {
  margin-top: 0.0625rem;
}

// checkbox tooltip
.checkbox-tooltip-selected {
  .tooltip-inner {
    max-width: 15.313rem;
  }
}

.checkbox-custome {
  .bs-checkbox-inner {
    border-radius: 0.25rem !important;
  }
}

.bulk-select {
  margin-left: 0.5rem;
}

.divider-vertical {
  height: 1.25rem !important;
  width: 0.063rem !important;
  background-color: $gray-13;
  margin: -0.25rem 0.75rem;
  display: inline-block;
}

.bulk-select-tag-modal {
  .modal-dialog {
    width: 34.5rem !important;
  }
  .modal-content {
    .modal-header {
      min-height: auto !important;

      .confirmation-modal-v3--title {
        h2 {
          color: $gray-10 !important;
          line-height: $heading-2 !important;
        }
      }
    }
    .modal-body {
      .bulk-select-tag-modal-text {
        font-size: $text-14;
        line-height: $heading-2;
        font-weight: $font-weight-normal;
        color: $blue-15;
      }

      .tags-autosuggest-container {
        & > div:first-child {
          margin-top: 24px !important;

          .bs-input {
            height: 2.5rem;
            border: 0.0625rem solid #d1d5db;
            background: #f9fafb;
            input {
              background: #f9fafb;
            }
          }

          #react-autowhatever-1 {
            margin-top: 0.25rem !important;
          }
        }

        .tags-container.tag-container-style {
          height: auto !important;
        }
      }
    }
  }
}
