.confirmation-modal {
  padding-top: 0.75rem !important;
  padding-right: 1rem !important;
  .close-button {
    height: 1.25rem;
  }
  i {
    font-size: 1.25rem;
    color: '#1F2937';
  }
  .contents {
    margin-top: 1.875rem;
    margin-bottom: 1.25rem;
  }
  .footer-border {
    padding-top: 1rem;
    border-top: 1px solid #f3f4f6;
    border-radius: 4px;
  }
}

// New Confirmation Modal Styling ------

.confirmation-modal-v3 {
  .modal-dialog {
    width: 28.125rem !important;
    .modal-header {
      border-bottom: none;
      padding: 0px 1.5rem;
      position: relative;

      .confirmation-modal-v3--title {
        gap: 10px;
        h2 {
          line-height: 1.5rem;
          margin-left: 0px;
        }
      }

      button.close {
        padding: 0;
        position: absolute;
        top: 0.9375rem;
        right: 1.875rem;
      }
    }
    .modal-content {
      .bs-modal-body {
        padding: 1.5rem !important;
      }
    }
    .modal-footer {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 0px;
      padding-right: 0px;
      margin: 0 24px;
      min-height: 4.5rem;
      max-height: 4.5rem;
      .bs-modal-footer-action-buttons {
        margin: 0;
        button {
          height: 2rem;
          margin: 0 !important;
          line-height: $heading-2;
          &:last-of-type {
            margin-left: 1rem !important;
          }
        }
        .btn-ghost-outlined{
          color: $gray-15;
        }
      }
    }
  }

  &--title {
    padding-top: 1.5rem;
    img,
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-top: 0.1875rem;
    }
    h2 {
      font-weight: $font-weight-semibold;
      font-size: 1rem;
      color: $blue-15;
      margin: 0;
      margin-left: 1rem;
    }
  }
  &--body {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $blue-15;

    &__contents {
      p {
        margin-bottom: 1rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    &__content {
      margin-bottom: 0;
    }
  }
}
