.admin-setting-section {
  padding: 1.5rem;
  background-color: white;
  margin-bottom: 1rem;
  border: 0.0625rem;
  border-color: #E8EFF5;
  border-radius: 0.25rem;

  &--title {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $blue-15;
    margin-bottom: 1rem;
  }

  .sso-toggle-container{
    border-bottom: 1px solid $gray-13;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .sso-toggle {
      display: flex;
      justify-content: space-between;
  
      .sso-toggle-label {
        color: $blue-15;
  
        .sso-toggle-label-disable {
          opacity: 0.35 !important;
        }
  
        a {
          text-decoration: none;
          margin-left: 0.5rem;
          color: $blue-33;
          animation-timing-function: ease-out;
          animation-duration: 300ms;

        }
      }
  
      .sso-setup-link{
        align-self: flex-start;
        display: flex;
        align-items: center;
        gap: 0.25rem;
  
        font-size: $text-14;
        line-height: $heading-2;
        font-weight: $font-weight-medium;
        color: $gray-15;
      }
  
      .sso-setup-link:hover{
        color: $blue-11;
        cursor: pointer;
      }
    }

    .sso-domains-list {
      display: flex;
      justify-content: flex-start !important;
      gap: 0.5rem;
      overflow-x: auto;

      .shd-pills {
        height: auto;
        background-color: $gray-16;
        padding: 2px 8px;
  
        .pills-label{
          background-color: $gray-16;
          color: $blue-15;
          font-size: $text-14;
          line-height: $heading-2;
          font-weight: $font-weight-normal;
        }
      }
    }

    .sso-domains-list::-webkit-scrollbar {
      height: 10px;
    }

    .sso-domains-list::-webkit-scrollbar-track {
      background: #FFFFFF;
    }

    .sso-domains-list::-webkit-scrollbar-thumb {
      border-top: 5px solid #FFFFFF;
      border-right: 5px solid #FFFFFF;
      border-left: 5px solid #FFFFFF;
      border-radius: 20px;
    }

    .sso-status {
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
      gap: 0.5rem;
      line-height: $text-18;
    }

    .sso-actions {
      justify-content: flex-start !important;
      gap: 1rem;
      color: $gray-15;

      .sso-actions--refresh-disabled {
        opacity: 0.35 !important;
      }
      .sso-actions--refresh-disabled:hover {
        cursor: not-allowed;
      }
    }

    .table-cell {
      min-width: auto !important;
      line-height: $text-18 !important;
    }

    .table-responsive::-webkit-scrollbar {
      height: 23px;
    }

    .table-responsive::-webkit-scrollbar-track {
      background: #FFFFFF;
    }

    .table-responsive::-webkit-scrollbar-thumb {
      border: 8px solid #FFFFFF;
      border-radius: 30px;
    }
  }

  .admin-setting-toggle--label{
    .mfa-toggle-label-disable {
      color: $blue-15;
      opacity: 0.35 !important;
    }
  }

}

.confirmation-modal-v3 {
  .sso-setup-form-container {
    form {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      grid-gap: 1rem;
      gap: 1rem;
      width: 100%;

      .admin-setting-input {
        margin-bottom: 0rem;
        
        .bs-input-affix-wrapper{
          &.input-error{
            border: 1px solid $red-20;
          }
        }

        .bs-input-label-suffix{
          color: $red-12;
          font-size: $text-12;
          line-height: $text-18;
          font-weight: $font-weight-normal;
        }

        .bs-input-label-prefix{
          font-weight: $font-weight-semibold;
          font-size: $text-12;
          line-height: $text-16;
          letter-spacing: 0;
          color: $gray-15;
          margin-bottom: 0.25rem;
        }

        input {
          font-size: $text-14;
          line-height: $heading-2;
          font-weight: $font-weight-normal;
          color: $blue-15;
        }

        input::placeholder {
          color: $gray-11;
        }

      }
      .admin-setting-sso-domain--input {
        width: 100%;
        margin-bottom: 0rem;
        .admin-setting-sso-input--label-wrapper {
          display: flex;
          gap: 0.25rem;

          .admin-setting-sso-input--label {
            font-weight: $font-weight-semibold;
            font-size: $text-12;
            line-height: $text-16;
            letter-spacing: 0;
            color: $gray-15;
            margin-bottom: 0.25rem;
          }

        }
        .emails-and-domains-input--container{
          min-height: 2rem;
          height: auto !important;
          grid-gap: unset;
          gap: unset;
          padding: 6px 8px;
          border: 1px solid $gray-5;

          input {
            font-size: $text-14;
            line-height: $heading-2;
            font-weight: $font-weight-normal;
            color: $blue-15;
          }

          input::placeholder {
            color: $gray-11;
          }
          &:placeholder-shown {
            text-overflow: ellipsis;
          }
          &:hover {
            border-color: $blue-5;
            border-right-width: 1px !important;
          }
          &:focus-within {
            border-color: $blue-5;
            border-right-width: 1px !important;
            outline: 0;
            box-shadow: $input-shadow;
          }

          .emails-and-domains-input--pills{
            .pills-label{
              font-size: $text-14;
              line-height: $heading-2;
              font-weight: $font-weight-normal;
              color: $blue-15;
            }
          }
          &.error{
            border: 1px solid $red-20;
          }
          &.error:focus-within {
            border: 1px solid $red-20;
            box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.5);
          }
        }
      }
    }
  }
  .sso-setup-link-container{
    .sso-setup-link-container--text{
      font-size: $text-14;
      line-height: $heading-2;
      font-weight: $font-weight-normal;
      color: $blue-15;
      margin-bottom: 1rem;
    }
    .copy-content{
      background-color: $gray-19;
      padding: 6px 16px;
      border: 0.0625rem solid $gray-13;
      border-radius: 0.25rem;
      word-wrap: break-word;
      span{
        font-family: $font-family-sans-serif;
        font-size: $text-14;
        line-height: $heading-2;
        color: $gray-12;
        cursor: pointer;
      }
    }
  }

  &.sso-setup-link-modal{
    .modal-dialog{
      width: 30.1875rem !important; 
    }
  }
}