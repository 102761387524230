.plan-cycle-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 6px;

  position: relative;

  border-radius: 30px;
  border: 0.5px solid #e5e7eb;
  background: #f3f4f6;

  &--option {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    height: 2.125rem;

    z-index: 1;

    padding: 8px 12px;

    &.monthly {
      width: 8.625rem;
    }

    &.annually {
      width: 10.0625rem;
    }

    span {
      color: #6b7280;
      font-size: $text-14;
      font-style: normal;
      font-weight: 600;
      line-height: $text-18;
    }

    &.selected {
      span {
        color: $blue-11;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }

    .discount-percentage {
      background: $green-15;
      padding: 3px 5px;
      border-radius: 15px;
      color: $green-14 !important;
      font-size: $text-12;
      line-height: $text-12;
      font-weight: $font-weight-medium;
      max-height: 18px;
    }
  }

  .selected-option-bg {
    height: 2.125rem;
    position: absolute;

    transition: all 0.25s ease-out;

    border-radius: 25px;
    background: #fff;
    box-shadow: 0px 2px 6px 0px #00000014;

    &.monthly {
      width: 8.625rem;
      transform: translateX(0%);
    }
    &.annually {
      width: 10.0625rem;
      transform: translateX(8.625rem);
    }
  }
}
