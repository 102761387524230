@import './components/searching-empty/searching-empty';
@import './components/leads-filter/leads-filter';
@import './components/people-tab/people-tab';
@import './components/search-auto-complete/search-auto-complete';
@import './components/recent-search/recent-search';
@import './components/total-credits/total-credits';
@import './components/individual-leads/individual-leads';
@import './components//view-more/view-more';
@import './components/add-remove-tag-button/add-remove-tag-button';

.leads-container {
  .leads-content {
    overflow: hidden;
    flex-wrap: nowrap;
    position: relative;

    .lead-listing-container {
      height: calc(100vh - 8.625rem);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.3125rem;
      }
    }

    .leads-navigation {
      min-height: calc(100vh - 5.625rem);
      padding: 0.063rem 0;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      background: $white-text;
      z-index: 5;
      border-right: 1px solid $gray-13;
      width: 17rem;

      &::-webkit-scrollbar {
        height: 5px !important;
        width: 5px !important;
      }

      &.leadsSideNav {
        transition: all 0.2s ease-in-out;

        &.sidenav--collapse {
          width: 1.25rem;

          .expand-btn {
            left: 0.5rem;
          }
        }

        .expand-btn {
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $gray-15;
          border-radius: 50%;
          background-color: $gray-16;
          cursor: pointer;
          position: absolute;
          top: 0.6875rem;
          left: 15.75rem;
          z-index: 5;

          &:hover {
            background-color: $gray-14;
          }
        }
      }
    }
  }

  .collpase-leads-component {
    width: calc(100% - 1.5rem);
    overflow-y: auto;
  }

  .leads-components {
    width: calc(100% - 14.5rem);
    overflow-y: auto;

    .pagination {
      justify-content: end;
      margin: 8px 24px 8px 0;
    }
  }
}

// TODO Leads Header Skeleton
.leads-header-skeleton {
  border-bottom: 1px solid $gray-13;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  padding-top: 0.438rem;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 4;

  .add-prospect-btn {
    margin-left: 2rem;
  }

  .credits-btn-skeleton {
    span {
      display: flex;
    }
  }
}

.get-contact-modal-tag {
  margin-bottom: 1rem;
}
.lead-tags-btn {
  width: 100% !important;
}

.date-saved-filter {
  position: absolute;
}

.pill-saved-filter {
  margin: 0.25rem;
  margin-left: 0;
}

.pill {
  display: flex;
  flex-wrap: wrap;
}

.select-saved-tag {
  margin-bottom: 0.5rem;
  color: $gray-11;
}

.lead-filter-dropdown {
  &.transfer-overlay {
    padding: 0.125rem 0 0 0;
  }
  .transfer-container {
    padding: 0;
    border: 0.0625rem solid $gray-13;
    box-shadow: 0.5rem 1rem 3.5rem -1.5rem rgba(156, 157, 161, 0.24),
      0.125rem 0.125rem 1rem -0.1875rem rgba(4, 21, 76, 0.16);

    .transfer-body {
      .transfer-search {
        height: 2rem;
        padding: 0.375rem 1rem;
        border: 0.0625rem solid $gray-16;
      }

      .transfer-list {
        li {
          height: 2rem;
          padding: 0.375rem 1rem !important;

          line-height: 20px !important;

          &:hover,
          &.active {
            background: $blue-17;
          }

          .option-content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .transfer-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 3rem;
        border: 0.0625rem solid $gray-13;

        .bs-checkbox-wrapper {
          color: $blue-15;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  &.lead-filter {
    .transfer-container {
      width: 15rem !important;
      .transfer-body {
        padding-bottom: 0;
      }
    }
  }
}

.pills-container {
  display: flex;
  height: 1.875rem;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.25rem;
  background: #f4f3f6;
  border-color: $gray-23;
}
//  TODO Individual Leads Skeleton
.individual-leads-skeleton {
  padding: 28px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray-13;

  .span-space-remove {
    span {
      &:first-child {
        display: flex;
        line-height: normal;
      }
    }
  }

  .company-details {
    width: 30%;
    padding-left: 4.375rem;
  }

  .inform-details-container {
    position: relative;
    bottom: 0.75rem;

    span {
      &:first-child {
        line-height: 0;
        display: flex;
      }

      .inform-details {
        margin: 0.375rem 0;
      }
    }

    .email-phone {
      span {
        &:first-child {
          line-height: unset;
        }
      }
    }
  }

  .w-25 {
    width: 25%;
  }

  .user-avatar {
    margin: 0 0.75rem;
  }

  .mt-6 {
    margin-top: 0.375rem;
  }
}

// Alert Message
.alert-message {
  .leads-content {
    .lead-listing-container {
      height: calc(100vh - 11.688rem);
    }

    .leads-navigation {
      .people-tab-accordian {
        max-height: calc(100vh - 19rem) !important;
      }
    }
  }
}

// Less Than Data
.lead-listing-container-less {
  height: calc(100vh - 6.875rem) !important;
}

// Alert Less Than Data
.alert-message {
  .lead-listing-container-less {
    height: calc(100vh - 9.188rem) !important;
  }
}

.lead-filter-date-piker {
  .prospect-filter-date-piker {
    .react-datepicker-wrapper {
      .bs-input {
        padding: 0 !important;
        background-color: #fff !important;
        color: $blue-15;
        font-family: 'Inter', sans-serif;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .input::placeholder {
        color: $gray-11;
        font-weight: 400;
        padding-left: 0.75rem;
        margin-left: 0.5rem !important;
      }
    }
  }
}

.checkbox-skeleton {
  margin-top: 0.4375rem;
}

.lead-finder-pagination {
  .pagination-container {
    padding: 0.5rem 1.5rem !important;
    background: $white !important;
  }
}

.valid-email {
  color: $green-14 !important;
}
.risky-email {
  color: $orange-6 !important;
}
.unverified-email {
  color: $gray-15 !important;
}


.lead-company-domain {
  &:hover {
    .icon {
      .icon-color {
        color: $blue-11 !important;
      }
    }
    .icon-color {
      color: $blue-11 !important;
    }
    .user-email {
      color: $blue-11 !important;
    }
    .m-details {
      color: $blue-11 !important;
    }
    text-decoration: none !important;
  }
}