.email-accounts-states-container {
  width: 414px;
  height: max-content;
  max-height: 276px;
  max-width: 414px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
  position: relative;
  background-color: $white;
  border: 1px solid $gray-16;
  border-radius: 8px;
  box-shadow: 3px 8px 24px -6px #0f224329;
  cursor: default;
  margin-top: 0.625rem;

  & .email-skeleton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 7px;
  }
  & .no-email-account-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
    & .no-email-account-found {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 104px;
      width: 108px;
      &.no-email-account-found span {
        white-space: nowrap;
        color: $blue-15;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: $font-weight-semibold;
        line-height: 24px;
      }
    }
    &.email-accounts-states-container {
      height: 298px;
    }
  }

  & .email-accounts-list-header {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0;
    align-items: center;
    background-color: $white;
    z-index: 1;
    & .email-account-column {
      max-width: 262px;
      flex: 1;
      max-height: 36px;
      display: flex;
      align-items: center;
      padding: 10px 16px;
      border-bottom: 1px solid $gray-16;
      border-right: 1px solid $gray-16;
      & span {
        font-size: 12px;
        font-weight: $font-weight-bold;
        line-height: 16px;
        color: $gray-15;
        letter-spacing: 0px;
        display: inline-flex;
        align-items: center;
      }
    }
    & .sending-limit-column {
      max-width: 152px;
      display: flex;
      max-height: 36px;
      align-items: center;
      flex: 1;
      padding: 10px 16px;
      border-bottom: 1px solid $gray-16;

      & span {
        font-size: 12px;
        font-weight: $font-weight-bold;
        line-height: 16px;
        color: $gray-15;
        letter-spacing: 0px;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  & .email-list-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0;
    align-items: center;

    & .email-account-info {
      max-width: 262px;
      height: 40px;
      flex: 1;
      display: flex;
      align-items: center;
      padding: 10px 16px;
      border-bottom: 1px solid $gray-16;
      border-right: 1px solid $gray-16;
      gap: 8px;
      overflow: hidden;

      & .email-text-section {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 18px;
        gap: 8px;
        flex: 1;
        align-items: center;
        overflow: hidden;
        & .email-text {
          color: $gray-12;
          cursor: pointer;
          font-weight: $font-weight-medium;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        & .sequence-status {
          margin: 0;
          flex: 1;
          justify-content: flex-start;
          & .sequence-status-badge {
            width: 18px;
            height: 18px;
            & sup {
              width: 6px;
              height: 6px;
              transform: translate(50%, -70%);
            }
          }
        }
      }
    }
    & .sending-limit-info {
      max-width: 152px;
      display: flex;
      height: 40px;
      align-items: center;
      flex: 1;
      padding: 10px 16px;
      border-bottom: 1px solid $gray-16;
      justify-content: space-between;
      & .email-acc-progress-bar {
        width: 64px;
        display: flex;
        align-items: center;
        & .progress-bar-wrapper {
          width: 100%;
          height: 6px;
          border-radius: 8px;
          background-color: $blue-13;
          &__bar {
            border-radius: 8px;
            background-color: $blue-24 !important;
          }
        }
      }
      & .limit-text {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 0px;
        max-height: 18px;
        & .current-limit {
          font-weight: $font-weight-medium;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0%;
        }
        & .max-limit {
          font-weight: $font-weight-medium;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0%;
          color: $gray-11;
        }
      }
    }
  }
}
