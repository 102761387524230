.sequence-container {
  // temporary style to make stats disabled.
  .state-row {
    user-select: none;
  }

  .unsubscribe-error-text {
    color: $red-12;
    font-size: 12px;
    font-weight: 400;
  }

  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-grow: 1;

  .sequence-header {
    color: $gray-10;
    padding: 0.5rem 2.5rem;
    box-shadow: 10px 0 30px rgba(220, 224, 230, 0.51);
    height: 4.5rem;
    position: relative;
    z-index: 1;
    background: $gray-1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin: 0;
    }

    .steps-top-action {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      margin-left: 3rem;
      .switchToggle {
        label {
          margin: 0 1.5rem 0 0;
          top: 3px;
          span {
            font-weight: $font-weight-semibold;
          }
        }
      }
      .dropdown-item-custom {
        i {
          font-size: 1.125rem;
          top: 2px;
          margin-right: 0.5rem;
          color: $blue-6;
        }
      }
      a {
        span {
          color: $gray-8;
        }
        &:hover {
          text-decoration: none;
          span {
            color: $gray-10;
          }
        }
      }
    }
  }
  .sequence-components {
    width: 100%;
    height: 100%;

    .sequence-list-container {
      height: 100%;
      background-color: $gray-19;
      display: flex;
      flex-direction: column;

      .onboarding-progress {
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-top: 1.375rem;

        .on-progress-bar {
          .progress-bar-wrapper {
            height: 0.375rem;
            border-radius: 0.5rem;
            background-color: $blue-13;
          }
        }

        &__items-secondary {
          height: 17.5rem;
          .steps {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
        &__items {
          border-radius: 0.25rem;
          border: 1px solid $gray-13;
          .steps {
            width: 43.99%;
            background-color: $gray-19;
            border-right: 1px solid $gray-13;
            &__container {
              width: 100%;
              padding: 1.5rem;
              display: flex;
              flex-direction: column;
              gap: 0.625rem;
              .completed-step {
                width: 1rem;
                height: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            &__item {
              padding: 0.75rem 1rem;
              border-radius: 0.25rem;
              border: 1px solid $gray-13;

              &-completed {
                border: 1px solid $gray-13;
              }
              &-active {
                border-color: $blue-11;
                box-shadow: 0px 2px 8px 0px rgba(46, 87, 219, 0.3);
              }
              &:hover {
                background-color: $blue-17;
              }
            }
          }
          .right-container {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            width: calc(100% - 43.99%);
            .video-container {
              display: flex;
              gap: 1.5rem;
              .left-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              .right-section {
                width: 21.25rem;
                height: 12.5rem;
                border-radius: 1rem;
                .img-container {
                  // border-radius: 1rem;
                }
                img {
                  width: 21.25rem;
                  height: 12.5rem;
                  // object-fit: contain;
                  border-radius: 1rem;
                }
                .play-icon {
                  display: flex;
                  width: 3rem;
                  height: 3rem;
                  padding: 0.75rem;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 4.6875rem;
                  background-color: $gray-1;
                  box-shadow: 0px 0px 25px 0px rgba(0, 32, 123, 0.8);
                }
              }
            }
          }
        }
      }
      .sequence-filter-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 1.5rem 1.5rem 1rem 1.5rem;

        .sequence-filter-bar-left {
          display: flex;
          align-items: center;
          gap: 1.25rem;

          .sequence-search-input-wrap {
            width: 12.5rem;

            .bs-input-affix-wrapper {
              border: 1px solid $gray-23;

              .input-icons-left {
                margin: 0 !important;
              }

              .bs-input-prefix,
              .bs-input-suffix {
                color: $gray-11;
                font-size: 1rem;
                margin: 0;

                display: flex;
                justify-content: center;
                align-items: center;

                i {
                  margin-top: 1px;
                }
              }

              input {
                margin-left: 0.5rem;
                color: $blue-15;

                &::placeholder {
                  font-weight: $font-weight-normal;
                  font-size: 0.875rem;
                  line-height: 1.25rem;
                  color: $gray-11;
                }
              }

              &:hover,
              &:focus,
              &:focus-within {
                border-color: #1d4ed8;
                box-shadow: none;
              }
            }
          }
          .sequence-checkbox {
            margin-right: 1.5rem;
            padding-left: 0.5rem;
          }

          .btn-owner-select {
            min-width: 8.75rem;
            height: 2rem;
            background-color: $white;
            padding: 0.375rem 1rem;
            border: 0.0625rem solid $gray-23;
            border-radius: 0.25rem;

            font-weight: $font-weight-normal;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: $blue-15;

            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover,
            &:focus,
            &:focus-within {
              border-color: $blue-11;
              box-shadow: none;
            }

            .icon-right {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .bs-select-box {
            width: 160px;
            height: 2rem;
            border: 0.0625rem solid $gray-23;
            border-radius: 0.25rem;
            padding: 0.375rem 1rem;

            font-weight: $font-weight-normal;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: $blue-15;

            &:hover,
            &:focus,
            &:focus-within {
              border-color: $blue-11;
              box-shadow: none;
            }
          }

          .rotate-90 {
            transform: rotate(90deg);
            display: inline-block;
          }
          .dropdown-custom {
            background: $gray-4;
            border-radius: 4px;
            margin-left: 1rem;
            .btn-solid-dropdown-custom {
              @media only screen and (min-width: $screen-768) and (max-width: $screen-1190) {
                padding: 0.314rem 0.5rem;
              }
              span {
                @media only screen and (min-width: $screen-768) and (max-width: $screen-1190) {
                  display: none;
                }
              }
              i {
                font-size: $text-16;
              }
            }
            &:hover {
              background: $gray-5;
            }
            &:focus {
              outline: 0;
            }
            .dropdown-menu-custom {
              &.sorting-dropdown {
                .dropdown-item-custom {
                  justify-content: space-between;
                }
              }
            }
          }
          .dropdown-progress-custom-btn {
            background: $gray-1;
            border: 1px solid $blue-14;
            &:hover {
              background: $gray-1;
            }
            .dropdown-progress-item {
              &:hover {
                background: $blue-17;
                color: $gray-24;
              }
            }
            .selected-status-icon {
              margin-top: 4px;
            }
          }

          .action-delete {
            margin-left: 2rem;
            color: $red-5;
            display: flex;
            align-items: center;
            padding: 0.375rem 0;
            @media only screen and (min-width: $screen-768) and (max-width: $screen-1190) {
              margin-left: 0.625rem;
            }
            i {
              margin-right: 0.5rem;
              font-size: $text-16;
              color: $red-3;
            }
            &:hover {
              text-decoration: none;
              i {
                color: $red-5;
              }
            }
          }
          .sequence-action-btn-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            margin-left: -0.3125rem;

            .sequence-action-btn {
              width: 2.125rem;
              height: 2rem;
              cursor: pointer;
              border-radius: 0.25rem;
              transition: all 0.2s ease;
              display: flex;
              justify-content: center;
              align-items: center;
              i {
                font-size: 1.25rem;
                color: $gray-15;
                opacity: 1;
              }
              &:hover {
                background: $blue-13;
                i {
                  color: $blue-11;
                }
              }
            }

            &.disabled {
              .sequence-action-btn {
                cursor: not-allowed;
                i {
                  opacity: 0.35;
                }
                &:hover {
                  background: transparent;
                  i {
                    color: $gray-15;
                  }
                }
              }
            }
          }
        }
        .sequence-filter-bar-right {
          margin-right: -3.6rem;
        }
        .sequence-search {
          margin-left: 0.6rem;
          .bs-input-affix-wrapper {
            @media only screen and (min-width: $screen-768) and (max-width: $screen-1190) {
              width: 11.25rem;
            }
          }
        }
      }
      .sequence-selection-info {
        &--wrapper {
          width: 100%;

          display: flex;
          justify-content: center !important;
          align-items: center;
          gap: 0.75rem;

          p {
            margin: 0;
            color: $blue-15;
            font-size: 0.75rem;
            font-weight: $font-weight-semibold;
            line-height: 1.375rem;
          }
        }
        &--separator {
          width: 0.0625rem;
          height: 1.25rem;
          background: $gray-11;
        }
        &--cta {
          color: $blue-11;
          font-size: 0.75rem;
          font-weight: $font-weight-semibold;
          line-height: 1.375rem;
          cursor: pointer;
        }
      }
      .sequence-list {
        padding: 0 1.5rem;
        flex-grow: 1;
        height: 100%;
        overflow-y: hidden;

        .bs-table-tbody {
          .align-avatar-left {
            display: flex;
            justify-content: flex-end;
          }
        }
        .table-list {
          height: 95%;
          .react-bootstrap-table {
            .table-organism {
              .sequence-table-header {
                height: 2.75rem;
                background: $white;
                box-shadow: none;
                z-index: 15;
              }
              tr:hover {
                background-color: $white !important;
              }
              .table-organism-header > tr > th {
                height: 1.25rem;
                max-height: 1.25rem;
                border-color: $gray-16;
                border-right: 1px solid $gray-16;
                padding: 0.5rem 1rem;
                vertical-align: middle;
                &:first-of-type {
                  border-right: none !important;
                  position: sticky;
                  vertical-align: middle;
                  left: 0;
                  top: 0;
                  z-index: 4;
                  background-color: $white;
                  padding: 0.5rem 0.5rem 0.5rem 1rem;
                  &.client-sequence-name-header {
                    border-right: 1px solid $gray-16 !important;
                    box-shadow: 8.056px 0 15px -5px rgba(226, 230, 245, 0.7);
                  }
                  &:hover {
                    background-color: $white;
                  }
                  .bs-checkbox-wrapper {
                    .bs-checkbox-inner {
                      border-radius: 0.25rem;
                      border: 0.0625rem solid $gray-11;
                    }
                  }
                }
                &:last-of-type {
                  position: sticky;
                  right: 0;
                  z-index: 3;
                  top: 0;
                  padding: 0 !important;
                  &.created-by-header {
                    position: relative;
                    padding: 0.5rem 1rem !important;
                  }
                  &:hover {
                    background-color: $white;
                  }
                }
                &:nth-of-type(2) {
                  border-right: none !important;
                  position: sticky;
                  left: 41px;
                  z-index: 3;
                  background-color: $white;
                  top: 0;
                  padding: 0.5rem 0.25rem 0.5rem 0.75rem;
                  &.client-sequence-progress-header {
                    background-color: none;
                    border-right: 1px solid $gray-16 !important;
                    &:hover {
                      background-color: $gray-19;
                    }
                  }
                  &:hover {
                    background-color: $white;
                  }
                }
                &:nth-of-type(3) {
                  position: sticky;
                  left: 85px;
                  z-index: 3;
                  background-color: $white;
                  vertical-align: middle;
                  top: 0;
                  box-shadow: 8.056px 0 15px -5px rgba(226, 230, 245, 0.7);
                  &.client-prospect-col-header {
                    box-shadow: none;
                    &:hover {
                      background-color: $gray-19;
                    }
                  }
                  &:hover {
                    background-color: $white;
                  }
                }
                &:nth-of-type(4) {
                  min-width: 11.875rem;
                  &.client-contacted-col-header {
                    min-width: 8.887rem;
                  }
                }
                &:nth-of-type(5) {
                  min-width: 6.125rem;
                }
                &:nth-of-type(6) {
                  min-width: 8.5rem;
                }
                &:nth-of-type(7) {
                  min-width: 9.125rem;
                }
                &:nth-of-type(8) {
                  min-width: 8.25rem;
                }
                &:nth-of-type(9) {
                  min-width: 8rem;
                }
                &:nth-of-type(10) {
                  min-width: 8.25rem;
                }
                &:nth-of-type(11) {
                  min-width: 9.4375rem;
                }
                &:nth-of-type(12) {
                  min-width: 12.1875rem;
                  &:hover {
                    background-color: $white;
                  }
                }
                &:nth-of-type(13) {
                  border-right: none !important;
                  &:hover {
                    background-color: $white;
                  }
                }
                div {
                  display: flex;
                  align-items: center;
                }
                &:hover {
                  background-color: $gray-19;
                  &.created-by-header {
                    &:hover {
                      background-color: transparent;
                    }
                  }
                }
              }
              .table-organism-header div span {
                font-weight: $font-weight-bold;
                color: $gray-15;
                line-height: 1rem;
              }
              .table-organism-body > tr {
                height: 4.5rem;
                > td {
                  height: 3.75rem;
                  max-height: 20px !important;
                  background: $white;
                  z-index: 10;
                  border-color: $gray-16;
                  border-right: 0.0625rem solid $gray-16;
                  padding: 0.5rem 1rem !important;
                  &.selection-cell {
                    padding: 0.5rem 0.5rem 0.5rem 1rem !important;
                  }
                  &.sequence-switch-col {
                    padding: 0.5rem 0.25rem 0.5rem 0.75rem !important;
                  }
                  &:first-of-type {
                    padding-left: 1rem !important;
                    border-left: 0.0625rem solid $gray-16;
                    position: sticky;
                    left: 0;
                    background-color: $white;
                    z-index: 10;
                  }
                  &:last-of-type {
                    position: sticky;
                    right: 0;
                    background: $white;
                    z-index: 1;
                    padding: 0 !important;
                    border-left: 0.0625rem solid $gray-13;
                    border-bottom: 0.0625rem solid $gray-13;
                    border-right: 0.0625rem solid $gray-13;
                    &.sequence-owner {
                      position: relative;
                      padding: 0.5rem 1rem !important;
                    }
                    &.action-btn {
                      z-index: 3;
                    }
                  }
                }
              }
              .table-organism-body > tr:hover {
                box-shadow: none;
                td {
                  background: $gray-19;
                }
              }
            }
          }
        }
        &.sequences-selected {
          .table-list {
            height: 90%;
          }
        }
        &.disabled-select-checkbox {
          .table-list {
            .selection-cell,
            .selection-cell-header {
              .show-disable-style {
                cursor: not-allowed !important;
                .bs-checkbox {
                  cursor: not-allowed !important;
                  .bs-checkbox-inner {
                    cursor: not-allowed !important;
                  }
                  .bs-checkbox-input {
                    cursor: not-allowed !important;
                  }
                }
              }
            }
          }
        }
        .selection-cell {
          border-right: none !important;
          .bs-checkbox-wrapper {
            .bs-checkbox-inner {
              border-radius: 4px;
            }
          }
        }
        .sequence-switch-col {
          width: 1.75rem;
          border-right: none !important;
          position: sticky;
          left: 41px;
          z-index: 1;
          background-color: $white;
          .switchToggle {
            display: flex;
            label {
              background: $gray-13;
              span {
                white-space: nowrap;
                @media only screen and (min-width: $screen-1191) and (max-width: $screen-1281) {
                  max-width: 40ch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                @media only screen and (min-width: $screen-768) and (max-width: $screen-1190) {
                  max-width: 34ch;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
            input:checked + label {
              background: $blue-11;
            }
          }
        }
        .sequence-switch {
          label {
            margin-bottom: 0;
          }
        }
        .sequence-name {
          min-width: 23rem !important;
          position: sticky;
          left: 85px;
          z-index: 1;
          background-color: $white;
          box-shadow: 8.056px 0 15px -5px rgba(226, 230, 245, 0.7);
          @media only screen and (min-width: $screen-2500) {
            width: 40% !important;
          }

          .text-container {
            flex: 3 1;
            min-width: 13.75rem !important;
            font-weight: $font-weight-semibold;
            cursor: pointer;
            width: auto;
            line-height: 1.5rem;
            color: $blue-15;
            text-decoration: none;
            font-size: 1rem;

            &:hover {
              color: $blue-11;
            }

            @media only screen and (min-width: $screen-2500) {
              width: fit-content !important;
            }
          }
        }
        .sequence-status {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0.25rem;

          .sequence-status-badge {
            width: 1.25rem;
            height: 1.25rem;
            .badge-dot-danger,
            .badge-dot-success,
            .badge-dot-secondary {
              transform: translate(50%, -57%);
            }
          }

          .sequence-progress {
            font-weight: $font-weight-medium;
            font-size: 0.75rem;
            line-height: 1rem;
            text-align: center;
            color: $gray-15;
            margin: 0;
          }
        }
        tr {
          &:hover .sequence-steps-created-on {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.2s ease-in-out;
          }
        }
        .sequence-steps-created-on {
          display: flex;
          align-items: center;
          color: $gray-15;
          font-weight: $font-weight-medium;
          font-size: 0.75rem;
          margin-top: 0.25rem;
          gap: 0.5rem;

          opacity: 0;
          visibility: hidden;
          transition: opacity 0.2s ease-in-out;

          .sequence-divider-vertical {
            width: 0.125rem;
            height: 1rem;
            border-radius: 0.0625rem;
            color: $gray-13;
            &.steps-divider {
              margin-left: 0.5rem;
            }
          }
        }
        .sequence-total {
          .sequence-total-text {
            width: 5.260625rem !important;
          }
        }
        .sequence-owner {
          width: 4rem !important;
          border-right: none !important;
        }

        .pagination-container {
          bottom: 0 !important;
          left: 0;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          position: fixed !important;
          height: 2.5rem;
          background: $white;
          width: 100%;
          .pagination-row {
            height: 1.5rem;
            justify-content: flex-end;
            width: 100%;
          }
        }
      }
      .sm-table {
        .table-list {
          // height: calc(100vh - 11rem);
        }
      }
    }
    .create-sequence-container {
      display: flex;
    }

    .empty-block {
      padding: 10% 0 3rem 0;
      margin: auto;
      text-align: center;
      h3 {
        margin-top: 2rem;
      }
      p {
        color: $gray-7;
        margin-bottom: 2rem;
      }
      &.steps-block {
        .dropdown-item-custom {
          i {
            font-size: 1.125rem;
            top: 2px;
            margin-right: 0.5rem;
            color: $blue-6;
          }
        }
        a {
          span {
            color: $gray-8;
          }
          &:hover {
            text-decoration: none;
            span {
              color: $gray-10;
            }
          }
        }
      }
    }
    .single-sequence-tab-container {
      .left-side-tabs {
        .bs-tabs-nav > .bs-tabs-nav-wrap {
          .bs-tabs-nav-list .bs-tabs-tab:nth-last-child(2) {
            margin-left: initial !important;
          }
        }
      }
      .custom-tab-container {
        height: 100%;

        .bs-tabs-nav {
          display: flex;
          align-items: center;
          gap: 12px;

          .bs-tabs-extra-content {
            display: flex;
            gap: 0.5rem;
            flex-shrink: 0;
          }
        }

        &.onboardingCompleted {
          .bs-tabs-nav-list {
            gap: 1.5rem !important;

            .sequence-single-tab--label {
              gap: 0.25rem;
              .label-text {
                font-size: 0.875rem !important;
                font-family: inherit !important;
              }
            }
          }
        }

        .bs-tabs-nav > .bs-tabs-nav-wrap {
          flex-grow: 1 !important;
          display: flex;
          overflow: visible !important;
          z-index: 2;

          .bs-tabs-nav-list {
            width: 100%;
            gap: 2.5rem;
            align-items: center;
          }

          .bs-tabs-nav-list .bs-tabs-tab:nth-last-child(2) {
            margin-left: auto;

            .bs-tabs-tab-btn {
              .sequence-score-header {
                &__disabled {
                  cursor: not-allowed !important;
                  opacity: 0.5;
                }
                &__hover-container {
                  position: relative;
                  padding: 0.188rem 0.5rem;

                  &:hover {
                    background: $gray-16;
                    border-radius: 0.25rem;

                    & .sequence-score__text {
                      color: $gray-15;
                    }
                  }
                }
                &__hover-card {
                  position: absolute;
                  top: 3rem; //prev value: 2.5rem
                  right: -60%; //prev value : -75%
                  display: flex;
                  flex-direction: column;
                  border-radius: 0.5rem;
                  background-color: $gray-1;
                  box-shadow: 0px 2px 6px 0px #04154c1f;
                  padding: 1rem;
                  gap: 1rem;
                  z-index: 10;
                  max-width: 26.25rem;
                  min-width: 26.25rem;
                  width: 26.25rem;

                  .score-items {
                    background-color: $purple-11;
                    border-radius: 0.25rem;
                    padding: 0.75rem;
                    gap: 0.75rem;
                    width: 100%;
                    overflow: hidden;

                    .left-panel {
                      display: flex;
                      flex-direction: column;
                      gap: 0.75rem;

                      .progress-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 0.25rem;
                        width: 9rem;
                        height: 4.25rem;
                        padding: 0.5rem;
                        border-radius: 0.25rem;
                        background-color: $gray-1;
                      }
                    }

                    .right-panel {
                      border-radius: 0.25rem;
                      border: 0.73px solid $gray-32;
                      background-color: $gray-1;
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 1.25rem 0.75rem;
                      gap: 0.5rem;
                      overflow: hidden;

                      .score-apex-chart {
                        width: 100%;
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .popup-score-radical-chart {
                          min-height: 100% !important; // Overriding chart lib's inline style
                          display: flex;
                          align-items: center;
                        }

                        .marker-zero {
                          top: 7.125rem;
                          left: 0rem;
                        }
                        .marker-twenty-five {
                          top: 2rem;
                          left: 0.5rem;
                        }
                        .marker-fifty {
                          top: -0.45rem;
                          left: 5.5rem;
                        }
                        .marker-seventy-five {
                          top: 2.5rem;
                          right: 0.25rem;
                        }
                        .marker-hundred {
                          top: 7.125rem;
                          right: 0rem;
                        }
                      }

                      & .score-block {
                        display: flex;
                        flex-direction: column;
                        gap: 0px;
                        align-items: center;
                        &__text {
                          font-weight: $font-weight-semibold;
                          font-size: 1rem;
                          line-height: 1.25rem;
                          color: $blue-15;
                        }
                        &__value {
                          font-weight: $font-weight-semibold;
                          font-size: 1rem;
                          line-height: 1.25rem;
                          text-transform: capitalize;
                          &.sequence-score-poor {
                            color: $red-12;
                          }
                          &.sequence-score-below-average,
                          &.sequence-score-average {
                            color: $orange-14;
                          }
                          &.sequence-score-good,
                          &.sequence-score-very-good,
                          &.sequence-score-excellent {
                            color: $green-14;
                          }
                        }
                      }
                    }
                  }

                  & .score-items {
                    &.sequence-score-poor {
                      background-color: $red-19;
                    }
                    &.sequence-score-below-average,
                    &.sequence-score-average {
                      background-color: $orange-15;
                    }
                    &.sequence-score-good,
                    &.sequence-score-very-good,
                    &.sequence-score-excellent {
                      background-color: $green-11;
                    }
                  }

                  & .scorebased-content {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    overflow: hidden;

                    &__emoji {
                      font-weight: $font-weight-medium;
                      font-size: 1.25rem;
                      margin-top: 2px;
                    }

                    &__content {
                      color: $blue-15;
                      font-weight: $font-weight-medium;
                      white-space: initial;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .custom-tab-container-xl {
        .bs-tabs-nav > .bs-tabs-nav-wrap {
          .bs-tabs-nav-list .bs-tabs-tab:nth-last-child(2) {
            .bs-tabs-tab-btn {
              .sequence-score-header {
                &__hover-card {
                  right: -10%;
                }
              }
            }
          }
        }
      }

      .sequence-single-header-action {
        display: flex;
        justify-content: flex-end;
        .steps-top-action {
          gap: 1.25rem;
          margin: 0 !important;
          .switchToggle {
            padding: 7px 0;

            input:checked + label {
              background: $blue-11;
            }
          }
          .add-steps {
            &__options {
              position: absolute;
              width: 11.25rem;
              right: 0;
              z-index: 2;
              transform: translateY(-100%);
              top: 13.3125rem;
              border-radius: 4px;
              border: 1px solid $gray-13;
              background: $gray-1;
              /* dropdown  */
              box-shadow: 8px 16px 56px -24px rgba(156, 157, 161, 0.24),
                2px 2px 16px -3px rgba(4, 21, 76, 0.16);
              padding: 0.5rem 0px;
            }
            &__option {
              padding: 0.5625rem 1rem;
              &:hover {
                background-color: $blue-17;
              }
            }
          }
        }
      }

      .bs-tabs-tab-btn {
        padding: 0;

        display: flex;
        align-items: center;
      }

      .bs-tabs {
        .bs-tabs-ink-bar {
          height: 3px;
          border-top-right-radius: 0.25rem;
          border-top-left-radius: 0.25rem;
        }
      }

      // change alignment
      .bs-tabs {
        &.bs-tabs-temp-steps {
          .bs-tabs-ink-bar {
            width: 2.5rem !important;
            left: 1rem !important;
          }
        }
        &.bs-tabs-temp-prospects {
          .bs-tabs-ink-bar {
            width: 4.3125rem !important;
            left: 5.375rem !important;
          }
        }
        &.bs-tabs-temp-emails {
          .bs-tabs-ink-bar {
            width: 2.75rem !important;
            left: 11.5rem !important;
          }
        }
        &.bs-tabs-temp-sequence-score {
          .bs-tabs-ink-bar {
            width: 6.875rem !important;
            left: 16.1875rem !important;
          }
        }
        &.bs-tabs-temp-settings {
          .bs-tabs-ink-bar {
            width: 3.5625rem !important;
            left: 24.9375rem !important;
          }
        }
      }

      height: 100%;
      .bs-tabs {
        height: 100%;
        &-content {
          height: 100%;
        }
        .chevron-right-icon-container {
          position: absolute;
          right: -1.75rem;

          display: flex;

          svg {
            width: 1rem;
            display: block;
            color: #9ca3af;
          }
        }

        .bs-tabs-tab {
          margin: 0;
          padding: 0;
          height: 100%;

          font-size: 0.875rem;
          font-weight: 500;
          color: #6b7280;

          &.bs-tabs-tab-active {
            color: $blue-11;

            .sequence-single-tab--label {
              .tab-incomplete {
                border-color: $blue-11;
              }
            }
          }

          .sequence-single-tab--label {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .tab-complete,
            .tab-incomplete {
              width: 1.125rem;
              height: 1.125rem;
              border-radius: 50%;
              transition: all 0.2s ease;
            }

            .tab-complete {
              background-color: $blue-11;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 0.875rem;
                height: 0.875rem;
                color: $white;
              }
            }
            .tab-incomplete {
              border: 1.5px solid $blue-14;
            }
          }

          .tab-count {
            border-radius: 1.25rem;
            background-color: $gray-16;
            height: 1rem;
            padding: 0px 0.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .bs-tabs-nav {
          margin-bottom: 0%;
          padding: 0rem 1.5rem;
          justify-content: space-between;
          background-color: $gray-1;
          height: 3.5rem;

          border-bottom: 1px solid #eaedef;
          box-shadow: 0px 2px 4px 0px #e5e7eb;

          .bs-tabs-nav-wrap {
            flex: none;
            font-weight: $font-weight-medium;
          }
        }
        .bs-tabs-nav-operations {
          display: none;
        }
        .bs-tabs-content-holder {
          overflow-y: hidden;
        }
        .sequence-setting-container {
          padding: 1.5rem 1.875rem;
          height: 100%;
          overflow-y: auto;
          .sequence-setting-description {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .sequence-setting-title {
            color: #1f2937;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: $font-weight-semibold;
            line-height: 20px; /* 142.857% */
            .setting-icon {
              color: $geekblue-5;
              border: 2px solid $geekblue-5;
              border-radius: $border-radius;
              cursor: default;
            }
            .safety-icon {
              color: $green-7;
              font-size: 1.125rem;
            }
            h3 {
              margin: 0 0 0 0.625rem;
              font-weight: $font-weight-semibold;
            }
          }
          .sequence-setting-verify-status {
            margin-top: 1.625rem;
            &__banner {
              margin-top: 0.5rem;
              width: fit-content;
              span {
                font-size: 0.75rem;
                line-height: 1.125rem;
                margin-left: 0.25rem;
              }
              .banner-message-wrap {
                .anchor-link-text {
                  // color: $orange-12;
                  a {
                    text-decoration: underline;
                    margin-left: 0.15rem;
                  }
                }
              }
            }
            .verify-button {
              margin-left: 1rem;

              .icon-left {
                margin-right: 0.5rem;
                .refresh-svg {
                  animation: spin 2s linear infinite;
                }
              }
            }
            .verify-button-pending {
              opacity: 0.5;
              cursor: not-allowed;
              pointer-events: none;
            }
          }
          .form-dropdown {
            width: 16rem;
            margin-bottom: 1rem;
            .bs-input-label-suffix {
              white-space: nowrap;
              color: $gray-9;
            }
          }
          .info-icon {
            margin-left: 0.5rem;
            font-size: 1.3rem;
            margin-top: 0.25rem;
            color: gray;
          }
          .schedule-name-input {
            width: 16rem;
            .bs-input-label-suffix {
              white-space: nowrap;
            }
            .schedule-info-icon-right {
              position: absolute;
              right: -30px;
              i {
                color: $gray-6;
                font-size: 1.3rem;
              }
            }
          }
          .switchToggle {
            margin-top: 1.4rem;
            label {
              margin: 0;
              span {
                white-space: nowrap;
                font-size: $text-14;
              }
            }
          }
          .sequence-setting-number {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
            label {
              font-size: $text-14;
              line-height: $text-style-2-line-height;
            }
            .sequence-input-number {
              width: 3.75rem;
              min-width: 3.75rem;
              input[type='number']::-webkit-outer-spin-button,
              input[type='number']::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              input[type='number'] {
                -moz-appearance: textfield;
              }
            }
          }
          .unsub-preset {
            margin-top: 1.4rem;
            border-left: 2px solid $gray-16;
            padding-left: 1.1875rem;
            label {
              color: $gray-9;
            }
            .preset-list {
              display: flex;
              font-size: $text-14;
              font-weight: $font-weight-normal;
              line-height: $text-style-2-line-height;
              color: $popover-arrow-color;
              margin-bottom: 0.5rem;
              .bs-radio-wrapper {
                margin-right: 0.5rem;
              }
              .unsub-link-text {
                color: $blue-6;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          .unsubscribe-setting-editor {
            min-height: 20rem;
          }
          .custom-unsub-preset {
            margin-left: 1.4rem;
            width: 35rem;
            height: 10rem;
          }
          .sequence-save-btn {
            margin-bottom: 2rem;
          }
          .error-caption {
            margin-bottom: 0;
            margin-top: 0.125rem;
            color: $red-6;
          }
          .sequence-settings-switch.switchToggle input:checked + label {
            background: $blue-11;
          }
        }
        .steps-list-container {
          padding-left: 0px;
          padding-right: 0px;
          height: 100%;
          overflow-y: auto;

          .empty-screen {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 6.25rem;
            .step-empty-screen {
              padding: 2rem;
              border-radius: 0.75rem;
              background-color: $gray-1;
              width: 48.5rem;
              box-shadow: 0px 8px 30px 0px rgba(4, 21, 76, 0.15);
              .right-container {
                img {
                  width: 21.25rem;
                  height: 12.5rem;
                  border-radius: 1rem;
                }
                .play-icon {
                  display: flex;
                  width: 3rem;
                  height: 3rem;
                  padding: 0.75rem;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 4.6875rem;
                  background-color: $gray-1;
                  box-shadow: 0px 0px 25px 0px rgba(0, 32, 123, 0.8);
                }
              }
            }
          }
          .empty-steps-container {
            .add-steps {
              &__options {
                position: absolute;
                width: 11.25rem;
                right: 0;
                z-index: 2;
                transform: translateY(-100%);
                top: 13.3125rem;
                border-radius: 4px;
                border: 1px solid $gray-13;
                background: $gray-1;
                /* dropdown  */
                box-shadow: 8px 16px 56px -24px rgba(156, 157, 161, 0.24),
                  2px 2px 16px -3px rgba(4, 21, 76, 0.16);
                padding: 0.5rem 0px;
              }
              &__wrapper {
                background-color: $gray-1;
                border-radius: 0.25rem;
                box-shadow: 8px 16px 56px -24px rgba(156, 157, 161, 0.24),
                  2px 2px 16px -3px rgba(4, 21, 76, 0.16);
                padding: 1rem 1.5rem;
                height: 4rem;
              }
              &__steps {
                display: flex;
                align-items: center;
                gap: 0.75rem;
              }
              &__step {
                padding: 0.375rem 0.5rem;
                border-radius: 0.25rem;
              }
              &__step-email:hover {
                background-color: #2563eb !important;
              }
              &__step-linkedin:hover {
                background-color: #167dbc !important;
              }
              &__step-call:hover {
                background-color: #7c3aed !important;
              }
              &__step-task:hover {
                background-color: #ea580c !important;
              }
            }
          }
          .step-list-inner {
            ul {
              padding: 0;
              margin: 0;
              margin-bottom: 1.25rem;
              list-style: none;
              display: flex;
              flex: 1;
              flex-direction: column;
              position: relative;
              &::before {
                content: '';
                width: 1px;
                height: 104%;
                background-color: $gray-13;
                position: absolute;
                top: 1.5rem;
                left: 3.0625rem;
              }

              .placeholder {
                position: absolute;
                background-color: white;
                border-radius: 3px;
                border: dashed 1px blue;
                background-color: white;
              }

              .step-day-wrap {
                width: 5.625rem;
                height: 1.5rem;
                background-color: $gray-13;
                border: 1px solid $blue-14;
                border-radius: 0px 0.25rem 0.25rem 0px;
                border-left-width: 0px;
                z-index: 2;
                & span {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              li {
                min-width: 990px;
                position: relative;
                border: 1px solid transparent;

                list-style: none;
                display: flex;
                border-radius: $border-radius;
                flex-direction: column;
                user-select: none;
                transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

                .st {
                  height: auto;
                  .dragging-wrap {
                    width: 8.1875rem !important;
                    height: 2rem !important;
                    border-radius: 0.25rem;
                    border: 1px solid $blue-19;
                  }
                  .drag {
                    height: auto;
                    min-height: 7.375rem;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                  }
                }

                &:hover {
                  .dnd-icon-wrapper {
                    visibility: visible;
                    opacity: 1;
                  }
                }

                .dnd-icon-wrapper {
                  position: absolute;
                  top: 1.25rem;
                  left: 0.5rem;
                  visibility: hidden;
                  opacity: 0;
                  transition: visibility 0s, opacity 0.5s linear;
                }

                &:hover {
                  .step-list {
                    .step-actions-wrapper {
                      display: flex;
                    }
                  }
                }
                &:after {
                  content: '';
                  border-radius: 5px;
                  position: absolute;
                  z-index: -1;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  box-shadow: 0 5px 20px rgba(220, 224, 230, 0.5);
                  opacity: 0;
                  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                }
                .step-list {
                  width: 100%;
                  .tag {
                    padding: 0.25rem 0.5rem;
                  }
                  .step-wrap {
                    padding-left: 1.9375rem;
                    padding-right: 2rem;
                  }

                  // Step Tentative Date Styles
                  .step-tentative-date {
                    img {
                      width: 1rem;
                      height: 1rem;
                    }
                  }

                  .step-type-icon {
                    font-size: 1.25rem;
                    background-color: $blue-24;
                    width: 1.875rem;
                    height: 1.875rem;
                    border-radius: 0.25rem 0px 0px 0.25rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 1.125rem;
                      height: 1.125rem;
                    }
                  }
                  .step-title {
                    height: 1.875rem;
                    padding: 0.3125rem 0.5rem;

                    border: 1px solid $blue-13;
                    border-radius: 0px 4px 4px 0px;

                    display: flex;
                    align-items: center;
                    gap: 0.25rem;

                    .step-title-text {
                      font-weight: $font-weight-semibold;
                      font-size: 14px;
                      line-height: 20px;
                      color: #1f2937;
                    }
                  }
                  .step-change-date-drop-down {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    cursor: pointer;
                    .step-type-action {
                      border-radius: $border-radius;
                      padding: 0.2rem;
                      font-size: 0.7rem;
                      color: $gray-8;
                      margin-left: 0.5rem;
                      &::before {
                        position: relative;
                        top: 1px;
                      }
                      &:hover {
                        color: $gray-10;
                      }
                    }
                  }
                  .step-actions-wrapper {
                    display: none;
                    align-items: center;
                    gap: 1rem;
                    .step-id {
                      background-color: #eff7fe;
                      padding: 0.125rem 0.5rem;
                      border-radius: 0.5rem;
                      code {
                        font-family: monospace;
                        font-size: 14px;
                        color: $gray-12;
                      }
                      &:hover {
                        cursor: pointer;
                        code {
                          text-decoration: underline;
                        }
                      }
                    }
                    .step-action-icon {
                      cursor: pointer;
                      &:hover {
                        i {
                          color: $gray-10;
                        }
                      }
                      i {
                        font-size: $text-18;
                        color: $gray-6;
                      }
                    }
                  }
                }
                .step-sub-list-inner {
                  display: flex;
                  flex-direction: column;
                  padding: 0.5rem 2rem 0 7.6281rem;

                  .step-sub-list {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 0.0625rem solid $gray-4;
                    padding: 0.5rem 1rem;
                    height: 4rem;
                    border-radius: 0.25rem;
                    transition: all 0.2s ease;
                    cursor: pointer;

                    &:hover {
                      background-color: #eff6ff;

                      .step-sub-list-right {
                        .step-email-type {
                          display: flex;
                        }
                        .grid-action {
                          visibility: visible;
                        }
                      }
                    }

                    &:nth-last-child(n + 2) {
                      &:first-child {
                        border-radius: 0.25rem 0.25rem 0px 0px;
                      }
                    }

                    &:nth-last-child(n + 2) ~ div:last-child {
                      border-top: 0;
                      border-radius: 0px 0px 0.25rem 0.25rem;
                    }

                    .step-sub-list-left {
                      display: flex;
                      align-items: center;
                      gap: 1rem;

                      width: calc(100% - 36rem);
                      height: 2.375rem;

                      @-moz-document url-prefix() {
                        .step-action .switchToggle {
                          height: 3.5rem;
                        }
                      }

                      .step-action .switchToggle {
                        @supports (-moz-appearance: none) {
                          height: 3.5rem;
                        }

                        label {
                          margin-bottom: 0;
                        }
                        input:checked + label {
                          background: $blue-11;
                        }
                      }

                      .step-sub-icon {
                        min-width: 1.5rem;
                        max-width: 1.5rem;
                        height: 1.5rem;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        border-radius: 0.25rem;

                        font-size: 0.75rem;
                        line-height: 1.25rem;
                        font-weight: $font-weight-semibold;
                        color: #6d28d9;
                        background: #ede9fe;
                      }
                      .step-sub-desc {
                        width: calc(100% - 2.5rem);
                        cursor: pointer;
                        p {
                          margin: 0;
                          &:first-of-type {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            font-weight: $font-weight-semibold;
                            font-size: 0.875rem;
                            line-height: 1.25rem;
                            color: $blue-15;
                          }

                          &:last-of-type {
                            width: calc(100% - 2rem);
                            margin-top: 0.125rem !important;

                            font-weight: $font-weight-normal;
                            font-size: 0.75rem;
                            line-height: 1.125rem;
                            color: #4b5563;

                            span {
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            }
                          }
                        }
                        span {
                          color: $gray-12;
                        }
                        & .ai-generated-icon {
                          width: 0.625rem;
                          max-width: 0.625rem;
                          height: 0.625rem;
                          margin-right: 0.25rem;
                        }
                      }
                      .step-attachment-icon {
                        font-size: 0.875rem;
                        color: $gray-11;
                        transition: all 0.2s ease;
                        &:hover {
                          color: $blue-15;
                        }
                      }
                    }
                    .step-sub-list-right {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 1.625rem;
                      .step-action {
                        display: inline-table;
                        width: 6rem;

                        label {
                          margin: 0;
                        }
                      }
                      .step-email-type {
                        display: none;
                        justify-content: center;
                        align-items: center;

                        width: 6.5rem;

                        .step-email-type-icon {
                          font-size: 1rem;
                          align-items: center;
                          margin-right: 0.4rem;
                          margin-top: 0.1rem;
                        }
                        .step-email-type-text {
                          font-weight: $font-weight-medium;
                          font-size: 0.875rem;
                          line-height: 1.25rem;
                        }
                      }
                      .state-row {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 12rem;
                        height: 3rem;

                        .state-block {
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;

                          width: 4rem;
                          height: 3rem;

                          span {
                            line-height: 1.5rem;
                          }
                          small {
                            font-size: $text-12;
                            line-height: 1rem;
                            font-weight: $font-weight-medium;
                          }
                        }
                      }
                      .grid-action {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 0.25rem;
                        visibility: hidden;

                        .option {
                          width: 2rem;
                          height: 2rem;

                          display: flex;
                          justify-content: center;
                          align-items: center;

                          transition: all 0.2s ease;

                          svg {
                            color: $gray-11;
                            &:hover {
                              color: $gray-15;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .add-steps {
              margin-left: 0.5rem;
              &__options {
                position: absolute;
                width: 10.1875rem;
                left: 0;
                z-index: 2;
                transform: translateY(-100%);
                top: 0;
                border-radius: 4px;
                border: 1px solid $gray-13;
                background: $gray-1;
                /* dropdown  */
                box-shadow: 8px 16px 56px -24px rgba(156, 157, 161, 0.24),
                  2px 2px 16px -3px rgba(4, 21, 76, 0.16);
                padding: 0.5rem 0px;
              }
              &__option {
                padding: 0.375rem 1rem;
                &:hover {
                  background-color: $blue-17;
                }
              }
            }
            .step-variant-tip {
              font-style: italic;
              font-weight: $font-weight-medium;
              font-size: 0.75rem;
              line-height: 1rem;
              color: #b45309;
              margin: 0.5rem 0 0 0.5rem;
            }
            .add-variation {
              width: fit-content;
              height: 1.375rem;

              cursor: pointer;

              display: inline-block;
              align-items: center;

              margin-top: 0.5rem;
              margin-left: 7.625rem;

              font-weight: $font-weight-medium;
              font-size: 0.875rem;
              line-height: 1.25rem;

              .ai-variant {
                span {
                  background: linear-gradient(
                    114deg,
                    #eb4ce6 -49.13%,
                    #9d54db 13.02%,
                    #1d4ed8 93.65%
                  );
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
                &.cr-variant {
                  &:hover {
                    border-radius: 0.25rem;
                    background: linear-gradient(
                      114deg,
                      rgba(235, 76, 230, 0.05) -49.13%,
                      rgba(157, 84, 219, 0.05) 13.02%,
                      rgba(29, 78, 216, 0.05) 93.65%
                    );
                  }
                }
              }

              .cr-variant {
                padding: 0.125rem 0.5rem;
                & .btn-text {
                  line-height: $text-style-1-line-height;
                }
                &:hover {
                  background: $gray-16;
                  border-radius: 0.25rem;
                }
              }

              transition: all 0.2s ease;

              // &:hover {
              //   text-decoration-line: underline;
              // }
            }
            .smart-ideas {
              margin-left: 0.5rem;
              margin-top: 1rem;
            }
          }
        }

        .bs-tabs-content {
          .empty-screen {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 6.25rem;
            .prospect-empty-screen {
              padding: 2rem;
              border-radius: 0.75rem;
              background-color: $gray-1;
              width: 48.5rem;
              box-shadow: 0px 8px 30px 0px rgba(4, 21, 76, 0.15);
              .right-container {
                img {
                  width: 21.25rem;
                  height: 12.5rem;
                  border-radius: 1rem;
                }
                .play-icon {
                  display: flex;
                  width: 3rem;
                  height: 3rem;
                  padding: 0.75rem;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 4.6875rem;
                  background-color: $gray-1;
                  box-shadow: 0px 0px 25px 0px rgba(0, 32, 123, 0.8);
                }
              }
            }
          }
        }
      }
    }
    .contact-list-container {
      width: 100%;
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;

      .cst-banner {
        margin-bottom: 0px;
        border: 1px solid $orange-18;
        div {
          width: 100%;
          display: flex !important;
          justify-content: center !important;
        }
        span {
          color: $blue-15 !important;
        }
        .upgrade-link {
          color: $blue-11 !important;
          text-decoration: none;
        }
      }
      .prospects-main {
        width: 100%;
        // height: calc(100vh - 12.75rem);
        flex-grow: 1;
        margin: 0 !important;
        overflow-y: hidden;

        .empty-list-prospect {
          height: calc(100vh - 25rem);
        }
        .step-filter {
          .filter-item {
            margin-bottom: 0.5rem;
          }
        }
      }
      .status-filter {
        padding: 0.75rem 1.5rem 0.75rem 1.625rem;
        display: flex;
        gap: 0.75rem;
        .divider-vertical {
          height: 2.625rem;
          width: 1px;
          background-color: $gray-13;
          align-self: center;
        }
      }
      .prospect-banner {
        padding-right: 0.5rem;

        .prospect-selected-row-info {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5625rem 0;

          .divider-vertical {
            height: 1.25rem;
            width: 0.063rem;
            background: $gray-11;
            margin: 0px 0.75rem;
          }
        }

        .prospect-selected-row-info-waring {
          background: $yellow-11;
        }

        .prospect-selected-row-info-danger {
          background: $red-17;
        }
      }
      .prospect-list-table {
        position: relative;
        // overflow-y: auto;
        // overflow-x: auto;
        height: 100%;
        display: flex;
        flex-direction: column;

        .col {
          padding: 0;
        }
      }
      .table-list {
        height: 100%;
        overflow-y: auto;
        tr:hover {
          background-color: none !important;
        }

        .react-bootstrap-table {
          .table-organism {
            .table-organism-header {
              tr {
                th {
                  border-top: 0px;
                }
                th:first-of-type,
                th:last-of-type {
                  border-radius: 0 !important;
                }
              }
            }
          }
        }
      }
      .sm-table {
        .table-list {
          // height: calc(100vh - 21rem);
        }
      }
      .sequence-prospect-actions {
        width: 100%;
        height: 4.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1.5rem;
        margin: 0;
        border-bottom: 1px solid $gray-16;
        .sequence-prospect-search-bar-and-filter {
          display: flex;
          align-items: center;
          width: 87%;
          gap: 0.8rem;
          .search-bar {
            margin-right: 1.5rem;
          }
          .prospect-filter-icon-container {
            width: 2rem;
            height: 2rem;
            padding: 0.3125rem !important;

            display: flex;
            justify-content: center;
            align-items: center;

            border: none;
            border-radius: 0.25rem;

            transition: all 0.2s;
            cursor: pointer;

            i {
              font-size: 1.25rem;
              color: $gray-15;
            }

            &:hover {
              background-color: $blue-13 !important;
              i {
                color: $blue-11;
              }
            }
          }
          .sequence-prospect-separator {
            width: 0.125rem;
            height: 2rem;
            background: $gray-23;
            border-radius: 0.125rem;
          }
        }
        .prospect-export {
          padding-top: 6.5px;
        }
      }
    }
  }
}
.change-date-popover {
  max-width: initial;
  .bs-popover-confirm-desc {
    flex-direction: column;
    .change-date-div {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      label {
        margin: 0 0.75rem 0 0;
        color: $gray-10;
      }
      .change-date-number {
        min-width: 4rem;
        margin: 0 0.75rem 0 0;
        width: 4rem;
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
  }
}

.email-suggestion-tooltip {
  .spammy-word-tooltip {
    padding: 0.625rem 0.3125rem;
  }
  p {
    margin: 0;
    text-align: left;
  }
  .spammy-word-tooltip-title,
  .spammy-word-tooltip-text {
    font-weight: $font-weight-medium;
    font-size: 0.625rem;
    line-height: 0.9375rem;
    color: $gray-1;
  }
  .spammy-word-tooltip-title {
    margin-bottom: 0.625rem;
    text-underline-offset: 0.125rem;
    text-decoration-line: underline;
  }
}

.confirm-modal-width {
  .modal-dialog {
    .modal-content {
      .bs-modal-body {
        padding: 1.5rem !important;
      }
      .bs-modal-icon {
        display: flex;
      }
      .bs-modal-inner-content {
        width: 100%;

        .bs-modal-inner-content__title {
          margin: 0;
        }

        .bs-input-affix-wrapper {
          width: 100%;
        }
        p {
          margin: 0;
        }
      }
    }
  }
}

.report-submit-failed-width {
  width: 6rem;
}

.import-contacts-modal {
  .modal-dialog {
    max-width: 48.75rem;

    .modal-body {
      padding: 1rem 1.5rem 1rem 1.5rem;
      height: 29rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .import-contacts-content {
        margin-bottom: 3.5rem;
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .import-contact-stage {
        display: flex;
        flex-direction: column;
        height: 100%;
        .import-contact-stage-content {
          flex: 1;
        }
      }

      .import-flow {
        flex: 1;
        display: flex;
        flex-direction: column;

        .radio-tabs-wrapper {
          display: flex;
          justify-content: center;
          margin-top: 0.8rem;
          margin-right: 0px;
          margin-bottom: 0.5rem;
          margin-left: 0px;

          > .btn-group > label {
            width: 11rem;
          }
        }

        .import-flow-stage-wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;

          .pre-select-stage {
            height: 17.875rem;
            background: $gray-19;
          }
          .post-select-stage {
            background: $green-21;
            border: 0.0625rem solid $green-15;
            border-radius: 0.5rem;
          }
          .csv-error {
            border: 1px solid $red-11;
            background: $red-17;
          }

          .select-file {
            margin-bottom: 1rem;
            border-radius: 0.5rem;
            .select-file-content {
              display: flex;
              justify-content: center;
              align-items: center;

              > div {
                display: flex;
                flex-direction: column;
                align-items: center;
              }

              .shd-drag-and-drop {
                flex-direction: row;
                justify-content: center;

                border-radius: 0.5rem;
                border: 0.0625rem dashed $gray-23;
                &.dragging {
                  border: none;
                }
              }
            }

            .pre-select {
              display: flex;
              flex-direction: column;
              align-items: center;

              .empty-data-icon-wrapper img {
                width: 1.875rem;
              }

              .pre-select-tagline {
                font-weight: $font-weight-medium;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: $blue-15;

                margin: 1.5rem 0 1rem 0;
              }

              .pre-select-info {
                color: $gray-15;
                font-weight: $font-weight-normal;

                font-size: 0.75rem;
                line-height: 1.25rem;
              }

              .pre-select-file-uploader {
                width: 7.5rem;
                height: 2rem;

                margin-top: 1rem;
                background-color: $gray-1;
              }

              .file-uploader {
                width: 7.7rem;
                height: 2rem;
                border: 1px solid $blue-11;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;

                > input {
                  display: none;
                }

                .select-file-placeholder {
                  padding: 0 0.75rem;
                  color: $gray-6;
                }

                .choose-file {
                  height: calc(100% + 2px);
                  display: flex;
                  align-items: center;
                  border-radius: 4px;
                  color: $blue-11;
                  justify-content: center;
                  width: 100%;
                  font-weight: $font-weight-semibold;
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }

            .post-select {
              .post-select-icon-wrapper {
                width: 2.5rem;
                height: 2.5rem;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .post-select-filename {
                font-weight: $font-weight-medium;
                font-size: 1rem;
                line-height: 1.5rem;
                color: $blue-15;
                margin-top: 0.875rem;
                margin-bottom: 1rem;
              }

              .post-select-success,
              .post-select-delete {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
              }

              .post-select-success {
                font-weight: $font-weight-normal;
                font-size: 0.875rem;
                line-height: 1rem;
                color: $blue-15;
              }

              .csv-error-message {
                color: $blue-15;
                text-align: center;
                font-size: 0.875rem;
                line-height: 1.25rem;
                margin: 0;
                margin-top: 0.875rem;
                span {
                  display: block;
                }
              }

              .post-select-delete {
                width: 8.625rem;
                height: 2rem;

                margin-top: 1.125rem;
                color: $gray-15;
                transition: all 0.2s ease;

                span {
                  font-size: 0.875rem;
                  line-height: 1.25rem;
                  font-weight: $font-weight-medium;
                }

                svg {
                  width: 1.25rem;
                  height: 1.25rem;
                }

                i {
                  font-size: 1.25rem;
                }

                &:hover {
                  color: $blue-15;
                  i {
                    &::before {
                      color: $blue-15;
                    }
                  }
                }
              }

              .file-preview {
                width: 13.7rem;
                height: 2rem;
                border: 1px solid $gray-5;
                border-radius: 4px;
                display: flex;
                align-items: center;
                user-select: none;
                margin-top: 1.75rem;

                .filename {
                  flex: 1;
                  overflow-x: hidden;
                  text-overflow: ellipsis;
                  padding: 0 0.75rem;
                  color: $gray-6;
                  white-space: nowrap;
                }

                .remove-file {
                  display: flex;
                  align-items: center;
                  height: 100%;
                  padding: 0 0.3125rem;
                  cursor: pointer;
                  color: $gray-7;
                  font-size: 1.3125rem;
                }
              }
            }
          }

          .map-fields {
            .map-fields-content {
              padding-bottom: 1rem;
            }

            .map-list {
              width: 100%;
              height: 100%;
              border: 1px solid $gray-5;
              border-radius: 4px;
              display: flex;
              flex-direction: column;

              .map-list-header,
              .map-list-item {
                display: flex;
              }

              .map-list-header {
                padding: 0.625rem 1.5rem;
                color: $gray-9;
              }

              .map-list-item {
                padding: 0.45rem 1.5rem;
                align-items: center;

                .map-list-item-child-left {
                  flex: 1;
                  display: flex;
                  color: #1f2937;
                  overflow: hidden;
                  margin-right: 1rem;

                  .csv-header-label,
                  .csv-row-value {
                    flex-shrink: 0 !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .csv-header-label {
                    flex: 3;
                    max-width: 9rem;
                  }

                  .csv-row-value {
                    flex: 3;
                    margin-left: 6rem;
                  }
                }

                .map-list-item-child-right {
                  display: flex;
                  align-items: center;
                  width: 13rem;
                  justify-content: flex-end;

                  .map-list-item-remove-icon-wrapper {
                    padding: 5px;
                    margin-right: 0.5rem;
                    border-radius: 100%;
                    display: flex;
                    cursor: pointer;
                  }

                  .bs-select-box {
                    width: 10rem;
                    color: #1f2937;
                  }
                }
              }

              .map-list-header {
                display: flex;
                justify-content: space-between;
                background-color: $gray-100;
                color: $gray-12;
                font-weight: $font-weight-semibold;
              }

              .map-list-content {
                flex: 1;
                overflow-y: auto;
              }
              .map-list-footer {
                display: flex;
                height: 3.125rem;
                justify-content: space-between;
                background: $gray-100;
              }
              .map-list-scroll {
                height: 21.875rem;
                flex: auto;
                margin-top: 0.625rem;
              }
              .map-list-footer-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-left: 3px !important;
                margin-top: 0.5rem;
              }
            }

            .map-fields-config-wrapper {
              display: flex;

              .map-fields-config {
                display: flex;
                align-items: center;

                &:not(:first-child) {
                  margin-left: 0.75rem;
                }

                .map-fields-config-label {
                  color: #1f2937;
                  flex-shrink: 0;
                  margin-right: 0.5rem;
                }
              }

              .map-fields-step {
                .bs-select-box {
                  width: 7rem;
                }
              }

              .map-fields-conflict-action {
                .bs-select-box {
                  width: 12.5rem;
                }
                .info-circle {
                  margin-bottom: 0.25rem;
                  margin-right: 0.25rem;
                }
              }
            }

            .modal-footer {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
          }

          .sample-map-field {
            margin-left: 8.7rem;
          }

          .import-config {
            .import-config-content {
              padding: 0.625rem 2px 0 2px;

              .import-config-step-wrapper {
                width: 14rem;
              }
            }

            .import-config-footer {
              .modal-footer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              }
            }
          }

          ::-webkit-scrollbar {
            width: 5px;
            height: 4.93rem;
          }

          ::-webkit-scrollbar-track {
            background: #fff;
          }

          ::-webkit-scrollbar-thumb {
            background: $gray-23;
            border-radius: 4px;
          }

          .email-verification {
            flex: 1;
            display: flex;
            flex-direction: column;
            .content-loader {
              align-self: center;
              width: 15rem;
            }
            .email-verification-content-footer {
              flex: 1;
              display: flex;
              flex-direction: column;
              font-weight: 400;
              font-size: 1rem;
              .verification-checkbox {
                flex: 1;
                display: flex;
                flex-direction: row;
                padding-left: 0.5rem;
                margin-bottom: 1rem;
              }
              .text {
                color: $blue-15;
                font-size: 0.875rem;
                font-weight: $font-weight-normal;
                margin-top: 0.15rem;
              }
            }
            .review-section-container {
              display: flex;
              flex-direction: column;
              .text {
                font-size: 0.87rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.25rem;
                letter-spacing: 0em;
                text-align: left;
                color: #1f2937;
              }
              .review-section-title-container {
                display: flex;
                align-items: center;
                .review-section-title {
                  margin-left: 0.6rem;
                  font-weight: $font-weight-semibold;
                }
              }
              .email-verification-desc-container {
                margin-top: 0.75rem;
                font-size: 0.75rem;
                line-height: 1rem;
                color: #595959;
              }
              .status-name {
                margin-left: 8px;
                font-size: 14px;
                font-weight: 400;
              }
              .status-content {
                font-size: 12px;
                margin-left: 1.18rem;
                color: #595959;
              }
              .valid-contact {
                font-size: 1rem;
                .icon {
                  color: $green-7;
                }
                .valid-status-name {
                  color: $green-7;
                }
              }
              .risky-contact {
                font-size: 1rem;
                .icon {
                  color: $red-7;
                }
                .risky-status-name {
                  color: $red-7;
                }
              }
              .tags-autosuggest-container {
                .tags-autosuggest-width {
                  width: 100%;
                  .tag-input-container {
                    flex: 1;
                    min-width: 10rem;
                  }
                }
                input {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .import-contacts-footer {
        margin: 0.2rem -1.5rem;
        width: 100%;
        position: absolute;
        bottom: 0;
      }

      .import-contacts-footer-button {
        width: 6rem;
      }
    }
  }
}

.risky-prospect-import-container {
  background: $yellow-11;
  border: 1px solid $orange-18;
  border-radius: 4px;
  height: 2.5rem;
  padding-left: 1.25rem;
  font-weight: $font-weight-medium;
  line-height: $text-style-1-line-height;
  color: $blue-15;
}

.add-sequence-report-modal {
  .modal-dialog {
    max-height: 15.6875rem;
    max-width: 30.5rem;
  }
  .modal-title {
    img {
      height: 1.25rem;
    }
  }
}

.link-track-nudge-user-modal {
  margin-top: 12.5rem;

  .modal-dialog-centered {
    align-items: flex-start;
  }

  .modal-dialog {
    max-width: 31.25rem;
  }

  .h4 {
    margin-bottom: 0rem;
  }

  .modal-header {
    border-bottom: 0 none;
  }

  .modal-content {
    color: $blue-15;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .modal-title {
    margin-top: 2rem;
    margin-bottom: 0;
    color: $black-1;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding-top: 0.25rem !important;
  }

  .modal-footer {
    margin-left: 5%;
    margin-right: 6%;
    .bs-modal-footer-action-buttons {
      margin-right: -1.5rem;
    }

    .btn-solid,
    .btn-ghost {
      height: 2rem;
      min-width: 6.25rem;
    }

    .btn-ghost {
      border: 1px solid $gray-5;
      color: $gray-8;
    }
  }
}

.unsubscribe-link-modal {
  margin-top: 12.5rem;

  .modal-dialog-centered {
    align-items: flex-start;
  }

  .modal-dialog {
    max-width: 28.125rem;
  }

  .h4 {
    margin-bottom: 0rem;
  }

  .modal-header {
    border-bottom: 0 none;
  }

  .modal-content {
    color: $blue-15;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .modal-title {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding-top: 0.25rem !important;
  }

  .modal-footer {
    margin-left: 5%;
    margin-right: 6%;
    .bs-modal-footer-action-buttons {
      margin-right: -1.5rem;
    }

    .btn-solid,
    .btn-ghost {
      height: 2rem;
      width: 6.25rem;
    }

    .btn-ghost {
      border: 1px solid $gray-5;
      color: $gray-8;
    }
  }
}

.tooltip-schedule {
  max-width: 450px;

  &.bs-popover-inner {
    background-color: #1f2937;

    .bs-popover-inner-content {
      padding: 0;
    }

    .arrow::after,
    .arrow::before {
      border-right-color: #1f2937;
    }
  }

  .schedule-day {
    width: 100%;
    padding: 0px 16px 16px 16px;

    display: flex;
    align-items: center;

    p {
      margin: 0;
      color: #ffffff;

      &:first-of-type {
        min-width: 75px;

        font-family: Inter;
        font-weight: $font-weight-medium;
        font-size: 12px;
      }

      &:nth-of-type(2) {
        background-color: #4b5563;
        height: 2px;
        width: 15px;
        border-radius: 1px;
      }

      &:last-of-type {
        margin-left: 10px;

        display: flex;
        span {
          margin: 0px 5px;
          width: 86px;
          height: 20px;
          border-radius: 4px;
          background-color: #374151;
          font-feature-settings: 'tnum' on, 'lnum' on;

          display: flex;
          justify-content: center;
          align-items: center;

          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
        }
      }
    }
  }

  .schedule-timezone {
    font-family: Inter;
    font-style: normal;
    font-weight: $font-weight-semibold;
    font-size: 12px;
    color: #ffffff;

    padding: 12px 16px;
    margin-bottom: 12px;
    border-bottom: 1px solid #374151;
  }
}
.modal-content {
  .overflow-hidden {
    .table-list {
      .selection-cell-header {
        border-top: 1px solid #dee2e6 !important;
        padding-left: 1.7rem !important;
      }
    }
  }

  .modal-footer {
    .map-fields-config {
      display: flex;
      .map-fields-config-label {
        width: 10rem;
        text-align: center;
        margin-top: 0.3125rem;
      }
    }
    .cancel-button {
      margin-right: 2rem;
    }
  }
}

.modal-content {
  .prospect-container {
    height: 100vh;
    overflow-x: hidden;

    .table-list {
      height: 75%;
      .selection-cell {
        padding-right: 0px;
        width: 50px !important;
      }
      .name-field {
        width: 33.33%;
      }
      .sequence-detail {
        width: 50%;
      }
      .created-col {
        .created-day {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: $gray-8;
        }
        .created-by {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: $blue-6;
        }
      }
    }

    .empty-prospect {
      width: 24.375rem;
      padding: 10% 0 3rem 0;
      margin: auto;
      text-align: center;
    }
    .prospect-header {
      color: $gray-10;
      padding: 0.5rem 1rem;
      box-shadow: 10px 0 30px rgba(220, 224, 230, 0.51);
      height: 4.5rem;
      position: relative;
      z-index: 1;
      background: $gray-1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        margin: 0;
      }
    }
    .prospect-component {
      width: 100%;
      height: 100%;
      padding: 0 0.5625rem;
      .prospect-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0.5rem 0;
        border-bottom: 1px solid $gray-5;
        .bulk-action-button {
          .bulk-action-button-icon {
            color: $blue-6;
          }
          .bulk-action-button-text {
            color: $blue-6;
          }
        }
        .prospect-search-bar-and-filter {
          display: flex;
          align-items: center;
          .search-bar {
            margin-right: 1.5rem;
          }
        }
        .prospect-refresh {
          margin-left: 1rem;
          border: 1px solid $blue-6;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 0px 4px 0px 4px;
        }
      }
    }
  }
}

.search-and-add {
  .modal-content {
    height: fit-content;
  }
  .table-list {
    height: 100%;
    overflow-y: auto;
  }

  // @media only screen and (min-width: 1000px) and (max-width: 1200px) {
  //   .table-list {
  //     height: calc(100vh - 15rem);
  //   }
  // }
  // @media only screen and (min-width: 1200px) and (max-width: 1440px) {
  //   .table-list {
  //     height: calc(100vh - 20rem);
  //   }
  // }
  // @media only screen and (min-width: 1440px) and (max-width: 1500px) {
  //   .table-list {
  //     height: calc(100vh - 25rem);
  //   }
  // }
  // @media only screen and (min-width: 1500px) and (max-width: 1700px) {
  //   .table-list {
  //     height: calc(100vh - 22rem);
  //   }
  // }
  // @media only screen and (min-width: 1700px) and (max-width: 1900px) {
  //   .table-list {
  //     height: calc(100vh - 37rem);
  //   }
  // }
  // @media only screen and (min-width: 1700px) and (max-width: 1900px) {
  //   .table-list {
  //     height: calc(100vh - 37rem);
  //   }
  // }

  .form-body {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.onboarding-modal-container {
  .modal-dialog {
    max-width: 55vw !important;
    .modal-content {
      .modal-body {
        height: 490px;
        .email-connect-content {
          font-size: 16px;
          line-height: 24px;
          .select-account-type-label {
            font-weight: $font-weight-semibold;
          }
          .one-liner {
            width: 340px;
            font-weight: 400;
            text-align: center;
            color: #595959;
          }
          .connect-gmail {
            cursor: pointer;
          }
          .connect-microsoft {
            cursor: pointer;
          }
          .microsoft-logo {
            padding: 1.15rem 1.25rem;
          }
          .btn-later-container {
            margin-top: 100px;
            .btn-later {
              color: #8c8c8c;
            }
          }
        }

        .intro-video-content {
          font-size: 16px;
          line-height: 24px;
          .email-not-connected-warning {
            color: #d46b08;
            font-weight: 400;
          }
          .success-message {
            color: #389e0d;
            font-weight: $font-weight-semibold;
          }
        }
      }
    }
  }
}

#rc-tabs-2-panel-emails {
  .empty-list__body {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }
}

@mixin filter-text {
  font-family: Inter;
  font-style: normal;
  letter-spacing: 0em;
}

@mixin text {
  @include filter-text;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@mixin number {
  @include filter-text;
  font-size: 20px;
  font-weight: $font-weight-semibold;
  line-height: 28px;
}

.emails-container {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  .empty-list-emails {
    height: calc(100vh - 19rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
      margin-top: 1.5rem;
      font-weight: $font-weight-bold;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $blue-15;
    }

    a {
      color: $blue-11;
    }
  }
  .status-filter {
    padding: 0.75rem 1.5rem;
    display: flex;
    gap: 0.75rem;
    .filter-item {
      width: 7rem;
    }
  }
  .emails-main {
    height: 100%;
    margin: 0 !important;
    flex-grow: 1;
    overflow-y: hidden;
    .step-filter {
      .filter-item {
        margin-bottom: 0.5rem;
      }
    }
  }
  .emails-list-table {
    position: relative;
    height: 100%;
    padding-left: 0px;

    .col {
      padding: 0;
    }
  }
  .email-table {
    height: 100%;
    overflow-y: auto;
  }
  .email-table-filter {
    height: 100%;
    overflow-y: auto;
    .table-list {
      height: 100%;
    }
  }
  .table-list {
    height: 100%;
    overflow-y: auto;
    .table {
      color: $blue-15 !important;
    }

    .react-bootstrap-table {
      .table-organism {
        .table-organism-header {
          tr {
            th {
              border-top: 0px;
            }
            th:first-of-type,
            th:last-of-type {
              border-radius: 0 !important;
            }
          }
        }
        .table-organism-body {
          tr > td {
            .step-variant-block {
              width: 1.125rem;
              height: 1.125rem;
              border-radius: 0.125rem;
            }
          }
        }
      }
    }

    .name {
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: $font-weight-semibold;
      font-size: 14px;
      line-height: 22px;
      color: $blue-15;
    }
    .email {
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $gray-12;
    }
    .subject {
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: $font-weight-semibold;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.status-filter {
  border-bottom: 1px solid $gray-16;
}
.step-filter {
  min-width: 6.25rem;
  max-width: 6.25rem;
  position: relative;
  height: 100%;
  padding-top: 0.75rem;
  padding-left: 0.5938rem;
  padding-right: 0.5938rem;
  overflow: auto;
  border-right: 1px solid $gray-16;
}

.step-filter::-webkit-scrollbar {
  display: none;
}

.filter-item {
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  flex-grow: 1;
  max-width: 400px;
}

.filter-item.sm {
  height: 60px;
}
.filter-item.md {
  height: 60px;
}
.active-step-filter {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  .text,
  .number {
    span {
      color: $blue-11;
      font-weight: $font-weight-medium;
    }
  }

  &::after {
    content: '';
    position: absolute;
    background-color: $blue-11;
    right: 0px;
    display: block;
    height: 2.5rem;
    width: 3px;
    border-radius: 2px 0px 0px 2px;
  }
}
.inactive-step-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all 0.2s ease;
  .text {
    span {
      color: $gray-15;
      font-weight: $font-weight-medium;
    }
  }
  .number {
    span {
      color: $blue-15;
      font-weight: $font-weight-medium;
    }
  }
  &:hover {
    .text,
    .number {
      span {
        color: $blue-11 !important;
      }
    }
  }
}
.active-status-filter {
  background-color: $blue-17;
  border: 1px solid $blue-23;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px 0px #bccff6;
  gap: 0.375rem;
  .bounce-summary-dropdown {
    background: $white;
  }
}
.inactive-status-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all 0.2s ease;
  gap: 0.375rem;
  &:hover {
    background: $blue-17;
  }
}
.segments {
  &--items {
    z-index: 2;
    padding: 0.5rem 0rem;
    border-radius: 0.25rem;
    border: 1px solid $gray-13;
    background-color: $gray-1;
    display: grid;
    row-gap: 0.125rem;
    width: 12.5rem;
    max-height: 12.875rem;
    overflow-y: auto;
  }
  &--items--segment {
    top: 4rem;
    left: 0px;
  }
  &--items-outcome {
    top: 4rem;
    right: 0px;
    height: 15rem;
    overflow-y: scroll;
  }
  &--item {
    padding: 0.4375rem 1rem;
    &:hover {
      background-color: $blue-17;
    }
  }
}

.subject-tooltip > .tooltip-inner {
  max-width: 50rem;
}

.bounced-reason-tooltip > .tooltip-inner {
  width: 220px;
}

.date-time-tooltip > .tooltip-inner {
  width: 240px;
}
.email-activity-tooltip > .tooltip-inner {
  width: 268px;
}
.activity-count {
  &:hover {
    color: $blue-11;
  }
}

.email-replied-icon {
  width: 1.125rem;
  height: 1.125rem;
  &:hover {
    cursor: pointer;
  }
}

.sequence-pause-tooltip > .tooltip-inner {
  max-width: 25rem;
  text-align: left;
}

.sequence-switch-tooltip > .tooltip-inner {
  max-width: 15rem;
  text-align: left;
}

// Sequences -> Prospect -> Engagement Icon
.span-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 2.9375rem;
  max-height: 1.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

// Prospect Outcome
.prospect-outcome-select {
  width: 10.5rem;
  height: 28px;
  cursor: pointer;
  .cs {
    &__control {
      display: inline-block;
      border-radius: 1.25rem;
      height: 1.75rem;
      // padding-right: 2px;
    }
    &__single-value {
      position: relative !important;
      transform: none;
      -webkit-transform: none;
      max-width: none;
    }
    &__value-container {
      float: left;
      padding-right: 0.25rem;
    }
    &__single-value {
      position: relative !important;
      transform: none;
      -webkit-transform: none;
      max-width: none;
    }
    &__value-container {
      float: left;
      padding-right: 0.25rem;
    }
    &__indicator-separator {
      display: none;
    }
    &__indicator {
      color: $gray-15;
      padding: 0px;
      padding-right: 0.5rem;
      padding-top: 0.375rem;
      svg {
        width: 0.875rem;
        height: 0.875rem;
      }
    }
  }
}

.prospect-custom-outcome-select {
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  width: fit-content;
  height: 28px;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;

  .selected-outcome-main {
    display: flex;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    .outcome-content-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;
      // border: 1px solid green;
    }
  }
  &.positive-sentiment {
    border: 1px solid $green-20;
    background: $green-21;
  }
  &.positive-sentiment:hover {
    background: $green-11;
    border: 1px solid $green-13;
  }
  &.negative-sentiment {
    border: 1px solid $red-11;
    background: $red-17;
  }
  &.negative-sentiment:hover {
    background: $red-19;
    border: 1px solid $red-20;
  }

  &.neutral-sentiment {
    border: 1px solid $gray-23;
    background: $gray-16;
  }
  &.neutral-sentiment:hover {
    background: $gray-13;
    border: 1px solid $gray-11;
  }

  &.default-sentiment {
    border: 1px solid $gray-13;
    background: $gray-19;
  }
  &.default-sentiment:hover {
    background: $gray-16;
    border: 1px solid $gray-23;
  }

  &.disabled,
  &.disabled:hover {
    background: transparent;
    border: none;
  }
}

.prospect-activity-container {
  .modal-content {
    border-radius: 0.5rem !important;
    height: 35rem;
  }

  .bs-tabs {
    height: 100% !important;
    .bs-tabs-content-holder {
      height: 100% !important;
    }

    .bs-tabs-nav {
      padding: 0 24px;
    }
    .bs-tabs-nav::before {
      border-bottom-color: #f3f4f6 !important;
    }

    .bs-tabs-nav-wrap {
      justify-content: space-evenly;
      .bs-tabs-nav-list {
        justify-content: space-between;
        width: 100%;
      }
    }

    .bs-tabs-tab {
      padding: 0 !important;
      margin: 0 !important;
      display: flex;
      justify-content: center;
      color: #1f2937 !important;
      .bs-tabs-tab-btn {
        width: 100%;
      }
    }
    .bs-tabs-tab-active {
      background-color: #eff6ff;
      color: #1d4ed8 !important;
    }
  }
  .modal-dialog {
    max-width: 45rem;
  }
  .modal-header {
    border-bottom: none;
    padding: 0 !important;
    margin: 20px 24px;
    display: flex;
    align-items: flex-start;
    .close {
      padding: 0 1rem !important;
    }
  }
  .modal-body {
    padding: 0 !important;
    overflow: hidden;
    .bs-tabs-nav {
      margin-bottom: 0px !important;
    }
    .bs-tabs-content {
      height: 26rem;
      overflow-y: auto;

      &::-webkit-scrollbar-thumb {
        background: $gray-13;
      }

      .bs-tabs-tabpane {
        margin-bottom: 15px;
        .log-activity {
          margin-left: 24px;
        }
        .empty-block {
          height: calc(100% - 2.625rem);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: space-between;
          .empty-icon-wrapper {
            display: flex;
            justify-content: center;
            height: 3.125rem;
          }
          .empty-message-wrapper {
            margin-top: 1.5rem;
            text-align: center;
            .empty-message-heading {
              font-weight: $font-weight-bold;
              color: $blue-15;
              font-size: 0.875rem;
            }
            .empty-message {
              display: flex;
              justify-content: center;
              width: 100%;
              .description {
                width: 23.375rem;
                text-align: center;
                color: $gray-15;
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }
    .prospect-tab-action {
      height: 100%;
    }
  }
  .bs-tabs-tab-btn {
    font-weight: $font-weight-semibold;
    font-size: 14px;
    line-height: 20px;
  }
  .prospect-fullname-header {
    font-family: Inter;
    font-style: normal;
    font-weight: $font-weight-semibold;
    font-size: 16px;
    line-height: 24px;
    color: #1f2937;
  }
  .prospect-email-header {
    font-family: Inter;
    font-style: normal;
    font-weight: $font-weight-medium;
    font-size: 14px;
    line-height: 20px;
    color: #6b7280;
  }

  .prospect-activity-tile {
    color: #1f2937;

    .prospect-link-clicked {
      width: 26rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 5px;
    }

    .prospect-link-clicked a {
      color: #1d4ed8 !important;
      padding-top: 5px;
    }

    .prospect-activity-content {
      font-weight: $font-weight-medium;
      font-size: 14px !important;
      line-height: 20px !important;
      .prospect-activity-time {
        color: #6b7280;
        margin-left: 17px;
        display: flex;
        align-items: center;
        span {
          margin-left: 0.125rem;
          margin-bottom: -1px;
          line-height: 0.875rem;
        }
      }

      .prospect-activity-name {
        margin-left: 5px;
        font-weight: $font-weight-medium;
      }

      .prospect-current-step {
        font-weight: $font-weight-semibold;
      }

      display: inline-flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .day-text {
    font-weight: $font-weight-medium;
    font-size: 12px;
    line-height: 20px;
  }
}

.email-report-actions-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 1rem 1.5rem 0px 1.5rem;
  margin: 0;
  border-bottom: 1px solid $gray-16;

  .icon:hover {
    color: $blue-11;
  }
}

.export-button-wrapper {
  margin-left: 10px;
  max-width: 100px;
  button {
    width: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  img {
    margin-bottom: 2px;
  }
}

.template-modal {
  .modal-dialog {
    max-width: 65.25rem;
    // max-height: 43rem;
  }
  .modal-content {
    height: 43rem;
    .modal-body {
      padding: 0;
      max-height: 100%;
      font-family: Inter;
      .tabs-wrapper {
        width: 23.125rem;
        border-right: 1px solid $gray-16;
        .bs-tabs.bs-tabs-top {
          height: 100%;
          .bs-tabs-nav {
            .bs-tabs-extra-content {
              width: 100%;
            }
            .bs-tabs-nav-list {
              width: 100%;
              height: 3.125rem;
              padding: 0px 1.5rem;
              display: flex;
              gap: 1rem;
              justify-content: flex-start;
              .bs-tabs-tab {
                margin: 0px;
                font-size: 0.75rem;
                color: $gray-15;
                font-weight: $font-weight-medium;
                &.bs-tabs-tab-active {
                  color: $blue-11;
                  font-weight: $font-weight-semibold;
                }
              }
              // .bs-tabs-ink-bar {
              //   display: none;
              // }
            }
          }
          .bs-tabs-content-holder {
            margin: 0;
            border-left: 0.03125rem solid $blue-14;
            overflow-y: scroll;
            .bs-tabs-content.bs-tabs-content-top {
              height: 100%;
              .bs-tabs-tabpane {
                padding: 0px;
                .search-wrapper {
                  margin-top: 0.25rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .search-input-field {
                    max-width: 20.125rem;
                    height: 2rem;
                    padding: 0px 0.625rem;
                    background: $gray-19;
                    border: 1px solid $gray-26;
                    display: flex;
                    align-items: center;
                    input {
                      width: 17.125rem;
                      height: 1.375rem;
                      font-weight: normal;
                      font-size: 0.875rem;
                      display: flex;
                      align-items: center;
                      background: transparent;
                      color: $blue-15;
                      &::placeholder {
                        color: $gray-11;
                        font-size: 0.875rem;
                      }
                    }
                    i {
                      color: $gray-11;
                    }
                  }
                }
                .results-found {
                  margin: 0;
                  height: 1rem;
                  font-weight: $font-weight-medium;
                  letter-spacing: 0.02em;
                  color: $gray-15;
                }
                .template-list {
                  .saleshandy-template {
                    height: 3.5rem;
                  }
                  .template {
                    height: 4.75rem;
                  }
                  .blue-17 {
                    border: 1px solid $blue-13;
                    border-radius: 0.25rem;
                  }
                  .subject {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
                .performance-wrapper {
                  display: flex;
                  align-items: center;
                  .template-performance {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                      font-weight: $font-weight-medium;
                      color: $gray-11;
                    }
                  }
                }
              }
              .empty-list-container {
                height: calc(100% - 4.0625rem);
                .no-template-found {
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  h3 {
                    font-family: Inter;
                    font-weight: $font-weight-semibold;
                    text-align: center;
                    color: $blue-15;
                    margin-top: 0.625rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    .template-content {
      padding: 0px 1.25rem;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__content {
        position: relative;
        width: 39.125rem;
        height: 460px;
        overflow-y: scroll;
        border-radius: 0.25rem;
      }
      &__actions {
        height: 4rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid $gray-16;
        &-button {
          max-width: 25.625rem;
        }
      }

      &__attachments-list {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        padding-top: 0.9375rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      &__attachment-name {
        margin: 0;
        margin-top: 2px;
        font-weight: $font-weight-medium;
        font-size: 0.625rem;
        line-height: 0.75rem;
        color: $gray-15;
      }

      &__attachments {
        position: absolute;
        bottom: 0;
      }
    }
    .template-type-title {
      font-weight: $font-weight-semibold;
      padding: 1rem 0;
      margin-left: 1.5rem;
      margin-bottom: 0.5rem;
      color: $gray-11;
      width: 8.75rem;
      border-bottom: 0.0625rem solid $gray-13;
      border-radius: 0.0625rem;
    }
  }
}

.merge-tags-dropdown {
  width: 11.875rem;

  .transfer-container {
    min-width: 11.875rem;
    width: 11.875rem;
    .transfer-body > .transfer-content {
      max-height: 13.5rem;
    }
  }
}

.toolbar-extra-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  padding: 0px 0.25rem 0px 0.25rem;
  border-left: 0.0625rem solid rgb(217, 217, 217);

  &:nth-child(3) {
    border-left: none;
  }

  .toolbar-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 1.5rem;

    padding: 0rem 0.25rem;
    border-radius: 0.1875rem;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: $gray-13;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

//* Create Step Modal
.create-step-modal {
  .modal-dialog {
    max-width: 53.0625rem;
  }
  .modal-content {
    max-height: 92vh;

    @media only screen and (min-width: 1920px) {
      transform: scale(1.15);
    }
  }
  .modal-header {
    border-bottom: 0px;
    padding-left: 0px;
    .close {
      padding: 1rem 0.6875rem;
      i {
        font-size: 1.5rem;
      }
    }
    .step-info-wrapper-secondary {
      width: 5.625rem;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    .step-info-wrapper {
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
      height: 2.25rem;
      background-color: $gray-16;
      position: absolute;
      border-radius: 0.25rem;
      .step-variant {
        margin-left: 1.125rem;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 0.25rem;
        background-color: $gray-1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .channels {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
      margin: 0 auto;
      padding-left: 4rem;
      .channel {
        border-radius: 4.632px;
        outline: 1px solid $gray-16;
        background-color: $gray-19;
        width: 2.75rem;
        height: 2.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          outline: 1px solid $gray-13;
        }
      }
      .channel-disable {
        cursor: not-allowed !important;
      }
    }
  }
  .modal-body {
    height: 35rem;
    padding: 1.5rem 0px 1.25rem 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;

    .channel-sub-types {
      padding: 0px 1.5rem;
      &__container {
        display: flex;
        justify-content: space-between;
        gap: 0.75rem;
      }
      &__type-secondary {
        cursor: pointer;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-grow: 1;
        max-width: 12.4375rem;
        border: 1px solid $gray-14;
        border-radius: 0.25rem;
        &:hover {
          border-color: $gray-11;
        }
      }
      &__type {
        cursor: pointer;
        padding: 0.5rem 2rem;
        border-radius: 0.25rem;
        border: 1px solid $gray-14;
        &:hover {
          border-color: $gray-11;
        }
      }
      &__active {
        border-color: $blue-11 !important;
        background-color: $blue-17;
        span {
          color: $blue-11;
        }
      }
      &__disable {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .create-step-input-box {
      background-color: $gray-19;
      height: 2.5rem;

      input {
        background-color: $gray-19;
        color: $popover-arrow-color;
        font-size: 0.875rem;
        &::placeholder {
          color: $gray-11;
          font-size: 0.875rem;
        }
      }
      .bs-input-label-suffix {
        color: $red-12;
        font-size: 0.75rem;
        font-weight: $font-weight-normal;
      }
    }
    .content-area {
      height: 15.25rem;
      width: 100%;
      overflow: hidden;
      border-radius: 4px;
      border: 1px solid #d1d5db;
      background-color: #f9fafb;
      padding: 0.75rem 1rem 1rem 1rem;

      &:hover {
        border-color: $blue-5;
        border-right-width: 1px !important;
      }
      &:focus-within {
        border-color: $blue-5;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: $input-shadow;
      }

      &--sm {
        height: 9.375rem;
        .content-area__input {
          height: 86%;
        }
      }
      &--md {
        height: 17.875rem;
      }
      &--lg {
        height: 17.375rem;
      }
      &__input {
        border-width: 0px;
        background-color: #f9fafb;
        height: 92%;
        width: 100%;
        font-size: 0.875rem;
        font-weight: 400;
        color: $popover-arrow-color;
        resize: none;
        &::placeholder {
          color: $gray-11;
        }
        &:focus {
          outline: none;
        }
      }
      .expand {
        right: 0.375rem;
        top: 0.3125rem;
        z-index: 1;
      }
    }
    .content-area--error {
      border-color: $red-20;
    }
    .task-priority {
      &__items {
        display: flex;
        gap: 0.5rem;
        margin-top: 0.9375rem;
      }
      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        border: 1px solid $gray-23;
        padding: 0.25rem 1rem;
        color: $popover-arrow-color;
        &:hover {
          border-color: $gray-11;
        }
      }
      .urgent {
        border: 1px solid $red-18;
        background-color: #fef2f2;
        color: #dc2626;
      }
      .high {
        border: 1px solid $orange-14;
        background-color: #fffbeb;
        color: #d97706;
      }
      .normal {
        border: 1px solid $blue-11;
        background-color: #eff6ff;
        color: #1d4ed8;
      }
      .low {
        border: 1px solid #0891b2;
        background-color: #ecfeff;
        color: #0891b2;
      }
    }
    .custom-button {
      &__input {
        width: 23.75rem !important;
        background-color: $gray-19;
        input {
          background-color: $gray-19;
          color: $popover-arrow-color;
        }
        &::placeholder {
          color: $gray-11;
          font-size: 0.875rem;
        }
      }
    }
    .task-assignee {
      width: 23.75rem;
      &__select {
        margin-top: 0.9375rem;
        .bs-select-box {
          height: 2.125rem;
          &-text {
            color: $popover-arrow-color;
          }
          border-radius: 4px;
          border: 1px solid #d1d5db;
          background: #f9fafb;
        }
      }
    }
  }
  .modal-footer > * {
    margin: 0rem !important;
  }
  .modal-footer {
    min-height: 4rem;
    .day-input-spinner {
      display: flex;
      align-items: center;
      label {
        margin: 0 0.75rem 0 0;
        color: $blue-15;
      }
      .day-number {
        min-width: 3.5rem;
        margin: 0 0.75rem 0 0;
        width: 3.5rem;
        height: 2rem;
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
          color: $blue-15;
        }
        .bs-input-number-input-wrap > input {
          width: 3.5rem;
          height: 2rem;
        }
      }
    }
    .btn-solid {
      min-width: 6.25rem;
      height: 2rem;
      border: none;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
      transition: all 0.2s ease;
      background-color: $blue-11;
      &:focus-visible {
        outline: none;
      }
      &:hover,
      &:active {
        background-color: #346aff !important;
        border-color: #346aff !important;
      }
    }
    .btn-solid-primary:disabled {
      border: none;
    }
  }
}
.task-note-modal {
  .modal-dialog {
    max-width: 53.0625rem;
  }

  .modal-content-container {
    max-height: inherit;
  }
  .modal-header {
    border-bottom: 0px;
    padding-left: 0px;
    min-height: 0px;
    padding-top: 1.5rem;
  }
  .modal-body {
    padding: 0rem 1.5rem 1.5rem 1.5rem !important;
    overflow-y: auto;
    height: 21rem;
    .content-area {
      height: auto;
      width: 100%;
      overflow: hidden;
      border-radius: 4px;
      border: 1px solid #d1d5db;
      background-color: #f9fafb;
      padding: 0.75rem 1rem 1rem 1rem;
      &__input {
        border-width: 0px;
        background-color: #f9fafb;
        height: 12.625rem;
        width: 100%;
        font-size: 0.875rem;
        font-weight: 400;
        color: $popover-arrow-color;
        resize: none;
        &:focus {
          outline: none;
        }
      }
      .expand {
        right: 0.375rem;
        top: 0.3125rem;
        z-index: 1;
      }
    }
    .content-area--error {
      border-color: $red-20;
    }
  }
  .modal-footer {
    .btn-solid {
      min-width: 6.25rem;
      height: 2rem;
      border: none;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
      transition: all 0.2s ease;
      background-color: $blue-11;
      &:focus-visible {
        outline: none;
      }
      &:hover,
      &:active {
        background-color: #346aff !important;
        border-color: #346aff !important;
      }
    }
    .btn-solid {
      background-color: $blue-11;
    }
    .btn-solid-primary:disabled {
      background-color: $blue-11;
      border: none;
    }
  }
}

.send-test-email-modal {
  &.confirm-modal-width {
    .modal-dialog {
      .modal-content {
        .bs-modal-body {
          padding: 1.875rem !important;
        }
      }
    }
  }
  &.save-changes-modal {
    .modal-dialog {
      width: 31rem !important;
    }
    .send-test-email-modal-content {
      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }
  .modal-dialog {
    width: 28.125rem !important;
    font-family: Inter;
    .modal-header {
      border-bottom: none;
      padding: 0px 2rem;
      position: relative;
      button.close {
        padding: 0;
        position: absolute;
        top: 0.9375rem;
        right: 1.875rem;
      }
    }
    .modal-footer {
      padding: 0;
      padding-top: 0.938rem;
      margin: 0 1.875rem 0.938rem 1.875rem;
      border-top: 0.125rem solid $gray-16;
      border-radius: 0.0625rem;
      .bs-modal-footer-action-buttons {
        margin: 0;
      }
    }
  }
  .send-test-email-modal-header {
    display: flex;
    padding-top: 1.5rem;
    padding-right: 0.3125rem;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
    h2 {
      font-weight: $font-weight-semibold;
      font-size: 1rem;
      color: #000000;
      margin: 0;
      margin-left: 0.9375rem;
    }
  }
  .send-test-email-modal-content {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $blue-15;
    margin-bottom: 0;
    &:first-of-type {
      margin-bottom: 2rem;
    }
  }
  .form-wrapper {
    .send-test-email-modal-label {
      font-weight: $font-weight-semibold;
      color: $gray-15;
      margin: 0;
      margin-bottom: 0.3125rem;
    }
    div.send-test-email-modal-input {
      background-color: transparent;
      border: none;
      span {
        background: $gray-19;
        border: 0.0625rem solid $blue-14;
        box-sizing: border-box;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        input {
          background-color: transparent;
        }
        &.bs-input-affix-wrapper.input-error {
          border: 1px solid $red-12;
        }
      }
      .bs-input-label-suffix {
        font-size: 12px;
        line-height: 20px;
        color: $red-12;
        margin-top: 0;
        img {
          margin-right: 5px;
          margin-top: -1px;
        }
      }
    }

    span.send-test-email-modal-input {
      background: $gray-19;
      border: 0.0625rem solid $blue-14;
      box-sizing: border-box;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      input {
        background-color: transparent;
      }
    }
  }
}

.show-activate-sequence-popover {
  z-index: 1071;
  margin-top: -5px !important;
  margin-left: 2px !important;
}

.sequence-step-email-list-dropdown {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 0;
    margin: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      transition: all 0.2s ease;
      font-size: 16px;
      color: $gray-11;
    }

    &:hover {
      i {
        color: $blue-15;
      }
    }
  }

  .dropdown-menu-custom {
    margin: 0;
    padding: 8px 0;

    a {
      color: $blue-16 !important;
      margin-right: 0;
      border-bottom-color: $gray-16;
      transition: all 0.2s ease;
      &:hover {
        background: $blue-17;
      }
      i {
        font-size: 1rem;
        color: $gray-15;
      }
      img {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.template-attachment-icon {
  font-size: 0.875rem;
  color: $gray-11;
  transition: all 0.2s ease;
  &:hover {
    color: $blue-15;
  }
}

.prospect--applied-filters {
  border-bottom: 1px solid rgb(209, 213, 219);
  padding: 1rem;
  .filter-tab-container {
    margin-bottom: 0;
  }
}

.pause-resume-report-modal {
  &--header {
    display: flex;
    align-items: center;
    gap: 1rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    span {
      font-weight: $font-weight-semibold;
      font-size: 1rem;
      line-height: 1.25rem;
      color: $blue-15;
    }
  }
  &--content {
    margin: 1.5rem 0;

    background: $gray-19;
    border: 0.0625rem solid $gray-16;
    border-radius: 0.25rem;

    .pause-resume-stat {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0.625rem 1rem;
      border-bottom: 0.0625rem solid $gray-16;

      &:last-of-type {
        border-bottom: none;
      }

      p {
        font-weight: $font-weight-medium;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $blue-15;
        margin: 0;

        &:last-of-type {
          font-weight: $font-weight-semibold;
        }

        display: flex;
        align-items: center;
        gap: 0.75rem;

        i {
          font-size: 1rem;
        }
      }
    }
  }

  &--footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-top: 1.25rem;

    border-top: 0.125rem solid $gray-16;
  }
}

.scheduled-sent-email {
  transition: all 0.2s ease;
  .mail-icon {
    margin-top: 1px;
    visibility: hidden;
  }

  &:hover {
    .mail-icon {
      visibility: visible;
    }
  }
}

.email-prospect-col {
  cursor: pointer;
}

.sequence-score {
  &--wrapper {
    padding: 1.5rem 4rem;
    background-color: #f9fafb;
    // max-height: calc(100vh - 121px);
    overflow-y: auto;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  &--content {
    width: 100%;
    background: $white;

    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding-top: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 1rem;

    .get-it-done {
      right: 0rem;
      top: 1.5rem;
    }
    .details-gauge-chart {
      width: 19.0625rem;
      height: 100%;
    }

    &--tabs {
      width: 100%;
      margin-top: 1rem !important;

      .bs-tabs-nav {
        height: 5.375rem !important;
        justify-content: center !important;
        box-shadow: none !important;

        .bs-tabs-nav-wrap {
          width: fit-content !important;
          z-index: 1 !important;

          .bs-tabs-nav-list {
            gap: 0px !important;
            grid-gap: 0px !important;
            display: flex;
            justify-content: center;
          }
        }

        .bs-tabs-tab {
          margin: 0 !important;
          padding: 1rem !important;
          border-width: 0.0625rem;
          border-style: solid;
          border-color: transparent;
          transition: all 0.2s ease-in-out;

          .bs-tabs-tab-btn {
            padding: 0 !important;
          }

          &.bs-tabs-tab-active {
            border-bottom: none;
            border-color: $gray-13;
            background: linear-gradient(180deg, $gray-19 -22.67%, $gray-1 100%);
          }

          &:hover {
            border-color: $gray-13;
            background: linear-gradient(180deg, $gray-19 -22.67%, $gray-1 100%);
          }
        }

        .bs-tabs-ink-bar {
          display: none !important;
        }
      }
    }

    &--title {
      height: 20px;

      font-style: normal;
      font-weight: $font-weight-semibold;
      font-size: $text-16;
      line-height: 1.25rem;
      color: $blue-15;

      margin: 0;

      span {
        &.poor {
          color: $red-18;
        }

        &.average {
          color: $yellow-12;
        }

        &.excellent {
          color: $green-16;
        }
      }
    }

    &--description {
      max-width: 43.75rem;

      font-style: normal;
      font-weight: $font-weight-medium;
      font-size: $text-12;
      line-height: 1.125rem;
      color: $gray-15;
      text-align: center;
      margin: 0;
    }

    &--chart {
      p {
        margin: 0;
        margin-top: 0.5rem;
        font-weight: $font-weight-medium;
        font-size: 0.75rem;
        line-height: 1.125rem;
        text-align: center;
        color: $gray-15;
      }
    }

    .sequence-score-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;

      &--progress {
        width: 3.375rem;
        height: 3.375rem;
        border-radius: 50%;
        background-color: transparent;
        position: relative;
        .circular-progressbar-text {
          line-height: 1.75rem;
        }
        &.poor {
          background-color: rgba(254, 242, 242, 0.5);
          svg {
            color: $red-18;
          }
        }
        &.average {
          background-color: rgba(255, 251, 235, 0.5);
          svg {
            color: $yellow-12;
          }
        }
        &.excellent {
          background-color: rgba(236, 253, 245, 0.5);
          svg {
            color: $green-16;
          }
        }
      }

      &--meta {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
      }

      &--title {
        font-weight: $font-weight-medium;
        font-size: $text-14;
        line-height: 1.25rem;
        color: $blue-15;
        margin: 0;
      }

      &--info {
        color: $gray-15;
        transition: all 0.2s ease;
        &:hover {
          color: $blue-15;
        }
      }
    }
  }
}
.score-apex-chart {
  position: relative;

  .apexcharts-radialbar-hollow {
    display: none;
  }

  span.category {
    font-style: italic;
    font-weight: $font-weight-semibold;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
  }

  .markers {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 0.25rem;
    width: 18px;
    height: 13.8208px;

    .marker-dash {
      width: 2px;
      height: 4px;

      background: #e5e7eb;
      border-radius: 7px;
    }

    span {
      font-weight: $font-weight-semibold;
      font-size: 0.625rem;
      line-height: 0.75rem;
      display: flex;
      align-items: center;
      text-align: center;
      color: $gray-23;
    }

    &.marker-zero {
      position: absolute;
      left: 3.625rem;
      top: 9.375rem;
    }

    &.marker-twenty-five {
      position: absolute;
      left: 3.875rem;
      top: 3.3125rem;

      .marker-dash {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: rotate(122deg);
      }
    }

    &.marker-fifty {
      width: 0.8125rem;
      height: 1.0625rem;

      position: absolute;
      left: 9.375rem;
      top: 0.75rem;

      .marker-dash {
        position: absolute;
        right: 5;
        bottom: 0;
      }
    }

    &.marker-seventy-five {
      width: 1.1531rem;
      height: 0.8013rem;
      justify-content: flex-end;

      position: absolute;
      right: 54px;
      top: 64px;

      .marker-dash {
        position: absolute;
        left: 0;
        bottom: 1px;

        transform: rotate(-122deg);
      }
    }

    &.marker-hundred {
      position: absolute;
      right: 3.125rem;
      top: 9.25rem;
    }
  }
}

.sequence-score-tab-tooltip {
  .tooltip-inner {
    max-width: 15.875rem !important;
  }
}

.empty-sequence-score {
  gap: 2rem;

  &--content {
    h1 {
      font-style: normal;
      font-weight: $font-weight-semibold;
      font-size: 0.875rem;
      line-height: 1.5rem;
      text-align: center;
      color: $blue-15;
      margin-bottom: 0.5rem;
    }

    p {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: $gray-15;
      margin-bottom: 1.5rem;
    }
  }
}

.sequence-score-loading {
  &--container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;

    padding: 1.5rem 4rem;
    background-color: #f9fafb;

    height: calc(100vh - 121px);

    overflow: hidden auto;
  }

  &--sequence-score {
    background: $white;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2.125rem;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
  }

  &--top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    padding: 1.5rem;

    border-bottom: 1px solid #e5e7eb;
  }

  &--divider {
    width: 2px;
    height: 154px;

    background: #e5e7eb;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
  }

  &--bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
  }

  &--sequence-score-tabs {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    border-bottom: 1px solid #e5e7eb;
  }

  &--sequence-score-tab {
    &:first-of-type {
      position: relative;
      border-width: 1px 1px 0px 1px;
      border-style: solid;
      border-color: #e5e7eb;
      margin-bottom: -1px;
      background-color: $white;
      z-index: 1;
    }
    padding: 1rem;
  }

  &--sequence-score-tab-progress {
    position: relative;

    .inner-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      margin-top: 0.0938rem;
      z-index: 1;

      width: 2.625rem;
      height: 2.625rem;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $white;
    }
  }

  &--sequence-score-factors {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: calc(100% - 3rem);
    margin: 1.5rem;
    margin-bottom: 0;
    padding: 1.5rem;
    padding-bottom: 0;

    background: $white;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #f3f4f6;
    border-radius: 4px 4px 0px 0px;
  }

  &--sequence-score-factor {
    .content {
      display: flex;
      gap: 0.75rem;
    }
  }
}

.sequence-score-cell {
  button {
    padding: 0 !important;
  }
  span.no-data {
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $blue-15;
  }
  .sequence-score-badge {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.125rem 0.5rem;

    width: 2rem;
    height: 1.5rem;

    border: 1px solid transparent;
    border-radius: 20px;

    span {
      font-weight: $font-weight-semibold;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    &.poor {
      background: $red-17;
      border-color: $red-21;
      span {
        color: $red-21;
      }
    }
    &.average {
      background: $yellow-11;
      border-color: $yellow-13;
      span {
        color: $yellow-13;
      }
    }
    &.excellent {
      background: $green-21;
      border-color: $green-13;
      span {
        color: $green-13;
      }
    }
  }
  .check-score span {
    text-align: left;
  }
}

.sequence-client-associated-name {
  color: $blue-15;
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: 18px;
  width: 175px;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sequence-client-associated-tooltip {
  .arrow {
    position: absolute !important;
    left: 0px !important;
    transform: translate(11px, 0px) !important;
  }
}

.number-type-cell {
  span {
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $blue-15;
  }
}

.sequence-score-error {
  gap: 2rem;
  padding: 3rem 1.5rem;
  background-color: $white;
  border-radius: 4px;

  &--content {
    h1 {
      font-style: normal;
      font-weight: $font-weight-semibold;
      font-size: 0.875rem;
      line-height: 1.5rem;
      text-align: center;
      color: $blue-15;
      margin-bottom: 0.5rem;
    }

    p {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: $gray-15;
      margin-bottom: 1.5rem;
    }
  }
}

.sequence-steps-skeleton {
  &__steps {
    margin-left: 5.6281rem;
  }
}

.scroll-to-top {
  align-self: flex-end;
  width: 6.625rem;
  height: 1.25rem;
  padding: 0;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.sequence-owner-label {
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.merge-tag {
  height: 1.875rem;
  width: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: $gray-15;
  }
  &:hover {
    background-color: $blue-13;
    svg {
      color: $blue-11;
    }
  }
}
.task-assignee__select-dropdown {
  .transfer-container .transfer-body .transfer-content ul {
    li {
      padding: 0.375rem 1rem;
      &:hover {
        background-color: $blue-17;
      }
    }
  }
}
.pause-resume-sequence-title {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  .pause-resume-sequence-title-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
      color: $orange-14;
    }
  }
  h1 {
    font-weight: $font-weight-semibold;
  }
}

.w-100px {
  width: 6.25rem;
}
.bounced-email-schedule-sent-tab {
  display: flex;
  position: relative;
  .bounced-reason-question-rounded {
    position: absolute;
    left: 3.6875rem;
    top: 0.125rem;
  }
  .hard-bounced-reason-question-rounded {
    position: absolute;
    left: 5.875rem;
    top: 0.125rem;
  }
  .block-bounced-reason-question-rounded {
    position: absolute;
    left: 6.1875rem;
    top: 0.125rem;
  }
  .failed-reason-question-rounded {
    position: absolute;
    left: 2.4375rem;
    top: 0rem;
  }
}

.failed-status {
  color: $orange-21;
  font-weight: $font-weight-normal;
  line-height: 1.25rem;
  font-size: 0.875rem;
}

.hard-bounced-status {
  color: $red-21;
  font-weight: $font-weight-normal;
  line-height: 1.25rem;
  font-size: 0.875rem;
}

.block-bounced-status {
  color: $red-15;
  font-weight: $font-weight-normal;
  line-height: 1.25rem;
  font-size: 0.875rem;
}

.help-icon {
  color: $orange-21;
  height: 1.25rem;
}

.hard-bounced-icon {
  color: $red-21;
}

.block-bounced-icon {
  color: $red-15;
}

.resend-pill {
  position: absolute;
  left: 4.25rem;
  top: 0.0625rem;
  background-color: $white;
  border-radius: 0.125rem;
  border: 0.0625rem solid $gray-13;
  gap: 0.375rem;
  padding: 0.125rem 0.25rem;
  cursor: pointer;
  .pills-label {
    color: $blue-15;
    font-size: 0.75rem;
  }
}

.block-resend-pill {
  left: 8rem;
}

.block-resend-pill {
  left: 8rem;
}

.info-circle-schedule {
  width: 0.875rem;
  height: 0.875rem;
}

.filter-icon-sequence {
  width: 1.5rem;
  height: 1.5rem;
}

.table-header {
  justify-content: center !important;
}

.icon-cross {
  position: absolute;
  right: 1rem;
  color: $gray-15;
}

.divider-vertical-alert {
  height: 1.25rem;
  width: 0.063rem;
  background-color: $gray-11;
  align-self: center;
  margin: 0 0.5rem;
}

.email-setting {
  margin-top: 0.5rem;
}

.a-link {
  color: $black-1 !important;
  text-decoration: underline;
  text-underline-offset: 0.125rem;
}

.alert-cross {
  border: none;
  background-color: $yellow-11;
  color: $gray-15;
}

.sequence-setting-radio-buttons-container {
  display: flex;
  width: 674px;
  height: 139.822px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.stage-dropdown {
  .stage-indicator-wrapper {
    .stage-indicator {
      border-radius: 0.25rem;
      margin-right: 0.625rem;
      width: 0.1875rem;
      height: 1.5rem;
      flex-shrink: 0;
      background: $red-15;
    }
  }
}

.bounce-summary-dropdown {
  border: none;
  background: $gray-16;
  width: 1rem;
  height: 1rem;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.125rem;
  .bounce-icon-wrapper {
    color: $gray-15;
    margin-top: 0.1rem;
    width: 0.75rem;
    height: 0.75rem;
  }
}

.bounce-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 0.5rem;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  .bounce-indicator-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    .bounce-indicator {
      border-radius: 0.25rem;
      margin-right: 0.625rem;
      width: 0.1875rem;
      height: 1.5rem;
      flex-shrink: 0;
      background: $red-12;
      &.hard {
        background: $red-21;
      }
      &.block {
        background: $red-15;
      }
    }

    .bounce-name {
      display: flex;
      align-items: center;
      flex-grow: 1;
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: $font-weight-normal;
      line-height: 1.25rem;
      gap: 0.25rem;
    }

    .bounce-info-icon {
      width: 0.875rem;
      height: 0.875rem;
      color: $gray-11;
      cursor: pointer;
    }
  }

  .bounce-count-wrapper {
    display: flex;
    align-items: center;
    width: 25%;
    .bounce-count {
      margin-right: 0.5rem;
      color: $blue-15;
      font-size: 1rem;
      font-weight: $font-weight-semibold;
      line-height: 1.125rem;
    }

    .bounce-percentage {
      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-normal;
      line-height: 1.125rem;
      margin-top: 0.125rem;
    }
  }
}

.bounce-transfer {
  display: inline-flex;
  padding: 0 !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.5rem !important;
  border: 0.0625rem solid $gray-13 !important;
  background: $white-text;
  box-shadow: 0px 16px 32px -4px rgba(0, 0, 0, 0.1),
    0px 2px 4px 0px rgba(0, 0, 0, 0.04) !important;
  max-height: 10.25rem;
  max-width: 25rem;
  min-width: 15rem;
  overflow-x: hidden;

  .popover-body {
    width: 100%;
    .bs-popover-inner-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.75rem;
      width: 15rem;
      padding: 1rem;
    }
  }
  &.soft-bounced-dropdown {
    .popover-body {
      .bs-popover-inner-content {
        height: 8rem;
      }
    }
  }

  &.hard-block-bounced-dropdown {
    .popover-body {
      .bs-popover-inner-content {
        height: 5.75rem;
      }
    }
  }
}
.ai-variant-error-modal {
  .modal-content {
    top: 38% !important;
    .modal-content-container {
      .modal-header {
        padding-top: 1.5rem !important;
      }
    }

    .bs-modal-footer {
      .btn-ghost {
        padding: 0.375rem 1.625rem !important;
        line-height: normal !important;
      }
    }
  }
}

.create-variant-btn {
  & .tooltip-inner {
    white-space: pre-line;
  }
}

.sequence-progress-col-tooltip {
  padding: none !important;
  .bs-popover-inner-content {
    padding: 0.5rem;
  }
}

.sequence-progress-col {
  gap: 0.25rem;
  align-items: center;
  .info-circle-icon {
    width: 0.875rem;
    height: 0.875rem;
    color: $gray-15;
    cursor: pointer;
    &:hover {
      color: $blue-11;
    }
    &.active {
      color: $blue-11;
    }
  }
}

.sequence-progress-col-tooltip {
  padding: 0 !important;
  border-radius: 0.25rem !important;
  border: 0.0625rem solid $gray-16 !important;
  background: $white !important;

  .bs-popover-inner-content {
    padding: 0.5rem !important;
    width: 258px;
    height: 84px;

    display: flex;
    justify-content: center;
    gap: 0.375rem;

    .inner-container {
      gap: 8px;
      .cross-icon-sequence {
        color: $blue-15;
        margin-bottom: 0.3125rem;
        cursor: pointer;
        outline: none;
      }
    }

    div.content {
      width: 218px;
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1.125rem;
      a {
        color: $gray-11;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    div.icon {
      width: 1rem;
      height: 1rem;
      svg {
        color: $gray-11;
      }
    }
  }
}

.sequence-progress-cell {
  cursor: pointer;
  &:hover {
    background: $gray-16 !important;
  }
  .sequence-progress-col-cell {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .contacted-value {
    color: #1f2927;
    font-size: 14px;
    font-weight: $font-weight-medium;
    line-height: 20px;
  }
  .total-value {
    color: $gray-11;
    font-size: 12px;
    font-weight: $font-weight-medium;
    line-height: 16px;
  }
  .progress-bar-count {
    margin-top: 2px;
  }
}

.prospect-col-header-icon {
  color: $purple-15;
  gap: 0.5rem;
  align-items: center;
}

.prospect-col-cell {
  cursor: pointer;
  &:hover {
    background: $gray-16 !important;
  }
  .count {
    color: $blue-15;
    font-size: 1rem;
    font-weight: $font-weight-semibold;
    line-height: 20px;
    border-bottom: 0.0625rem dashed $gray-11;
    &.no-data {
      border-bottom: none;
    }
  }
  &:hover .arrow-up-right-icon {
    opacity: 0.5;
  }
  .arrow-up-right-icon {
    color: $gray-15 !important;
    opacity: 0;
    &:hover {
      background-color: $white;
      opacity: 1;
      border-radius: 0.125rem;
    }
  }
}

.contacted-col-header-icon {
  color: $blue-23;
  gap: 0.5rem;
  align-items: center;
}
.opened-col-header-icon {
  color: $indigo-5;
  gap: 0.5rem;
}
.replied-col-header-icon {
  color: #06b6d4;
  gap: 0.5rem;
}
.positive-col-header-icon {
  color: $green-23;
  gap: 0.5rem;
}

.opened-col-cell {
  color: $blue-15;
  font-weight: $font-weight-medium;
  line-height: 1.25rem;
  font-size: 14px;
  .total-opened-count {
    font-weight: $font-weight-semibold;
    font-size: 16px;
  }
}

.replied-positive-col-cell {
  cursor: pointer;
  color: $blue-15;
  font-weight: $font-weight-medium;
  line-height: 1.25rem;
  font-size: 14px;
  &:hover {
    background: $gray-16 !important;
    color: $blue-11;
  }
  .total-replied-positive-count {
    font-weight: $font-weight-semibold;
    font-size: 16px;
  }
}

.email-accounts-col-cell {
  color: $blue-15;
  font-weight: $font-weight-semibold;
  font-size: 1rem;
  line-height: 1.25rem;
  cursor: pointer;
  &:hover {
    background: $gray-16 !important;
  }
}

.contacted-col-cell {
  color: $blue-15;
  font-weight: $font-weight-semibold;
  font-size: 1rem;
  line-height: 1.25rem;
}

.sequence-switch-header-col {
  position: sticky;
  left: 3.5625rem;
  z-index: 3;
  background-color: $white;
  top: 0;
}

.sequence-disabled-cell {
  cursor: not-allowed;
}

.sequence-action {
  .action-btn {
    width: 2.125rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;

    svg {
      width: 1.125rem;
      height: 1.125rem;
      color: $gray-15;
    }

    &:hover {
      background: $blue-13;

      svg {
        color: $blue-11;
      }
    }
  }

  .show > .show {
    display: block !important;
  }
  .dropdown-custom > .dropdown-menu-custom {
    display: none;
  }

  .dropdown-custom {
    .dropdown-toggle-custom {
      width: 2.125rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      padding: 0;
      border: none;

      svg {
        width: 1.125rem;
        height: 1.125rem;
        color: $gray-15;
      }

      &:hover {
        background: $blue-17;
      }
    }
    .dropdown-menu-custom {
      // display: flex;
      flex-direction: column;
      align-items: flex-start;

      height: auto;
      padding: 0.5rem 0px;

      border-radius: 0.25rem;
      border: 0.0625rem solid $gray-13;
      background: $white-text;
      box-shadow: 8px 16px 56px -24px rgba(156, 157, 161, 0.24),
        2px 2px 16px -3px rgba(4, 21, 76, 0.16);

      a {
        display: flex;
        align-self: stretch;
        align-items: center;
        gap: 0.5rem;
        padding: 0.4375rem 1rem;
        height: 2.125rem;

        color: $blue-16;
        font-size: 0.875rem;
        line-height: 1.25rem;

        svg {
          color: $gray-15;
        }

        &:hover {
          background: $blue-17;
        }
      }
    }
  }
}

.sequence-action-dropdown {
  .dropdown-menu-custom {
    padding: 0.5rem 0 !important;
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 7px 16px !important;
    color: $blue-16;
    &:hover {
      background-color: $blue-17;
    }
  }
  .pencil-icon {
    width: 1rem;
    height: 1rem;
    color: $gray-15;
  }
}

.waiting-status-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 4px 24px;
  .shd-alert--icon {
    display: flex;
    align-items: center;
    color: $orange-14;
  }
  .shd-alert--text {
    .text {
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      line-height: 1rem;
    }
    .sub-text {
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1rem;
    }
    .a-link-waiting {
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1rem;
      color: $blue-11;
      &:hover {
        text-decoration: underline;
      }
      a {
        color: $blue-11;
      }
    }
    .shd-alert--description {
      .text-cross-icon {
        width: 212%;
      }
    }
    .waiting-status-banner-close {
      outline: none;
      color: $gray-15;
    }
  }
}

.waiting-status-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 4px 24px;
  .shd-alert--icon {
    display: flex;
    align-items: center;
    color: $orange-14;
  }
  .shd-alert--text {
    .text {
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      line-height: 1rem;
    }
    .sub-text {
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1rem;
    }
    .a-link-waiting {
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1rem;
      color: $blue-11;
      &:hover {
        text-decoration: underline;
      }
      a {
        color: $blue-11;
      }
    }
    .shd-alert--description {
      .text-cross-icon {
        width: 212%;
      }
    }
    .waiting-status-banner-close {
      outline: none;
      color: $gray-15;
    }
  }
}
.sequence-progress-col-tooltip {
  padding: none !important;
  .bs-popover-inner-content {
    padding: 0.5rem;
  }
}

.sequence-progress-col {
  gap: 0.25rem;
  align-items: center;
  .info-circle-icon {
    width: 0.875rem;
    height: 0.875rem;
    color: $gray-15;
    cursor: pointer;
    &:hover {
      color: $blue-11;
    }
    &.active {
      color: $blue-11;
    }
  }
}

.sequence-progress-col-tooltip {
  padding: 0 !important;
  border-radius: 0.25rem !important;
  border: 0.0625rem solid $gray-16 !important;
  background: $white !important;

  .bs-popover-inner-content {
    padding: 0.5rem !important;
    width: 258px;
    height: 84px;

    display: flex;
    justify-content: center;
    gap: 0.375rem;

    .inner-container {
      gap: 8px;
      .cross-icon-sequence {
        color: $blue-15;
        margin-bottom: 0.3125rem;
        cursor: pointer;
        outline: none;
      }
    }

    div.content {
      width: 218px;
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1.125rem;
      a {
        color: $gray-11;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    div.icon {
      width: 1rem;
      height: 1rem;
      svg {
        color: $gray-11;
      }
    }
  }
}

.sequence-progress-cell {
  cursor: pointer;
  &:hover {
    background: $gray-16 !important;
  }
  .sequence-progress-col-cell {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .contacted-value {
    color: #1f2927;
    font-size: 14px;
    font-weight: $font-weight-medium;
    line-height: 20px;
  }
  .total-value {
    color: $gray-11;
    font-size: 12px;
    font-weight: $font-weight-medium;
    line-height: 16px;
  }
  .progress-bar-count {
    margin-top: 2px;
  }
}

.prospect-col-header-icon {
  color: $purple-15;
  gap: 0.5rem;
  align-items: center;
}

.prospect-col-cell {
  cursor: pointer;
  &:hover {
    background: $gray-16 !important;
  }
  .count {
    color: $blue-15;
    font-size: 1rem;
    font-weight: $font-weight-semibold;
    line-height: 20px;
    border-bottom: 0.0625rem dashed $gray-11;
    &.no-data {
      border-bottom: none;
    }
  }
  &:hover .arrow-up-right-icon {
    opacity: 0.5;
  }
  .arrow-up-right-icon {
    color: $gray-15 !important;
    opacity: 0;
    &:hover {
      background-color: $white;
      opacity: 1;
      border-radius: 0.125rem;
    }
  }
}

.contacted-col-header-icon {
  color: $blue-23;
  gap: 0.5rem;
  align-items: center;
}
.opened-col-header-icon {
  color: $indigo-5;
  gap: 0.5rem;
}
.replied-col-header-icon {
  color: #06b6d4;
  gap: 0.5rem;
}
.positive-col-header-icon {
  color: $green-23;
  gap: 0.5rem;
}

.opened-col-cell {
  color: $blue-15;
  font-weight: $font-weight-medium;
  line-height: 1.25rem;
  font-size: 14px;
  .total-opened-count {
    font-weight: $font-weight-semibold;
    font-size: 16px;
  }
}

.replied-positive-col-cell {
  cursor: pointer;
  color: $blue-15;
  font-weight: $font-weight-medium;
  line-height: 1.25rem;
  font-size: 14px;
  &:hover {
    background: $gray-16 !important;
    color: $blue-11;
  }
  .total-replied-positive-count {
    font-weight: $font-weight-semibold;
    font-size: 16px;
  }
}

.email-accounts-col-cell {
  color: $blue-15;
  font-weight: $font-weight-semibold;
  font-size: 1rem;
  line-height: 1.25rem;
  cursor: pointer;
  &:hover {
    background: $gray-16 !important;
  }
}

.contacted-col-cell {
  color: $blue-15;
  font-weight: $font-weight-semibold;
  font-size: 1rem;
  line-height: 1.25rem;
}

.sequence-switch-header-col {
  position: sticky;
  left: 3.5625rem;
  z-index: 3;
  background-color: $white;
  top: 0;
}

.sequence-disabled-cell {
  cursor: not-allowed;
}

.sequence-action {
  .action-btn {
    width: 2.125rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;

    svg {
      width: 1.125rem;
      height: 1.125rem;
      color: $gray-15;
    }

    &:hover {
      background: $blue-13;

      svg {
        color: $blue-11;
      }
    }
  }

  .show > .show {
    display: block !important;
  }
  .dropdown-custom > .dropdown-menu-custom {
    display: none;
  }

  .dropdown-custom {
    .dropdown-toggle-custom {
      width: 2.125rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      padding: 0;
      border: none;

      svg {
        width: 1.125rem;
        height: 1.125rem;
        color: $gray-15;
      }

      &:hover {
        background: $blue-17;
      }
    }
    .dropdown-menu-custom {
      // display: flex;
      flex-direction: column;
      align-items: flex-start;

      height: auto;
      padding: 0.5rem 0px;

      border-radius: 0.25rem;
      border: 0.0625rem solid $gray-13;
      background: $white-text;
      box-shadow: 8px 16px 56px -24px rgba(156, 157, 161, 0.24),
        2px 2px 16px -3px rgba(4, 21, 76, 0.16);

      a {
        display: flex;
        align-self: stretch;
        align-items: center;
        gap: 0.5rem;
        padding: 0.4375rem 1rem;
        height: 2.125rem;

        color: $blue-16;
        font-size: 0.875rem;
        line-height: 1.25rem;

        svg {
          color: $gray-15;
        }

        &:hover {
          background: $blue-17;
        }
      }
    }
  }
}

.sequence-action-dropdown {
  .dropdown-menu-custom {
    padding: 0.5rem 0 !important;
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 7px 16px !important;
    color: $blue-16;
    &:hover {
      background-color: $blue-17;
    }
  }
  .pencil-icon {
    width: 1rem;
    height: 1rem;
    color: $gray-15;
  }
}

// *. Sequence Settings ///////////////////////////////////
@import './components/sequence-single-content/components/sequence-single-settings/components/sequence-single-settings-content/settings-content';
// *. Sequence Settings ///////////////////////////////////

.sequence-progress-overlay-container,
.email-accounts-states-overlay-container {
  & .arrow {
    display: none;
  }
  &.bs-popover-inner {
    padding: 0px;
  }
  & .bs-popover-content {
    & .bs-popover-inner-content {
      padding: 0px;
      width: 0px;
    }
  }
}

.email-accounts-states-overlay-container {
  max-width: 414px;
  height: 0px;
}

.prospect-popover {
  & .arrow {
    display: none;
  }
  &.bs-popover-inner {
    padding: 0px;
  }
  & .bs-popover-content {
    & .bs-popover-inner-content {
      padding: 0px;
    }
  }
  & .progress-container {
    width: 14.5rem;

    & .progress-single-tab {
      width: 12.5rem;
    }
  }
}

.count-percentage {
  display: inline-flex;
  align-items: baseline;
  gap: 0.25rem;
}

.prospect-skeleton-container {
  transform: translateY(10px);
  display: inline-flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid $gray-13;
  background: $white-text;
  box-shadow: 3px 8px 24px -6px rgba(15, 34, 67, 0.16);
  position: absolute;
  z-index: 10;
  max-width: 14.5rem;

  & .prospect-skeleton-card {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 12.5rem;
    height: 1.5625rem;
  }
}

.progress-container {
  &.sequence-progress-container {
    max-height: 21rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
  }
}
.sequence-steps-container {
  transform: translateY(10px);
  &.progress-container {
    max-height: 14.25rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
  }
}
.client-sequence-action-header {
  min-width: 0px !important;
}
