$sidebarBgColor: var(--sidebarBgColor);
$navItemTextColor: var(--navItemTextColor);
$navItemHoverBgColor: var(--navItemHoverBgColor);
$navItemActiveBgColor: var(--navItemActiveBgColor);

.sidenav {
  background-color: $gray-16;
  border-right: 1px solid $gray-13;
  position: relative;
  width: 12.125rem;
  height: 100vh;

  transition: all 0.2s ease-in-out;

  & + .home-container {
    width: calc(100% - 12.125rem);
    transition: all 0.2s ease-in-out;
    background-color: $gray-19;
  }

  &--collapse {
    width: 3.875rem;
    z-index: 11;
    & + .home-container {
      width: calc(100% - 3.875rem);
      display: flex;
      flex-direction: column;
      transition: all 0.2s ease-in-out;
    }

    .sidenav__items {
      gap: 0.5rem;
      padding-top: 5px;
    }

    .sidenav__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.375rem 0.5rem;
      color: $gray-12;
      cursor: pointer;
      position: relative;
      border-radius: 0.5rem;

      width: 2.25rem;
      height: 2.25rem;
      justify-content: center;
      position: relative;
      right: 0rem;
    }

    .sidenav__text {
      display: none;
    }

    .sidenav__expand-btn {
      svg {
        margin-left: 1px;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 3.875rem;
    padding: 1rem;

    position: relative;
  }

  &__expand-btn {
    width: 1.125rem;
    height: 1.125rem;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $white;

    border-radius: 50%;
    background-color: $blue-11;
    cursor: pointer;

    position: absolute;
    right: -9px;
    bottom: -9px;

    z-index: 666;

    svg {
      margin-left: -1px;
    }
  }

  &__logo-text {
    margin-right: 0.5rem;
  }

  &__items {
    width: 100%;
    margin: 0;
    padding: 1rem 0.75rem;

    list-style-type: none;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

    a {
      width: 100%;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem 0.5rem;
    color: $gray-12;
    cursor: pointer;
    position: relative;
    border-radius: 0.5rem;
    border: 0.063rem solid transparent;
    width: 2.25rem;
    height: 2.25rem;
    justify-content: center;
    position: relative;
    right: 0.25rem;

    &:hover {
      border-radius: 0.5rem;
      border: 1px solid $gray-23;
      background-color: $gray-1;
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      justify-content: center;
      position: relative;
      .sidenav__icon {
        color: $gray-10;
      }
      .sidenav__dot-badge {
        top: 2px;
        right: 3px;
      }
      .sidenav__badge {
        top: -3px;
        right: -3px;
      }
    }

    &.active {
      background: linear-gradient(46deg, #2563eb 17.53%, #3b82f6 96.95%);
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      justify-content: center;
      position: relative;
      right: 0rem;
      padding: 0.375rem 0.5rem;
      border-radius: 0.5rem;
      background-repeat: no-repeat;
      border: 0px solid transparent;

      &:hover {
        border: none;
        outline: none;
      }
      &__text {
        font-weight: $font-weight-semibold;
      }
      .sidenav__icon {
        color: $gray-1;
      }
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    color: $gray-12;
  }

  &__text {
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    line-height: 1.25rem;

    animation-name: displayText;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &__item-tooltip {
    margin-left: 0rem;

    .arrow {
      display: none;
    }
    .tooltip-inner {
      height: 1.875rem;
      padding: 0.375rem 0.75rem !important;

      font-weight: $font-weight-semibold !important;
      line-height: 1.125rem;

      color: $gray-1;
    }
  }

  &__badge {
    height: 16px;

    padding: 1.5px 3px;

    background: $green-13;
    border-radius: 0.75rem;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: -2px;
    right: -2px;

    &.sm {
      width: 16px;
    }

    &.md {
      width: 24px;
    }

    &.lg {
      width: 28px;
      right: 1.5px;
    }

    &.sidebar-expanded {
      top: 12px;
      right: 8px;
    }

    span {
      font-weight: 600;
      font-size: 10px;
      line-height: 10px;
      text-align: center;
      color: $gray-1;
    }
  }

  &__dot-badge {
    position: absolute;
    top: 3px;
    right: 4px;

    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    background: $blue-24;

    &.sidebar-expanded {
      top: 1rem;
      right: 0.5rem;
    }
  }

  &__rect-text-badge {
    height: 0.875rem;
    width: 1.75rem;
    padding: 0.094rem 0.188rem;
    background: $yellow-13;
    border-radius: 0.188rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 1.063rem;

    span {
      font-weight: $font-weight-medium;
      font-size: 0.625rem;
      line-height: 0.625rem;
      text-align: center;
      color: $gray-1;
    }
  }

  &__bottom {
    position: absolute;
    right: 0rem;
    bottom: 0rem;
    z-index: -1;
    &__divider {
      border-bottom: 1px solid $gray-23;
      width: 3.125rem;
      position: relative;
      right: 0.4rem;
      top: 0.75rem;
    }
  }
}

.sidenav {
  &__item {
    border: 1px solid transparent;
  }
  &__item:hover .more-menu-wrapper {
    visibility: visible;
  }
  .more-menu-wrapper {
    left: 2.2rem;
    bottom: 0;
    z-index: 1;
    visibility: hidden;
    padding-left: 13px;
    .more-menu-dropdown {
      border: 1px solid $gray-23;
      border-radius: 0.5rem;
      width: 10.188rem;
      background-color: white;
      padding: 0.5rem 0 0.5rem 0;
    }
  }
  &__dropdown__item {
    height: 2rem !important;
    border: 0;
    .sidenav__text {
      font-size: 0.7rem;
      color: $blue-15;
      font-weight: $font-weight-normal;
    }
    .sidenav__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      width: 1.2rem;
      height: 1.2rem;
      color: $gray-12 !important;
    }
    &:hover {
      background-color: $blue-17;
      color: $blue-15;
      border: 0;
      border-radius: 0;
    }

    &.active {
      color: $white;
      background: $blue-17;
      color: $blue-15;
      border: 0;
      border-radius: 0;
      .sidenav__icon {
        color: $blue-15;
      }
    }
  }

  &__item:hover .email-menu-wrapper {
    visibility: visible;
  }

  .email-menu-wrapper {
    left: 2.2rem;
    top: 0;
    z-index: 1;
    visibility: hidden;
    padding-left: 0.8125rem;
    .more-menu-dropdown {
      border: 1px solid $gray-13;
      border-radius: 0.25rem;
      width: 9rem;
      background-color: white;
      padding: 0.5rem 0rem;

      .more-menu-dropdown-item {
        display: block;
        padding: 0.4375rem 1rem;
        text-decoration: none;
        color: $blue-16;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: $font-weight-normal;
        line-height: 1.25rem;
        text-wrap: nowrap;

        &:hover {
          background-color: $blue-17;
          color: $blue-15;
          border: 0;
          border-radius: 0;
        }
      }
    }
  }
}

.sidenav__hidden {
  display: none;

  & + .home-container-sidenav-hidden {
    width: 100%;
    transition: none !important;
  }
}

@keyframes displayText {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
