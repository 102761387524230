.status-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 20px 0px rgba(0, 13, 56, 0.12);
  border-radius: 0.25rem;

  & .status-inside-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .status-main-content {
      display: flex;
      padding: 0.5rem;
      align-items: flex-start;
      gap: 0.5rem;
      border-radius: 0.25rem;
      background: $white;
      width: 464px;

      & .status-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.75rem;
        width: 26.5rem;
        & .status-header-text {
          color: #1f2937;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 16px;
        }
        & .status-sequence-hover {
          display: flex;
          align-items: flex-start;
          gap: 1px;
          border-radius: 4px;
          background: $white;
          max-height: 10rem;
          overflow-y: auto;
          padding-right: 0.8125rem;

          & table {
            width: 424px;
            border: 1px solid $gray-16;
            border-collapse: separate;
            border-spacing: 0px;
            border-radius: 4px;
          }

          & .status-type {
            display: flex;
            width: 7.9375rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;
          }
          & .status-type-information {
            display: flex;
            width: 18.5rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;
          }
          & .status-title {
            border-right: 1px solid $gray-16;
            border-bottom: 1px solid $gray-16;
            padding: 0 16px;
            height: 40px;
          }
          & tr:last-child td:last-child {
            border-bottom: none;
          }
          & tr:last-child td:first-child {
            border-bottom: none;
          }

          & .status-description {
            border-bottom: 1px solid $gray-16;
            padding: 0px 16px;
            color: $gray-12;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: $font-weight-medium;
            line-height: 18px;
          }
        }
      }
    }
  }
}

.column-header-popover {
  &.bs-popover-inner {
    padding: 0px;
    width: 29rem;
  }
  & .bs-popover-content {
    width: 29rem;
    & .bs-popover-inner-content {
      padding: 0px;
      width: 29rem;
    }
  }
}
