.pricing-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  .card {
    width: 15.625rem;
    height: 24.0625rem;
    transition: border-color 0.3s ease-in-out, height 0.3s ease-in-out;
  }
  &__details {
    &-header {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      margin-bottom: 1rem;
      transition: transform 0.3s ease-in-out;

      &-title {
        font-size: $heading-1;
        line-height: $text-style-5-line-height;
        font-weight: $font-weight-semibold;
        color: #202020;
        transition: color 0.3s ease-in-out;
      }
    }
    &-price-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      margin-bottom: 1rem;
      &-currency {
        display: flex;
        align-items: center;
        gap: 6px;
        &-amount {
          font-size: 2rem;
          line-height: 38px;
          font-weight: $font-weight-semibold;
          color: #202020;
          transition: all 0.3s ease-in-out;
        }
        &-amount-strikethrough {
          font-size: $text-18;
          line-height: 24px;
          font-weight: $font-weight-normal;
          color: #848484;
          transition: color 0.3s ease-in-out;
          text-decoration: line-through;
        }
        &-amount-suffix {
          font-size: $text-18;
          line-height: 24px;
          font-weight: $font-weight-normal;
          color: #848484;
          transition: color 0.3s ease-in-out;
        }
      }
      &-discount {
        display: flex;
        align-items: center;
        outline: 1px solid $green-20;
        border-radius: 20px;
        padding: 4px 12px;
        transition: all 0.3s ease-in-out;
        &-text {
          font-size: $text-16;
          line-height: 22px;
          font-weight: $font-weight-medium;
          color: $green-16;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    &-description {
      line-height: 1rem !important;
      margin-top: 0.625rem;
    }
    &-currency {
      margin-right: 0.1875rem;
    }
    &-price-detail {
      line-height: 0.605rem;
      margin-left: 0.4375rem;
      margin-top: 2px;
      .mb-2-5 {
        margin-bottom: 0.35rem !important;
      }
    }
    .bs-tag {
      border-radius: 0.25rem;
      border-width: 0px;
      margin: 0px;
      font-size: 0.5rem;
      cursor: auto;
    }
  }
  &__divider {
    width: 100%;
    height: 0.125rem;
    background: $gray-16;
  }
  &__features {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    .green-stroke {
      width: 100%;
      padding-bottom: 0.25rem;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background-color: $green-20;
        border-radius: 0.25rem;
      }
    }
    .range-wrap {
      margin-top: 2rem;
    }
    .range {
      &__mark {
        &:first-child {
          left: 2px !important;
        }
      }
    }
    &-slider-control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      margin-bottom: 1rem;

      font-size: $text-12;
      line-height: $text-18;

      &-icon {
        display: flex;
        padding: 2px;
        align-items: flex-start;
        gap: 8px;
      }

      &-icon:hover {
        border-radius: 50px;
        background: $gray-16;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      &-item {
        display: flex;
        gap: 8px;
        padding-left: 4px;
        padding-right: 8px;

        &-check {
          display: flex;
          padding-top: 2px;
        }

        &-label {
          font-size: $text-14;
          line-height: 140%;
          font-weight: $font-weight-normal;
          color: #202020;
        }

        .break-lines {
          max-width: 216px;
        }

        .underline-dashed {
          text-decoration: underline dashed;
          text-underline-offset: 3px;
          text-decoration-color: #cacaca;
        }
      }
      .bg-color {
        background-color: $gray-16;
        padding: 4px;
        border-radius: 4px;

        .pricing-card__features-list-item-check {
          padding-left: 0px;
        }
      }
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      li {
        i {
          cursor: inherit;
        }
      }
    }
  }
  &__best-value-plan-insight {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 3px 6px;
    outline: 1px solid $indigo-2;
    border-radius: 4px;
    background-color: $indigo-1;
    &-text {
      font-size: $text-12;
      line-height: 16px;
      font-weight: $font-weight-medium;
      color: $blue-15;
    }
  }
  .ghost-primary-new.btn-ghost-primary {
    border-color: $blue-11;
    color: $blue-11;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: $blue-11;
      color: $white;
    }
  }
  .ghost-primary-new.btn-ghost-primary:disabled {
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: transparent;
      color: $blue-11;
      cursor: not-allowed !important;
    }
  }
  .ghost-secondary-new.btn-ghost-primary {
    border: 1px solid $gray-11;
    color: $gray-11;
    transition: all 0.3s ease-in-out;
  }
  .ghost-secondary-new-disable.btn-ghost-primary:disabled {
    border: 1px solid $gray-15;
    color: $gray-15;
    opacity: 0.35;
    transition: all 0.3s ease-in-out;
    &:hover,
    &:active {
      color: $gray-15 !important;
      background-color: transparent !important;
      cursor: not-allowed !important;
    }
  }
}
.best-value-plan {
  &__label-wrapper {
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
  }
  &__label {
    z-index: 1;
    display: flex;
    justify-content: center;
    gap: 4px;
    padding: 6px 12px;
    border-radius: 32px;
    background-color: #37b489;
    margin-bottom: -14px;
    margin-top: -14px;
    transition: all 0.3s ease-in-out;
    &-text {
      color: $gray-1;
      font-size: $text-12;
      font-weight: $font-weight-medium;
      line-height: 16px;
      transition: color 0.3s ease-in-out;
    }
  }
  .card {
    border: 1px solid $blue-11;
    border-top-width: 0.25rem;
    transition: all 0.3s ease-in-out;
  }
}
.pricing-card-lifetime-plan {
  .card {
    height: 25.5625rem;
  }
}
