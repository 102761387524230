.prospect-form-field {
  .bs-input-container {
    .bs-input-label-prefix {
      line-height: 1rem;
      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      margin-bottom: 0.25rem;
    }

    &:nth-child(1) {
      padding-left: 1.0625rem;
      padding-right: 0;
      margin-top: 0 !important;
    }

    &:nth-child(4) {
      padding-right: 0.6875rem;
      padding-left: 1.25rem;
    }

    &:nth-child(5) {
      padding-right: 0.6875rem;
      padding-left: 1.25rem;
    }

    &:nth-child(13),
    &:nth-child(15) {
      padding-left: 1.25rem !important;
    }

    &:nth-child(7),
    &:nth-child(9),
    &:nth-child(17),
    &:nth-child(19),
    &:nth-child(21),
    &:nth-child(23),
    &:nth-child(25),
    &:nth-child(27) {
      padding-right: 0 !important;
    }

    &:nth-child(8),
    &:nth-child(10),
    &:nth-child(18),
    &:nth-child(22),
    &:nth-child(24),
    &:nth-child(26),
    &:nth-child(28) {
      padding-right: 0.9375rem !important;
    }
  }

  .prospect-modal-user-field {
    .col-md-6 {
      &:nth-child(odd) {
        padding-right: 0;
        padding-left: 1.25rem;
      }

      &:nth-child(even) {
        padding-left: 1.25rem;
        padding-right: 0;
      }

      &:nth-child(3),
      &:nth-child(13),
      &:nth-child(15),
      &:nth-child(32),
      &:nth-child(34) {
        padding-right: 0.9375rem;
      }
    }

    .prospect-label-field {
      margin-bottom: 1rem !important;
    }

    .bs-input-container {
      margin-bottom: 1rem;
    }
  }

  .bs-input-number-container {
    .bs-input-number-label-prefix {
      line-height: 1rem;
      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      margin-bottom: 0.25rem;
    }
  }

  .col-md-6 {
    &:nth-child(even) {
      padding-left: 1.25rem;
      padding-right: 0;
    }

    &:nth-child(odd) {
      padding-right: 0.9375rem;
      padding-left: 1.25rem;
    }
  }
}

.longtext-wrapper-form {
  margin-bottom: 1rem;
  flex: 1;
  .longtext-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .bs-input-label-prefix {
      display: flex;
      line-height: 1rem;
      color: #6b7280;
      font-size: 0.75rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
    }
  }

  .longtext-textarea {
    width: 100%;
    padding: 4px 11px !important;
    border: 1px solid #d9d9d9;
    border-radius: 0.25rem;
    font-size: 14px;
    font-weight: 400;
    color: #595959;
    min-height: 3.25rem;
    max-height: 15.25rem;

    &:hover {
      border-color: #346aff;
    }

    &:active {
      border-color: #346aff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.5);
    }

    &:focus {
      outline: none;
      border-color: #346aff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.5);
    }

    &:focus-within {
      border-color: #346aff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.5);
    }

    &:focus-visible {
      border-color: #346aff !important;
    }

    &::placeholder {
      padding: 3px;
      border-radius: 4px;
      color: #9ca3af;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.error {
      border-color: $red-6 !important;
    }
  }
}
