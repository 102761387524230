@import './components/settings-header/settings-header';

.setting-container {
  flex-grow: 1;
  overflow-y: hidden;

  .setting-content {
    overflow: hidden;
    height: 100%;
    flex-wrap: nowrap;

    .settings-navigation {
      width: 13.5rem;
      min-height: calc(100vh - 5.625rem);

      padding: 1.5rem 0;

      display: flex;
      flex-direction: column;

      overflow-x: hidden;
      overflow-y: auto;
      background: #fff;

      z-index: 2;

      border-right: 1px solid $gray-13;
      box-shadow: 1px 2px 4px 0px $gray-13;

      &--title {
        display: flex;
        align-content: center;

        color: $blue-16;
        font-size: 0.75rem;
        font-weight: $font-weight-bold;
        line-height: 1.125rem;
        text-transform: uppercase;

        padding: 0.125rem 0rem 0.125rem 1.5rem;
        margin-bottom: 0.75rem;
      }

      &--item {
        display: flex;
        padding: 0.5rem 0 0.5rem 1.5rem;
        border-top: 0.0625rem solid transparent;
        border-bottom: 0.0625rem solid transparent;

        &:hover,
        &.active {
          text-decoration: none;
          border-top: 0.0625rem solid $blue-13;
          border-bottom: 0.0625rem solid $blue-13;
          background: $blue-17;
        }

        &.active {
          .settings-navigation--item-inner {
            color: $blue-11;
            font-weight: $font-weight-semibold;
          }
        }
      }

      &--item-inner {
        display: flex;
        align-items: center;

        color: $gray-15;
        font-size: 0.75rem;
        font-weight: $font-weight-medium;
        line-height: 1rem;

        svg {
          margin-right: 0.3125rem;
        }
        i {
          font-size: $text-16;
        }
      }
      .settings-navigation-divider {
        width: 10.375rem;
        min-height: 0.0625rem;
        max-height: 0.0625rem;
        margin: 1.5rem;
        background: $gray-13;
      }
    }

    .settings-navigation__hidden {
      display: none;
    }

    .setting-components {
      width: calc(100% - 13.5rem);
    }
    .setting-components-nav-hidden {
      width: 100%;
      .globalSettings__Wrapper {
        padding: 0px !important;
      }
    }
  }
  .email-account-container {
    .email-account-header {
      margin-bottom: 1.875rem;
      .action-delete {
        margin-left: 2rem;
        color: $red-5;
        display: flex;
        align-items: center;
        i {
          margin-right: 0.5rem;
          font-size: 1rem;
          color: $red-3;
        }
        &:hover {
          text-decoration: none;
          i {
            color: $red-5;
          }
        }
      }
    }

    .email-selection-info--wrapper {
      width: 100%;
      height: 2.5rem;
      border: 0.0625rem solid #fef3c7;
      background: #fffbeb;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      grid-gap: 0.75rem;
      gap: 0.75rem;

      .email-selection-info--separator {
        width: 0.0625rem;
        height: 1.25rem;
        background: #9ca3af;
      }
      .email-selection-info--cta {
        color: $blue-11;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.375rem;
        cursor: pointer;
      }
    }
    .sequence-score-badge {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0.125rem 0.5rem;

      width: 2rem;
      height: 1.5rem;

      border: 1px solid transparent;
      border-radius: 20px;

      span {
        font-weight: $font-weight-semibold;
        font-size: 0.75rem;
        line-height: 1.25rem;
      }

      &.poor {
        background: $red-17;
        border-color: $red-18;
        span {
          color: $red-18;
        }
      }
      &.average {
        background: $yellow-11;
        border-color: $yellow-13;
        span {
          color: $yellow-13;
        }
      }
      &.excellent {
        background: $green-21;
        border-color: $green-22;
        span {
          color: $green-22;
        }
      }
    }

    .empty-block {
      width: 24.375rem;
      padding: 10% 0 3rem 0;
      margin: auto;
      text-align: center;
      p {
        color: $gray-7;
      }
    }
    .email-account-list {
      .bs-table-content-free-plan {
        max-height: calc(100vh - 14.8rem);
      }
      .bs-table-content-default {
        max-height: calc(100vh - 12.5rem);
      }
      .bs-table-content {
        overflow: auto;
        table {
          .bs-table-tbody {
            border: 1px solid $gray-13;
            .disable-row {
              cursor: not-allowed;
              pointer-events: none;
              td {
                opacity: 0.3;
              }
            }
            .bs-table-row {
              &:hover {
                .tag-visibility {
                  visibility: visible;
                  transition: none;
                }
                td {
                  background: $gray-19 !important;
                }
              }
            }
          }
        }
      }
      .bs-table-tbody > tr > td {
        transition: none;
        &:first-child {
          border-left: 1px solid $gray-13;
        }
        &:last-child {
          border-right: 1px solid $gray-13;
        }
      }
      .bs-table-small .bs-table-thead > tr > th {
        font-weight: $font-weight-semibold;
        white-space: nowrap;
        border-top: 1px solid $gray-13;
        font-size: 0.75rem;
        color: $gray-12;
        padding: 0.625rem 0.5rem;

        &.email-multi-check {
          width: 1.875rem;
        }
        &:first-child {
          border-left: 1px solid $gray-13;
        }
        &:last-child {
          border-right: 1px solid $gray-13;
        }
      }
      .bs-table-small .bs-table-tbody td {
        &.email-multi-check {
          width: 1.875rem;
        }
      }
      .sender-data-row {
        display: flex;
        align-items: center;
        width: 17.75rem;
        .tag-visibility {
          visibility: hidden;
          transition: none;
        }
        p {
          margin: 0;
        }
        i {
          margin: 0 1rem;
          font-size: 1rem;
        }
        .smtp-label-wrap {
          margin-right: 0.5rem;
        }
      }
      .email-score {
        display: flex;
        align-items: center;
        i {
          color: $green-6;
          margin-right: 0.2rem;
        }
        span {
          color: $blue-11;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        .disable-text {
          pointer-events: none;
          opacity: 0.35;
        }
      }
      .cursor-not-allowed {
        cursor: not-allowed;
      }
      .grid-action {
        .bs-mr-15 {
          margin-right: 1rem;
          @media only screen and (min-width: $screen-768) and (max-width: $screen-1280) {
            margin-right: 0.5rem;
          }
        }
        i {
          font-size: 1.2rem;
          cursor: pointer;
          color: $gray-6;
          &:hover {
            color: $gray-9;
          }
        }
      }
      .switchToggle {
        &.connected-status {
          label {
            span {
              color: $lime-7;
              font-size: $text-12;
              font-weight: $font-weight-semibold;
              @media only screen and (min-width: $screen-768) and (max-width: $screen-1279) {
                display: none;
              }
            }
          }
        }
        &.disconnected-status {
          label {
            span {
              color: $gray-6;
              font-size: $text-12;
              font-weight: $font-weight-semibold;
              @media only screen and (min-width: $screen-768) and (max-width: $screen-1279) {
                display: none;
              }
            }
          }
        }
      }
      .column-visibility {
        @media only screen and (min-width: $screen-768) and (max-width: $screen-1280) {
          display: none;
        }
      }
      .custom-domain-mediaquery {
        display: none;
        @media only screen and (min-width: $screen-768) and (max-width: $screen-1280) {
          display: flex;
          margin-top: 0.5rem;
          align-items: center;
          strong {
            margin-right: 0.5rem;
            color: $gray-9;
          }
          .custom-domain {
            left: 0;
          }
          small {
            margin: 0 5px;
          }
        }
      }
      .status-col {
        min-width: 9rem;
        max-width: 9rem;
      }
    }

    .delete-submit-button {
      background-color: $red-12 !important;
    }
  }
  .safety-setting-container {
    padding: 1rem 1.5rem;
    .safety-setting-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $gray-4;
      padding-bottom: 0.5rem;
    }
    .safety-setting-list {
      .safety-setting-switch {
        margin: 1rem 0 1rem 0;
        .switchToggle {
          display: flex;
          align-items: center;
          label {
            margin: 0;
            span {
              white-space: nowrap;
              font-size: $text-14;
            }
          }
        }
      }
    }
  }
  .custom-fields-container {
    width: 100%;
    height: 100%;

    .custom-fields-grid {
      width: 100%;
      height: 100%;

      .custom-fields-content-container {
        position: relative;
        .add-custom-fields-container {
          z-index: 1;
          position: absolute;
          right: 0px;
        }
        .bs-tabs-nav {
          margin-bottom: 1.5rem !important;
          display: flex;
          align-items: start !important;
          &::before {
            border-bottom: 0rem solid #e8e8e8 !important;
          }
          .bs-tabs-nav-wrap {
            height: 1.875rem !important;
            align-self: auto !important;
            top: 0;
          }
        }
        .table-wrapper {
          height: calc(100% - 10rem);
          .table-list {
            overflow-y: auto;
            .bs-table-small {
              border: 1px solid $gray-14;
              border-radius: 4px 4px 0px 0px;
            }
            .react-bootstrap-table {
              .table-organism {
                .table-organism-header {
                  background-color: $gray-16;
                  pointer-events: none;
                }
                .table-organism-body {
                  td:hover {
                    .field-hashed-id-container {
                      display: flex;
                      max-width: 90px;
                      .field-hashed-id-content {
                        width: 69px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                      .field-hashed-id-icon {
                        display: inline;
                      }
                    }
                  }
                  td {
                    .field-hashed-id-container {
                      max-width: 90px;
                      .field-hashed-id-content {
                        width: 90px;
                      }
                      .field-hashed-id-icon {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .empty-custom-fields {
        width: 100%;
        height: 100%;
        padding: 10% 0 3rem 0;
        margin: auto;
        text-align: center;
      }
      .custom-fields-list {
        height: calc(100vh - 12rem);
        overflow-y: auto;
        .bs-table-tbody > tr > td {
          vertical-align: middle;
          transition: none;
        }
        .bs-table-small .bs-table-thead > tr > th {
          font-weight: $font-weight-semibold;
          &.custom-fields-check {
            width: 1.875rem;
            padding-left: 0;
          }
        }
        .bs-table-small .bs-table-tbody td {
          &.custom-fields-check {
            width: 1.875rem;
            padding-left: 0;
          }
        }
        .field-width {
          width: 30%;
        }
        .grid-action {
          .bs-mr-15 {
            margin-right: 1rem;
            @media only screen and (min-width: $screen-768) and (max-width: $screen-1280) {
              margin-right: 0.5rem;
            }
          }
          i {
            font-size: 1.2rem;
            cursor: pointer;
            color: $gray-6;
            &:hover {
              color: $gray-9;
            }
          }
        }
      }
    }
  }

  .custom-outcomes-container {
    .system-outcome-container {
      tr {
        th:last-of-type {
          justify-content: end;
          align-items: center;
          display: flex;
          padding-right: 2.9rem;
        }
      }
      td:last-of-type {
        display: flex;
        align-items: center;
        justify-content: end;
        padding-right: 1.875rem;
      }
    }
    .custom-outcome-container {
      tr {
        th:last-of-type {
          justify-content: end !important;
          align-items: center !important;
          display: flex !important;
          padding-right: 2.9rem !important;
        }
      }
      td:last-of-type {
        display: flex;
        align-items: center;
        justify-content: end;
        padding-right: 1.875rem;
      }
    }

    .custom-fields-grid {
      width: 100%;
      height: 100%;

      .custom-fields-content-container {
        .bs-tabs-nav {
          margin-bottom: 1.5rem !important;
          display: flex;
          align-items: start !important;
          &::before {
            border-bottom: 0rem solid #e8e8e8 !important;
          }
          .bs-tabs-nav-wrap {
            height: 1.875rem !important;
            align-self: auto !important;
            top: 0;
            .bs-tabs-nav-list {
              .bs-tabs-tab {
                .bs-tabs-tab-btn {
                  font-size: 0.875rem;
                  font-weight: $font-weight-medium;
                }
              }
            }
          }
        }
        .bs-tabs > .bs-tabs-nav .bs-tabs-nav-wrap {
          height: 40px;
        }
        .bs-tabs-top > .bs-tabs-nav {
          margin: 0 0 24px 0;
        }
        position: relative;
        .add-custom-fields-container {
          .prospect-outcomes--search {
            background-color: $gray-1;
            .bs-input-md {
              background-color: $gray-1;
            }
          }
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 2rem;
          min-height: 2rem;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: flex-end;
          justify-content: flex-end;
          -webkit-align-items: center;
          align-items: center;
          grid-gap: 1rem;
          gap: 1rem;
          margin-bottom: 0.5rem;
          .bs-input-affix-wrapper:focus,
          .bs-input-affix-wrapper:focus-within {
            box-shadow: none !important;
          }
        }

        .table-wrapper {
          height: calc(100% - 10rem);
          .table-list {
            overflow-y: auto;
            .bs-table-small {
              border: 1px solid $gray-14;
              border-radius: 4px 4px 0px 0px;
            }
            .react-bootstrap-table {
              .table-organism {
                .table-organism-header {
                  background-color: $gray-16;
                  pointer-events: none;
                }
                .table-organism-body {
                  tr td {
                    height: 62px !important;
                  }
                  td:hover {
                    .field-hashed-id-content {
                      display: flex;
                      padding: 2px 8px;
                      justify-content: center;
                      align-items: center;
                      gap: 4px;
                      border-radius: 20px;
                      width: fit-content;
                      .field-sentiment {
                        color: $blue-15;
                        font-family: $font-family-sans-serif;
                        font-style: normal;
                        font-weight: $font-weight-medium;
                        line-height: 18px;
                      }
                      .field-dot {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                      }
                    }
                    .field-name-container {
                      .field-name-content {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 8px;
                      }
                    }
                  }
                  td {
                    .field-hashed-id-content {
                      display: flex;
                      padding: 2px 8px;
                      justify-content: center;
                      align-items: center;
                      gap: 4px;
                      border-radius: 20px;
                      width: fit-content;
                      .field-sentiment {
                        color: $blue-15;
                        font-family: $font-family-sans-serif;
                        font-style: normal;
                        font-weight: $font-weight-medium;
                        line-height: 18px;
                      }
                      .field-dot {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                      }
                    }
                    .field-name-container {
                      .field-name-content {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .empty-custom-outcomes {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 15% 0 3rem 0;
      margin: auto;
      text-align: center;
      .empty-outcome-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        .empty-outcome-image {
          width: 80px;
          height: 80px;
        }
        .empty-outcome-title {
          display: flex;
          align-items: flex-start;
          color: $blue-15;
          text-align: center;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .empty-outcome-description {
          color: $gray-15;
          text-align: center;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          a {
            color: $blue-11;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            text-decoration-line: underline;
          }
        }
      }
    }
    .custom-fields-list {
      height: calc(100vh - 12rem);
      overflow-y: auto;
      .bs-table-tbody > tr > td {
        vertical-align: middle;
        transition: none;
      }
      .bs-table-small .bs-table-thead > tr > th {
        font-weight: $font-weight-semibold;
        &.custom-fields-check {
          width: 1.875rem;
          padding-left: 0;
        }
      }
      .bs-table-small .bs-table-tbody td {
        &.custom-fields-check {
          width: 1.875rem;
          padding-left: 0;
        }
      }
      .field-width {
        width: 30%;
      }
      .grid-action {
        .bs-mr-15 {
          margin-right: 1rem;
          @media only screen and (min-width: $screen-768) and (max-width: $screen-1280) {
            margin-right: 0.5rem;
          }
        }
        i {
          font-size: 1.2rem;
          cursor: pointer;
          color: $gray-6;
          &:hover {
            color: $gray-9;
          }
        }
      }
    }
  }
}

.admin-settings {
  max-height: 80vh;
  overflow-y: auto;
  &--spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    .spinner-border {
      border-color: $blue-11;
      border-right-color: transparent;
    }
  }

  &--content {
    .admin-setting-toggle {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .switchToggle {
        width: 2rem;
        height: 1rem;

        input:checked + label {
          background: $blue-11;
        }
      }

      &--label {
        font-weight: $font-weight-normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $blue-15;

        display: flex;
        align-items: center;
        gap: 0.25rem;

        a {
          font-weight: $font-weight-medium;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: $blue-11;
          text-decoration: underline;
        }
      }

      &--nested {
        margin-left: 2.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        &-item {
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            color: $blue-15;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: $font-weight-normal;
            line-height: 1.25rem; /* 142.857% */
          }
        }
      }
    }
  }

  .form-container {
    margin: 1.2em 0;
    width: 14rem;
    .email-verification {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    .button-container {
      margin-top: 0.8em;
    }
  }
}

.team-container {
  padding: 1.875rem 1.5rem 1rem 1.5rem;
  .team-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray-4;
    padding-bottom: 0.625rem;
    margin-bottom: 1.5rem;
  }

  .list-container {
    height: calc(100vh - 10rem);
    overflow-y: auto;
    .table-list {
      .team-list-body-wrapper {
        border: 1px solid $gray-13 !important;
        tr {
          border-left: 1px solid $gray-13 !important;
        }
      }
    }
  }
}

.schedule-container {
  .schedule-header {
    .action-delete {
      margin-left: 2rem;
      color: $red-5;
      i {
        margin-right: 0.5rem;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .schedule-list {
    overflow-y: auto;
    height: inherit;
    padding-bottom: 2.5rem;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .bs-accordions {
      &.accordion {
        height: calc(100vh - 12rem);
        overflow-y: auto;
        padding-right: 0rem;

        .accordion__item {
          margin-bottom: 0.875rem;
          border-bottom: 1px solid $gray-5;
          position: relative;
          overflow: hidden;
        }
        .accordion__button {
          display: flex;
          align-items: center;
          background: $gray-1;
          gap: 1.5rem;
          &:before {
            position: absolute;
            top: 1.25rem;
            left: 1rem;
          }
          p {
            margin: 0;
            padding-left: 1.75rem;
            font-size: $text-14;
            line-height: $text-style-2-line-height;
            width: 33%;
            .tag-visibility {
              visibility: hidden;
              transition: none;
            }
            &:hover {
              .tag-visibility {
                visibility: visible;
                transition: none;
              }
            }
          }
          small {
            font-size: $text-14;
            line-height: $text-style-2-line-height;
            width: 33%;
            @media only screen and (min-width: $screen-768) and (max-width: $screen-1190) {
              display: none;
            }
          }
        }
      }
      .accordion__panel {
        padding: 1.5rem 2.5rem;
        border-top: 1px solid $gray-5;
        background: $gray-1;
        .delete-schedule {
          margin-left: 2rem;
          color: $red-5;
          display: flex;
          align-items: center;
          i {
            margin-right: 0.5rem;
          }
          &:hover {
            text-decoration: underline;
          }
        }
        .schedule-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1rem;
        }
      }
    }
  }
  .schedule-form {
    .bs-input-container {
      width: 16rem;
    }
    ul {
      padding: 0;
      list-style: none;
      li {
        display: flex;
        margin: 1rem 0;
        span {
          min-width: 5.3125rem;
          font-size: $text-14;
          line-height: $text-style-2-line-height;
          display: flex;
          align-items: center;
          color: $gray-9;
        }
        .schedule-timeslot-container {
          display: flex;
          flex-flow: wrap;
          .schedule-timeslot {
            display: flex;
            border: 1px solid $gray-5;
            border-radius: $border-radius;
            padding: 0 5px;
            align-items: center;
            margin: 2px 0.6rem 2px 0;
            &.active {
              background: $gray-3;
            }
            &.error {
              border: 1px solid red;
            }
            input {
              margin: 0;
              border: 0;
              padding: 0;
              width: 1.8rem;
              text-align: center;
              font-size: $text-14;
              color: $gray-8;
              background: transparent;
              &:focus {
                outline: 0;
              }
            }
            input[type='number']::-webkit-outer-spin-button,
            input[type='number']::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type='number'] {
              -moz-appearance: textfield;
            }
            small {
              height: 1.5rem;
              align-items: center;
              display: flex;
              width: 0.3rem;
              justify-content: flex-start;
            }
            p {
              margin: 0 0 0 2px;
            }
            .schedule-timeslot-input {
              display: flex;
            }
            .timeslot-remove-action {
              display: flex;
              margin-left: 0.3rem;
              color: $gray-7;
              font-size: $text-14;
              cursor: pointer;
            }
          }
          .add-schedule {
            width: 1.625rem;
            height: 1.625rem;
            border: 1px solid $gray-5;
            border-radius: $border-radius;
            color: $gray-10;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin: 2px 0;
            &:hover {
              background: #f5f5f5;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.add-schedule-modal {
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  .modal-dialog {
    max-width: 43.75rem;
  }
  .error-list {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      color: $red-6;
      display: flex;
      align-items: center;
      font-size: $text-14;
      span {
        padding-left: 8px;
      }
    }
  }
}

.add-smtp-account-modal {
  .modal-dialog {
    max-height: 18.125rem;
  }

  .email-account {
    width: 14.813rem !important;
    height: 7.625rem !important;
  }
}

.add-email-account-modal {
  align-content: center;

  .modal-dialog {
    max-width: 34.125rem;
  }

  .email-account-method-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 1.875rem;
  }

  .email-account {
    cursor: pointer;
    width: 9.375rem;
    height: 7.625rem;
    background: $gray-16;
    border: 1px solid $gray-16;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .email-label {
      margin-left: 0.375rem;
    }

    &__description {
      width: 7.375rem;
      text-align: center;
      line-height: 0.875rem;
      margin-top: 0.5rem;
    }

    &:hover {
      border-color: $blue-5;
      background: $blue-17;
      .email-label {
        color: $blue-11;
      }
    }
    &.active {
      border-color: $blue-5;
    }
  }
  .email-account-select-service {
    color: $popover-arrow-color;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
  }
  .email-account-method {
    display: flex;
    justify-content: space-around;
  }
  .email-account-message {
    letter-spacing: 0em;
    text-align: left;
    color: $gray-15;
  }
  .email-account-note {
    letter-spacing: 0em;
    text-align: left;
    color: $gray-15;
  }
}
.add-email-info-modal {
  .modal-dialog {
    max-width: 43.75rem;
  }
  .add-email-info-body {
    .add-email-info-header {
      display: flex;
      justify-content: center;
      a {
        background: $blue-6;
        color: $gray-1;
      }
    }
    .add-email-info-form {
      .bs-select-box {
        height: 2rem;
      }
      .select-container {
        display: flex;
        align-items: center;
        .bs-select-box {
          margin-right: 0.5rem;
        }
        .sh-trash {
          cursor: pointer;
        }
      }
    }
  }
  .add-email-info-form {
    .sending-limit-component {
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        margin: 0;
      }
      .bs-input-number {
        width: 4.875rem;
        min-width: 4.875rem;
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
      /*label {
        margin-right: 0.5rem;
        color: $gray-9;
      }*/
    }
    .editor {
      height: 75%;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
}
.add-prospect-field {
  .modal-dialog {
    max-width: 31.25rem;
    .modal-header-custom {
      display: flex;
      align-items: center;
      h4 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #262626;
        width: 100%;
        margin: 0;
      }
    }

    .modal-content {
      padding: 15px 10px;
      .modal-header {
        border-bottom: 0;
      }
      .modal-body {
        padding-top: 0;
        padding-bottom: 0;
        .bs-input-label-prefix {
          font-size: 12px;
        }
        .select-label {
          margin-top: 10px;
        }
        .bs-select-box-text {
          font-size: 14px;
        }
        hr {
          border-width: 2px;
          margin-top: 30px;
        }
        .bs-input-container {
          margin-top: 10px;
          input {
            padding: 3px;
            border-radius: 4px;
            font-size: 14px;
          }
        }

        .info-icon {
          position: absolute;
          left: 177px;
          bottom: 85px;
        }
      }
      .modal-footer {
        border-top: 0;
        .btn-ghost-primary {
          width: 100px;
          height: 32px;
          border-color: #d9d9d9;
          color: #595959;
        }
        .btn-solid-primary {
          width: 100px;
          height: 32px;
          background-color: $blue-11;
        }
      }
    }

    .prospect-modal-body {
      max-height: 25rem;
      .bs-select-box {
        width: 16rem;
      }
    }

    .longtext-wrapper {
      margin-top: 10px;
      margin-bottom: 10px;
      .longtext-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .bs-input-label-prefix {
          margin-bottom: 0;
          line-height: 22px;
        }
      }

      .longtext-textarea {
        display: flex;
        width: 100%;
        padding: 4px 11px !important;
        border: 1px solid #d9d9d9;
        border-radius: 0.25rem;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        min-height: 3.25rem;
        max-height: 15.25rem;

        &:hover {
          border-color: #346aff;
        }

        &:active {
          border-color: #346aff;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.5);
        }

        &:focus {
          outline: none;
          border-color: #346aff;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.5);
        }

        &:focus-within {
          border-color: #346aff;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.5);
        }

        &:focus-visible {
          border-color: #346aff !important;
        }

        &::placeholder {
          padding: 3px;
          border-radius: 4px;
          color: #9ca3af;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .fallback-text-input {
      margin-top: 2%;
    }
    .error-label {
      display: flex;
      gap: 0.25rem;
      margin-bottom: 0.3125rem;
      font-size: 0.625rem;
      color: $red-12;
    }
    .modal-body {
      padding: 1.25rem 1.5rem;
    }
    .modal-footer {
      display: flex;
    }
  }
}

.delete-outcome-modal {
  .popover-arrow-color-txt {
    color: $blue-15;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .modal-dialog {
    .modal-content {
      .modal-header {
        .delete-modal-title {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        gap: 16px;
      }
    }
  }
}

.outcome-count-popover {
  display: flex;
  border: 2px solid red;

  border-radius: 0.5rem !important;
  border: 0.0625rem solid $gray-16 !important;
  background: $white !important;
  box-shadow: 0.1875rem 0.5rem 1.5rem -0.375rem rgba(15, 34, 67, 0.16) !important;

  .arrow {
    display: none;
  }

  .bs-popover-inner-content {
    padding: 0 !important;
  }

  .outcome-count {
    min-width: 10.5rem;
    padding: 0.75rem;

    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.25rem;

    .render-outcome-count-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--inner {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      p {
        color: $blue-15;
        font-size: 0.75rem;
        font-weight: $font-weight-medium;
        line-height: 1rem;
        margin: 0;
      }
    }
  }
}

.custom-outcomes-grid-header {
  .render-outcome-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid red;

    &--inner {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .active,
      .inactive {
        width: 0.5rem;
        height: 0.5rem;

        border-radius: 0.5rem;
        border: 0.0625rem solid $green-14;
        background: $green-14;
      }
      .inactive {
        border-color: $gray-11;
        background: $gray-11;
      }
    }
    p {
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1rem;
      margin: 0;
    }
  }
  .total-outcomes-badge {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.125rem 0.375rem;
    border-radius: 0.25rem;
    background: $gray-13;

    span {
      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      line-height: 1rem;
    }
  }
}

.add-prospect-outcome {
  .modal-dialog {
    max-width: 31.25rem;
    .modal-header-custom {
      display: flex;
      align-items: center;
      h4 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #262626;
        width: 100%;
        margin: 0;
      }
    }

    .modal-content {
      padding: 15px 0px;
      .modal-header {
        border-bottom: 0;
      }
      .modal-body {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 20px;
        .bs-input-label-prefix {
          font-size: 12px;
          margin-bottom: 4px;
        }
        .select-label {
          margin-top: 10px;
          cursor: default;
          margin-bottom: 4px;
        }
        .bs-select-box-text {
          font-size: 14px;
        }
        hr {
          border-width: 2px;
          margin-top: 30px;
        }
        .bs-input-container {
          margin-top: 10px;
          input {
            padding: 3px;
            border-radius: 4px;
            font-size: 14px;
          }
        }

        .info-icon {
          position: absolute;
          left: 177px;
          bottom: 85px;
        }
      }
      .modal-footer {
        border-top: 0;
        padding: 0 1.1rem;
        .btn-ghost-primary {
          width: 100px;
          height: 32px;
          border-color: #d9d9d9;
          color: #595959;
        }
        .btn-solid-primary {
          width: 100px;
          height: 32px;
          background-color: $blue-11;
        }
      }
    }

    .add-prospect-outcome-field {
      color: $gray-15;
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
    }

    .prospect-modal-body {
      color: $gray-15;
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      .bs-input-label-prefix {
        color: $gray-15;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
      .bs-input-affix-wrapper {
        height: 32px;
      }
      .bs-select-box {
        height: 32px;
      }
      .sentiment-content {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $blue-15;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .sentiment-dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }

    .fallback-text-input {
      margin-top: 2%;
    }
    .error-label {
      display: flex;
      gap: 0.25rem;
      margin-bottom: 0.3125rem;
      font-size: 0.625rem;
      color: $red-12;
    }
    .modal-body {
      // padding: 1.25rem 2.5rem;
    }
    .modal-footer {
      display: flex;
    }
  }
  // .modal-separator{
  //   display: flex;
  //   width: 396px;
  //   height: 1px;
  //   padding: 0px 24px;
  //   align-items: center;
  //   gap: 4px;
  //   position: absolute;
  //   right: 26px;
  //   background: #F3F4F6;
  //   margin-bottom: 20px;
  // }
}

.sequence-filter-menu {
  display: flex;
  .rotate-90 {
    transform: rotate(90deg);
    display: inline-block;
  }
  .dropdown-custom {
    background: $gray-4;
    border-radius: 4px;
    .btn-solid-dropdown-custom {
      @media only screen and (min-width: $screen-768) and (max-width: $screen-1190) {
        padding: 0.314rem 0.5rem;
      }
      span {
        @media only screen and (min-width: $screen-768) and (max-width: $screen-1190) {
          display: none;
        }
      }
      i {
        font-size: $text-16;
      }
    }
    &:hover {
      background: $gray-5;
    }
    &:focus {
      outline: 0;
    }
  }
}

.signature-editor {
  width: 100%;
  height: 15rem;
  .tox .tox-menu.tox-collection.tox-collection--list {
    top: -0.125rem !important;
    bottom: unset !important;
  }
  &.general-settings-input {
    height: 10.375rem;
  }
}

.settings-general-modal {
  padding: 1rem 1.5rem;
  .modal-dialog-centered {
    justify-content: center;
  }
  .modal-content {
    width: 27rem;
  }
  .modal-body {
    width: 100%;
    padding: 1rem 1.5rem;
    .input-error-container .bs-input-label-suffix {
      font-size: 0.75rem;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
    * {
      margin: 0;
    }
  }
}

.light-blue-color {
  background-color: #dbeafe !important;
}

.ellipse-email-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8rem;
  display: block;
}

.ellipse-sender-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 10rem;
  display: block;
}

.table-heading {
  font-size: $semibold-1;
  color: #4b5563 !important;
}

.email-table-header-background {
  background-color: #f9fafb;
  border: 1px solid #eaedef;
  border-radius: 4px;
  border-collapse: collapse;
}

.blue-bold {
  color: $blue-6;
}

.header-btn {
  &.btn-solid-primary {
    background: $blue-11 !important;
    color: $white !important;
    &.disabled {
      opacity: 0.34;
    }
  }
}

.billing-subscription {
  height: 100%;
  
  .billing-subscription-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.billing-subscription-container {
      padding-bottom: 2.5rem;
    }

    &__tab-content {
      overflow-y: auto;
      height: 100%;
    }
    .bs-tabs-nav {
      margin-bottom: 1.5rem !important;
      display: flex;
      align-items: start !important;
      &::before {
        border-bottom: 0rem solid #e8e8e8 !important;
      }
      .bs-tabs-nav-wrap {
        height: 1.875rem !important;
        align-self: auto !important;
        top: 0;
      }
    }

    // EV-Credits Tab
    &__ev-credits {
      .ev-credits-container {
        .card {
          box-shadow: 0px 2px 4px rgba(234, 237, 239, 0.65) !important;

          .card-body {
            display: flex;
            justify-content: space-between;
            .ev-credits-data {
              span {
                display: block;
              }
              .faq-page-link {
                color: $blue-11;
                text-decoration: underline;
              }
              .available-credits {
                color: $green-16;
                font-size: 1rem;
                font-weight: $font-weight-bold;
                margin-bottom: 0.7375rem;
              }
            }
            .purchase-credits-btn-wrap {
              .purchase-credits-btn.btn-solid-primary {
                background: $blue-11;
              }
            }
            .evc-loader {
              height: 4.7375rem;
              span {
                height: 1.25rem !important;
              }
            }
          }
        }
      }
    }

    .enhanced-email-verification {
      font-size: 0.75rem;
      color: #000;
    }

    .bold-text {
      font-weight: $font-weight-medium;
    }

    .normal-text {
      font-weight: 400;
    }

    .features-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 0;
      margin: 0.5rem 0 0 0;
      list-style: none;
    }

    .features-list li {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      svg {
        display: block;
      }
    }

    .feature-text {
      font-size: 0.75rem;
      color: $blue-15;
    }

    .custom-hr {
      height: 1px;
      background-color: #e8e8e8;
      border: none;
      margin-top: 0.8125rem;
      margin-bottom: 0.8125rem;
    }

    // EV-Credits END
  }
  .upgrade-plan-page-header {
    text-align: center;
    margin-top: 1.875rem;
  }
  .billing-option {
    display: flex;
    justify-content: center;
    margin-top: 1.225rem;
    div {
      display: flex;
      align-items: center;
      margin: 0px 0.5rem;
    }
    .annual-save-info {
      background: $red-15;
      height: 0.875rem;
      margin-top: 0.25rem;
      margin-left: 0.625rem;
      padding: 0.125rem;
      border-radius: 0.25rem;
      span {
        color: $white-text;

        font-style: normal;
        font-weight: $font-weight-medium;
        font-size: 0.625rem;
      }
    }
    .billing-option-switch.switchToggle label {
      background: $green-13;
    }
  }
  .billing-section {
    width: 100%;
    &__container {
      min-width: 67%;
    }
    &__addon-plan-wrap {
      .addon-text {
        margin: 1.5625rem 0;
      }
    }
    &__bill-wrap {
      margin-left: 1.875rem;
    }

    @media screen and (min-width: 1400px) {
      &__container {
        width: 75%;
      }
    }
  }

  &__payment-failed-wrap {
    height: 75vh;
    display: flex;
    justify-content: center;

    h3 {
      margin-top: 1.1625rem;
    }

    .payment-failed-message {
      margin-top: 0.225rem;
      a {
        text-decoration: underline;
      }
    }

    .btn-retry-payment.btn-solid {
      border: 1px solid $red-12;
      color: $red-12;
      font-weight: $font-weight-medium;
    }
  }

  .plan-name-wrapper {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-left: 1rem;

    h1 {
      color: $blue-15;
      font-size: 1rem;
      font-style: normal;
      font-weight: $font-weight-bold;
      line-height: 1.25rem;
      margin: 0;
    }
    p {
      &:first-of-type {
        color: $blue-15;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: $font-weight-semibold;
        line-height: 1.25rem;
        margin: 0;
      }
      &:last-of-type {
        color: $gray-15;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: $font-weight-normal;
        line-height: 1.25rem;
        margin: 0;
      }
    }
  }

  .subscription-info-tabs {
    .bs-tabs-tab {
      .billing-tab-container {
        display: flex;
        align-items: center;
        gap: 0.375rem;

        svg {
          color: $gray-15;
        }

        span {
          color: $blue-15;
          font-size: 0.875rem;
          font-weight: $font-weight-medium;
          line-height: 1.5rem;
        }
      }
      &.bs-tabs-tab-active {
        .billing-tab-container {
          span {
            font-weight: $font-weight-semibold;
          }
        }
      }
      &:hover,
      &.bs-tabs-tab-active {
        .billing-tab-container {
          svg,
          span {
            color: $blue-11;
          }
        }
      }
    }
  }

  .sending-infrastructure-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__tab-content {
      overflow-y: auto;
      height: 100%;

      .sending-infrastructure {
        &__container {
          display: flex;
          width: 1024px;
          padding: 24px 0px 24px 24px;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          border-radius: 4px;
          border: 1px solid $gray-13;
          background: white;
          box-shadow: 0px 2px 4px 0px rgba(234, 237, 239, 0.65);

          p {
            margin: 0;
          }

          .section-one {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;

            .section-one-header {
              display: flex;
              align-items: center;
              gap: 4px;

              &__title {
                color: #000;

                font-size: 14px;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 20px; /* 142.857% */
              }
            }
          }

          .section-two-table {
            .table-container {
              border: 0.0625rem solid $gray-16;
              border-radius: 0.25rem;
            }

            .purchase-history-table {
              width: 100%;
              border-collapse: collapse;
              background-color: white;

              th,
              td {
                text-align: left;
                font-size: 0.875rem;
                border-bottom: 0.0625rem solid $gray-16;
                border-right: 0.0625rem solid $gray-16;
              }

              th:last-child,
              td:last-child {
                border-right: none;
              }

              th:nth-child(1),
              td:nth-child(1) {
                min-width: 10.375rem;
                max-width: 10.375rem;
              }

              th:nth-child(2),
              td:nth-child(2) {
                min-width: 14.375rem;
                max-width: 14.375rem;
              }

              th:nth-child(3),
              td:nth-child(3) {
                min-width: 14.375rem;
                max-width: 14.375rem;
              }

              th:nth-child(4),
              td:nth-child(4) {
                min-width: 12.5rem;
                max-width: 12.5rem;
              }

              th:nth-child(5),
              td:nth-child(5) {
                min-width: 9.375rem;
                max-width: 9.375rem;
              }

              td {
                color: $blue-15;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 1.25rem;
                height: 2.5rem;
                padding: 0;
              }

              th {
                padding: 0;
                height: 46px;
                span {
                  display: flex;
                  color: $gray-15;
                  padding: 0 1rem;
                  font-size: 0.75rem;
                  font-style: normal;
                  font-weight: $font-weight-bold;
                  line-height: 1rem;
                  text-wrap: nowrap;
                }
              }

              tr:last-child td {
                border-bottom: none;
              }

              .date-cell {
                display: flex;
                height: 46px;
                padding: 0px 16px;
                align-items: center;
                gap: 20px;
                align-self: stretch;
                color: $blue-15;
                font-size: 14px;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 20px;
              }

              .domain-card {
                display: flex;
                height: 46px;
                padding: 0px 16px;
                align-items: center;
                gap: 20px;
                align-self: stretch;

                &__data {
                  display: flex;
                  width: 149px;
                  align-items: center;
                  gap: 8px;
                  text-wrap: nowrap;

                  &__icon {
                    display: flex;
                    width: 16px;
                    height: 16px;
                    padding: 2px 1.333px 1.333px 1.333px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                  }

                  &__price {
                    display: flex;
                    height: 20px;
                    align-items: flex-start;
                    color: $blue-15;
                    width: 125px;

                    font-size: 14px;
                    font-style: normal;
                    font-weight: $font-weight-medium;
                    line-height: 20px;
                    text-wrap: nowrap;
                  }
                  .price-pipe {
                    color: $blue-14;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                  }
                }

                &__button {
                  display: flex;
                  padding: 2px 6px;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 10px;
                  border-radius: 4px;
                  background: white;
                  color: $gray-15;
                  height: 20px;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: $font-weight-medium;
                  line-height: 16px; /* 160% */
                  visibility: hidden;
                  opacity: 0;
                  transition: opacity 0.3s ease, visibility 0.3s ease;
                }

                &:hover {
                  background-color: #f3f4f6;

                  button {
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }

              .manage-billing-cell {
                display: flex;
                height: 46px;
                padding: 0px 16px;
                align-items: center;
                gap: 20px;
                align-self: stretch;

                &__container {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .text {
                    color: $blue-11;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: $font-weight-medium;
                    line-height: 20px; /* 142.857% */
                    text-wrap: nowrap;
                  }

                  .icon {
                    display: block;
                    color: $blue-11;
                  }
                }
              }
            }
          }

          .section-three {
            margin-top: 0.25rem;
            .section-three-note {
              color: #4b5563;

              font-size: 12px;
              font-style: normal;
              font-weight: $font-weight-normal;
              line-height: 20px;

              a {
                color: $blue-11;

                font-size: 12px;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 20px;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-decoration-skip-ink: none;
                text-decoration-thickness: auto;
                text-underline-offset: auto;
                text-underline-position: from-font;
              }
            }
          }
        }
      }
    }
  }
}

.new-badge {
  display: flex;
  padding: 0.0625rem 0.1875rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.1875rem;
  background: $yellow-13;

  color: $white;
  text-align: center;
  font-size: 0.625rem;
  font-weight: $font-weight-medium;
  line-height: 0.75rem;
}

.shd-modal.purchase-credits-modal .modal-content {
  border: none !important;
}

.purchase-credits-modal {
  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    width: 24.5rem;
  }

  .shd-modal--body {
    padding: 0 !important;
  }

  &--custom-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
      display: block;

      color: $blue-15;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  &--plans {
    padding: 1.25rem 1.5rem;

    .credit-plans-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;

      width: 100%;
    }

    .credit-plan {
      width: 5rem;
      height: 2.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      flex: 1 1 calc(25% - 8px);

      padding: 0.375rem 1rem;

      position: relative;

      border-radius: 0.25rem;
      border: 0.0625rem solid #d1d5db;
      transition: all 0.2s ease;

      p {
        margin: 0;
        user-select: none;

        color: $blue-15;

        font-size: 0.875rem;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
        transition: all 0.2s ease;
      }

      .best-value-badge {
        width: 4.125rem;
        height: 1.125rem;

        padding: 0.25rem 0.375rem;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 0.75rem;
        border: 0.0625rem solid #6ee7b7;
        background-color: #e6f8ef;

        color: #059367;
        font-size: 0.625rem;
        line-height: 0.625rem;
        font-weight: $font-weight-medium;
        white-space: nowrap;

        position: absolute;
        top: -0.9375rem;
        left: 50%;
        transform: translateX(-50%);
      }

      &.loading {
        span {
          display: flex;
          height: 1.25rem !important;
        }
      }

      &.selected {
        border: 0.0625rem solid $blue-11;
        box-shadow: 0px 0px 0px 2px #dbeafe;
        transition: all 0.2s ease;

        p {
          color: $blue-11;
          font-weight: 600;
          transition: all 0.2s ease;
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .purchase-ev-credits-agreement {
      display: flex;
      align-items: flex-start;

      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1rem;
      margin-top: 1.25rem;

      .bs-checkbox {
        padding-top: 0.325rem;
        margin-right: 0.125rem;
      }
    }
  }
  &--custom-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 1.25rem 1.5rem;
    border-top: 0.0625rem solid #f3f4f6;
    box-sizing: border-box;

    .selected-plan-amount {
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
      color: $blue-15;
    }

    .buy-now-btn {
      min-width: 5.75rem;
    }
  }
}

.upgrade-plan-modal {
  .modal-dialog {
    max-width: 28.125rem;
  }

  .modal-content {
    margin-top: -12rem;
  }

  .modal-header {
    border-bottom: none;
    .sh-close {
      margin-top: -0.8rem;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 0px;
    }
  }

  .modal-footer {
    border-top: none;
    max-height: 4.5625rem;
    padding: 1.25rem 1.5rem;

    .btn-solid {
      background-color: $blue-11;

      &:hover {
        background-color: $blue-20;
      }
    }
  }

  .modal-footer {
    .btn-solid {
      background-color: $blue-11;
      &:hover,
      &:active {
        background-color: #346aff !important;
        border-color: #346aff !important;
      }
    }
  }
}

// SMTP label
.smtp-label-container {
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gray-13;
  border-radius: 0.25rem;
}

.danger-validation-text {
  color: $red-18;
}

// classes for modal that opens after clicking on CSV/import

.add-bulk-import-modals {
  align-content: center;

  .modal-dialog {
    max-width: 34.125rem;
  }

  .modal-content {
    max-height: 34.25rem !important;
  }

  .modal-body {
    height: 27rem;
  }

  .add-bulk-import-headings {
    margin-top: -0.157rem;
    background: linear-gradient(
      180deg,
      #f9fafb 0%,
      rgba(239, 246, 255, 0) 100%
    );
    border-radius: 0.25rem;

    width: 31.125rem;
    height: 10.75rem;

    .add-bulk-import-image {
      margin-top: 0.75rem;
    }
  }
  .select-file {
    border: 1px dashed;
    border-radius: 0.5rem;
    height: 11.25rem;
    &--pre {
      border-color: $gray-13;
      background-color: $gray-19;
    }
    &--post {
      border-color: $green-20;
      background-color: $green-21;
      .post-container {
        height: 100%;
      }
      .icon-wrapper > img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

// Email account service styling for animation

#connect-single {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: -1.1825rem;
  }

  to {
    margin-left: 0rem;
  }
}

#bulk-import {
  animation-duration: 1s;
  animation-name: slideof;
}

@keyframes slideof {
  from {
    margin-right: -1.1825rem;
  }
  to {
    margin-right: 0rem;
  }
}

.rotate-icon-vector {
  margin-top: 0.125rem !important;
}

.linksuccess {
  width: 0.875rem;
  height: 0.875rem;
}

.lh-20 {
  line-height: 1.25rem !important;
}

.transfer-container {
  .transfer-body {
    .transfer-content {
      .transfer-list {
        li {
          .sentiment-content {
            display: flex;
            gap: 8px;
            .sentiment-icon {
              width: 16px;
              height: 16px;
            }
            .sentiment-dot {
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

.delete-outcome-modal-transfer {
  .transfer-container {
    .transfer-body {
      .transfer-content {
        height: 16.3rem;
        overflow-y: scroll;
      }
    }
  }
}

.icon-default-size {
  width: 0.875rem;
  height: 0.875rem;
}

.sequence-setting-radio-buttons-container {
  width: 42.5rem;
}
.sequence-setting-lable {
  color: $blue-15;
}
.admin-settings-sequence-sending-priority {
  .text-label {
    color: $blue-15;
    font-size: 14px;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 20px;
  }
  .bs-radio-wrapper {
    margin: 0 !important;
    margin-right: 1.25rem !important;
    line-height: 20px;
    span {
      padding-right: 0 !important;
    }
  }
  .rb-group {
    gap: 0.75rem;
  }
}

.nested-input-container {
  margin-left: 2.5rem;
}

.emails-and-domains-input--container {
  height: 8.875rem !important;
}

.emails-and-domains-input--pills {
  overflow-y: auto !important;
}

.emails-and-domains-input--loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  span {
    display: flex;
  }
}

.admin-settings-exclusion-input-save-btn {
  margin-top: 0.75rem;
  min-width: 5.0625rem;
}

.admin-settings-exclusion-input {
  margin-left: 2.5rem;
  margin-top: 0.5rem;
}

.all-replies-icon {
  color: $gray-15;
  margin-left: 0.5rem;
}

.external-reply-tooltip {
  .tooltip-inner {
    margin-top: -12px;
  }
}

.shd-modal.view-order-details-modal .modal-content {
  border: none !important;
}

.view-order-details-modal {
  .modal-dialog {
    max-width: 868px !important;
  }
  .modal-content {
    border: none;
    height: 564px;
  }

  .shd-modal--header {
    padding: 1.25rem 1.5rem !important;
    border-bottom: 1px solid $gray-13 !important;
    .shd-modal--title {
      line-height: 1.5rem !important;
    }
  }
  .shd-modal--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-top: 1.5rem !important;
    overflow-x: hidden;

    .order-details-tables-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      max-height: 25.375rem;
      overflow: auto;
      width: 100%;
      height: 100%;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-16;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: $gray-2;
      }

      .domains-table-wrapper {
        border: 0.0625rem solid $gray-16;
        border-radius: 0.25rem;

        .domains-table {
          width: 100%;
          border-collapse: collapse;
          background-color: white;

          th,
          td {
            text-align: left;
            font-size: 0.875rem;
            border-bottom: 0.0625rem solid $gray-16;
            border-right: 0.0625rem solid $gray-16;
          }

          th:last-child,
          td:last-child {
            border-right: none;
          }

          th:nth-child(1),
          td:nth-child(1) {
            min-width: 18.75rem;
            max-width: 18.75rem;
          }

          th:nth-child(2),
          td:nth-child(2) {
            min-width: 9rem;
            max-width: 9rem;
          }

          td {
            color: $blue-15;
            height: 2.5rem;
            font-size: 14px;
            font-style: normal;
            font-weight: $font-weight-medium;
            line-height: 1.25rem;
            padding: 0 16px;
          }

          th {
            padding: 0;
            height: 2.875rem;
            span {
              display: flex;
              color: $gray-15;
              padding: 0 1rem;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: $font-weight-bold;
              line-height: 1rem;
              text-wrap: nowrap;
            }
          }

          tr:last-child td {
            border-bottom: none;
          }
        }
      }

      .email-accounts-table-wrapper-two {
        border: 0.0625rem solid $gray-16;
        border-radius: 0.25rem;

        .email-accounts-table-two {
          width: 100%;
          border-collapse: collapse;
          background-color: white;

          th,
          td {
            text-align: left;
            font-size: 0.875rem;
            border-bottom: 0.0625rem solid $gray-16;
            border-right: 0.0625rem solid $gray-16;
          }

          th:last-child,
          td:last-child {
            border-right: none;
          }

          th:nth-child(1),
          td:nth-child(1) {
            min-width: 21.125rem;
          }

          td {
            color: $blue-15;
            font-size: 14px;
            font-style: normal;
            font-weight: $font-weight-medium;
            line-height: 20px;
            height: 2.5rem;
            padding: 0 16px;

            .email-with-icon {
              display: flex;
              height: 20px;
              align-items: center;
              gap: 8px;
              align-self: stretch;

              img.email-accounts-profile-image {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          th {
            padding: 0;
            height: 2.875rem;
            span {
              display: flex;
              color: $gray-15;
              padding: 0 1rem;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: $font-weight-bold;
              line-height: 1rem;
              text-wrap: nowrap;
            }
          }

          tr:last-child td {
            border-bottom: none;
          }
        }
      }
    }

    .order-details-footer {
      display: flex;
      padding: 14px 0;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-top: 1px solid $gray-13;
      margin-top: 1.5rem;

      .footer-label {
        margin: 0;
        color: $gray-15;
        font-size: 12px;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 16px;
      }

      .purchaser-name {
        margin: 0;
        color: $blue-15;
        font-size: 14px;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 20px;
      }
    }
  }

  .shd-modal--divider {
    display: none;
  }
}

@import './components/custom-domain/custom-domain';
@import './components/header-banner/header-banner';
@import './components/users-and-teams/users-and-teams';
@import './components/my-profile/my-profile';
@import './components/out-of-office/out-of-office';
@import './components/billing-subscription/components/upgrade-plan-tabs/upgrade-plan-tabs';
@import './components/webhook/webhook';
@import './components/do-not-contact/do-not-contact';
@import '../../shared/components/global-settings-wrapper/global-settings-wrapper';
@import '../../shared/design-system/components/atoms/link-badge/link-badge';
@import '../../shared/components/delete-alert-modal/delete-alert-modal';
@import './components/whitelabel/whitelabel';
@import '../unified-inbox/components/unified-inbox-mails-content/utils/unified-inbox-alert-modal/unified-inbox-alert-modal';
@import './components/admin-settings/components/admin-setting-section/admin-setting-section';
