.auth {
  width: 100%;
  height: 100%;
  min-height: 32.625rem;

  overflow-y: auto;

  display: flex;
  align-items: center;

  .testimonials-container {
    width: 475px;
    min-height: 100vh;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    padding: 3rem;
    padding-right: 4.5625rem;

    background: linear-gradient(246deg, #dce1fe 3.34%, #eff2fe 87.55%);
    box-shadow: -0.125rem 0px 0.5rem $blue-13;
    border-radius: 0px;

    .copyright {
      width: 8.125rem;
      height: 1.5rem;
      margin: 0;

      font-weight: $font-weight-medium;
      font-size: $text-12;
      line-height: 1.5rem;

      display: flex;
      align-items: center;

      color: $gray-15;
    }
  }

  .auth-wrapper {
    width: calc(100% - 440px);
    height: 100%;
    min-height: 100vh;
    margin-left: -25px;
    overflow: hidden auto;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #ffffff;
    box-shadow: -2px 0px 8px #e5e7eb;
    border-radius: 2.25rem 0px 0px 2.25rem;
  }

  .auth-container {
    width: 28.75rem;

    .auth-form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1.5rem;
    }

    &.gap-0 {
      gap: 0;
    }

    .sh-logo {
      display: none;
      margin-bottom: 2rem;
      img {
        max-width: 18.75rem;
        max-height: 6.25rem;
      }
    }

    &--title {
      font-weight: $font-weight-bold;
      font-size: 1.5rem;
      line-height: 2.25rem;
      text-align: center;
      color: $blue-15;
      margin: 0;
    }

    &--sub-title {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: $gray-15;

      a,
      .link {
        color: $blue-11;
        text-decoration: none;
        transition: all 0.2s ease;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    &--form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1.25rem;

      width: 100%;

      .auth-form-row {
        display: flex;
        justify-content: center;
        gap: 1.25rem;

        .auth-form-input {
          width: 13.75rem;

          &.email {
            width: 28.75rem;
          }

          &.password {
            width: 28.75rem;
            .password-validation-label {
              font-size: 0.625rem;
              line-height: 1.125rem;
            }
            .password-validation-checklist-container {
              margin-bottom: 0;

              ul li p {
                font-weight: $font-weight-medium;
                font-size: 0.75rem;
                line-height: 1.125rem;
              }
            }
          }

          .bs-input-container {
            margin: 0;

            .bs-input-label-prefix {
              font-weight: $font-weight-semibold;
              font-size: 0.75rem;
              line-height: 1.125rem;
              color: $gray-15;
              margin-bottom: 0.3125rem;
            }

            .bs-input-affix-wrapper {
              height: 2.25rem;
              padding: 0.5rem 1rem;
              background: $gray-1;
              border: 0.0625rem solid $gray-13;
              border-radius: 0.25rem;
              line-height: 1.25rem;
              box-sizing: border-box;
              transition: all 0.2s ease-in-out;

              .bs-input {
                font-weight: $font-weight-medium;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: $blue-15;

                &::placeholder {
                  color: $gray-23;
                }
              }

              &:focus,
              &:focus-within {
                outline: none;
                box-shadow: none;
                border-color: $blue-11;
              }

              &.input-error {
                border-color: $red-20;

                &:focus,
                &:focus-within {
                  border-color: $red-20;
                }
              }
            }

            .bs-input-label-suffix {
              width: 100%;
              height: 1.125rem;

              font-weight: $font-weight-normal;
              font-size: 0.75rem;
              line-height: 1.125rem;
              color: $red-12;

              mix-blend-mode: normal;

              margin-top: 0.25rem;
            }
          }

          &.phone {
            p {
              font-weight: $font-weight-semibold;
              font-size: 0.75rem;
              line-height: 1.125rem;
              color: $gray-15;
              margin: 0;
              margin-bottom: 0.3125rem;
              margin-top: 0.25rem;
            }
            .phone-form-input {
              display: flex;
              align-items: center;

              .form-control {
                width: 100%;
                height: 2.25rem;
                padding-left: 0.75rem;
                background: $gray-1;
                border: 0.0625rem solid $gray-13;
                border-radius: 0 0.25rem 0.25rem 0;
                line-height: 1.25rem;
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;

                font-weight: $font-weight-medium;
                font-size: 0.875rem;
                color: $blue-15;

                &::placeholder {
                  color: $gray-23;
                }

                &:focus,
                &:focus-within {
                  outline: none;
                  box-shadow: none;
                  border-color: $blue-11;
                }
              }
              .flag-dropdown {
                border: 0.0625rem solid $gray-13;
                border-right: none;
                border-radius: 0.25rem 0 0 0.25rem;
                background-color: transparent;
                padding: 0.5rem;
                width: 3rem;
                height: 2.25rem;
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;

                z-index: 1;
                position: relative;

                &:focus,
                &:focus-within {
                  outline: none;
                  box-shadow: none;
                  border-color: $blue-11;

                  & + .form-control {
                    border-left-color: $blue-11;
                  }
                }

                .selected-flag {
                  width: 2rem;
                  padding: 0;
                  border-radius: 0;
                  .flag {
                    .arrow {
                      left: 1.625rem;
                    }
                  }
                }
              }

              &.input-error {
                .form-control,
                .flag-dropdown {
                  border-color: $red-20;

                  &:focus,
                  &:focus-within {
                    outline: none;
                    box-shadow: none;
                    border-color: $red-20;

                    & + .form-control {
                      border-color: $red-20;
                    }
                  }
                }
              }
            }
            .phone-form-input-error {
              width: 13.75rem;
              height: 1.125rem;
              font-weight: $font-weight-normal;
              font-size: 0.75rem;
              line-height: 1.125rem;
              color: $red-12;
              mix-blend-mode: normal;
              margin: 0;
              margin-top: 0.25rem;
            }
          }

          .forgot-password {
            text-align: right;
            margin-top: 0.25rem;
            a {
              font-weight: $font-weight-semibold;
              font-size: 0.75rem;
              line-height: 1.125rem;
              color: $blue-31;
            }
          }
        }
      }

      .shd-button {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: $text-14;
        line-height: $heading-2;
        font-weight: $font-weight-semibold;
        height: auto;
        background: linear-gradient(46deg, #2563eb 17.53%, #3b82f6 96.95%);
        transition: background 0.2s linear;
        &:hover {
          background: linear-gradient(46deg, #1c5be5 17.53%, #337bf0 96.95%);
        }
      }
    }

    .mfa-form {
      gap: 1.5rem;
      .shd-button {
        background: linear-gradient(46deg, #2563eb 17.53%, #3b82f6 96.95%);
        transition: background 0.2s linear;
        &:hover {
          background: linear-gradient(46deg, #1c5be5 17.53%, #337bf0 96.95%);
        }
      }
      &--message-container {
        display: block;
        text-align: center;
        margin-top: 0.5rem;
        .message {
          color: $gray-12;
          font-weight: $font-weight-normal;
          line-height: 1.25rem;
          font-size: 0.875rem;
        }
        .email-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 1.25rem;
          gap: 0.25rem;
          .email {
            color: $blue-15;
            font-weight: $font-weight-semibold;
            line-height: 1.25rem;
            font-size: 0.875rem;
          }
          .edit-action {
            color: $gray-11;
            cursor: pointer;
          }
        }
      }
      &--error-message {
        margin-top: 0.25rem;
        color: $red-12;
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 0.75rem;
        font-weight: $font-weight-normal;
        line-height: 1.125rem;
      }
      &--hint-message {
        color: $gray-11;
        font-size: 0.75rem;
        font-weight: $font-weight-normal;
        line-height: 1rem;
      }
      &--resend-container {
        color: $blue-16;
        font-family: Inter;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: $font-weight-normal;
        line-height: 1rem;
        .strong-text {
          font-weight: $font-weight-semibold;
        }
        .resend-btn {
          color: $blue-31;
          font-weight: $font-weight-medium;
          cursor: pointer;
        }
      }
      .otp-container {
        gap: 0.75rem;
        justify-content: center;
      }
      .otp-input {
        width: 3.125rem !important;
        height: 3.125rem;
        padding: 0;
        text-transform: uppercase;
        border: 1px solid $gray-13;
        border-radius: 0.25rem;
        color: $gray-800;
        font-size: 1rem;
        font-weight: $font-weight-normal;
        &:hover {
          border-color: #529dff;
        }

        &:focus {
          border-color: #529dff;
          box-shadow: 0 0 0 2px rgba(42, 127, 255, 0.2);
        }

        &:focus-visible {
          outline: 0px;
        }
      }
      .otp-input-error {
        border-color: $red-20;
        &:hover {
          border-color: $red-20;
        }
        &:focus {
          border-color: $red-20;
          box-shadow: 0 0 0 2px rgba(252, 165, 165, 0.2);
        }
      }
    }
    .verification-title {
      font-size: 1.125rem !important;
    }
    .sso-login-form {
      gap: 2rem;
    }
    .sso-setup-back-link {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;
        color: $gray-15;
        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 0.25rem;
          text-decoration: none;
          color: $gray-15;
          font-size: $text-14;
          line-height: $heading-2;
          font-weight: $font-weight-medium;
        }
      }
    }
    .sso-login-form {
      gap: 2rem;
    }
    .sso-setup-back-link {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;
        color: $gray-15;
        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 0.25rem;
          text-decoration: none;
          color: $gray-15;
          font-size: $text-14;
          line-height: $heading-2;
          font-weight: $font-weight-medium;
        }
      }
    }
    .sso-login-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .spinner--blue {
        height: 40px;
        width: 40px;
      }
      .sso-login-spinner-text {
        margin-top: 1rem;
        font-size: $text-14;
        line-height: $heading-2;
        font-weight: $font-weight-medium;
        color: $gray-11;
      }
    }
  }

  .privacy-policy-text {
    width: 18.75rem;
    height: 2.25rem;
    margin-top: 1rem;
    text-align: center;

    p {
      font-weight: $font-weight-medium;
      font-size: 0.75rem;
      line-height: 1.125rem;
      text-align: center;
      color: $gray-15;
      margin: 0;

      a {
        color: $gray-15;
        text-decoration: underline;
        transition: all 0.2s ease;
        &:hover,
        &:focus {
          color: $blue-11;
        }
      }
    }
  }

  .bottom-navigation {
    width: 13.9375rem;
    margin-top: 1.5rem;

    p {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $gray-15;
      margin: 0;
      text-align: center;

      a {
        color: $blue-31;
        text-decoration: none;
        transition: all 0.2s ease;
        font-weight: $font-weight-medium;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}

.oauth-buttons-container {
  width: 100%;

  .oauth-divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 24px;
    color: $gray-11;

    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid $gray-13;
    }

    span {
      padding: 0 8px;
      font-size: 14px;
    }
  }

  .oauth-button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 14px !important;
    border: 1px solid $gray-13 !important;

    font-size: $text-14;
    line-height: $heading-2;
    font-weight: $font-weight-medium;
    color: $gray-15;
    
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
    }
  }

  .google-button:hover {
    background-color: #f8f9fa;
  }

  .microsoft-button:hover {
    background-color: #f8f9fa;
  }
}

@media only screen and (max-width: 1023px) {
  .auth {
    height: 100vh;
    min-height: 100vh;
    display: block;

    .testimonials-container {
      display: none;
    }
    .auth-wrapper {
      width: 100%;
      height: 100%;
      min-height: 100%;
      margin: 0;
      border-radius: 0;
      padding: 3rem 1rem;

      display: block;

      .auth-container {
        width: 100%;
        max-width: 28.75rem;
        margin: 0 auto;

        .sh-logo {
          display: block;
          img {
            max-width: 18.75rem;
            max-height: 6.25rem;
          }
        }

        .auth-form-row {
          width: 100%;
          flex-direction: column;

          .auth-form-input,
          .email,
          .password {
            width: 100%;
            max-width: 28.75rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .auth-wrapper {
    .testimonials-container {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}

.form-control-select {
  .transfer-container {
    .transfer-body {
      .transfer-content {
        max-height: 13rem;
      }
    }
  }
}

.invalid-msg-container {
  padding: 2.8125rem 5rem;

  .btn-solid-primary.disabled,
  .btn-solid-primary:disabled {
    background-color: $blue-5;
    color: white;
  }

  .invalid-msg-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .top-welcome {
      text-align: center;
      width: 22.5rem;
      margin: 0 auto;

      h3 {
        color: $gray-10;
        margin-top: 1.625rem;

        @media only screen and (min-width: $screen-768) and (max-width: $screen-1024) {
          font-size: 145%;
        }

        @media only screen and (min-width: $screen-1025) and (max-width: $screen-1280) {
          font-size: 220%;
        }
      }

      p {
        margin-bottom: 1rem;
        color: $gray-10;

        a {
          color: $blue-6;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      &--image-text {
        font-style: bold;
      }
    }
    .reset-pass-success-message {
      width: 22.5rem;
    }
    .form-section {
      width: 21.25rem;
      margin: 0 auto;

      .btn-solid {
        width: 100%;
        margin-top: 1rem;
      }

      .bs-input-container {
        margin: 0 auto 1rem auto;
        text-align: left;
      }
    }
    .reset-success-note {
      text-align: center;
    }

    .login-link {
      margin: 1rem auto 0 auto;
      text-align: center;

      a {
        color: $blue-6;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .invalid-msg-header {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 50px;
    }
  }
}

.verification-code {
  text-align: center;
  color: $gray-9;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-solid-primary.disabled,
  .btn-solid-primary:disabled {
    background-color: $blue-5;
    color: white;
  }

  &--text {
    color: $gray-7;
  }

  .verification-code-timer {
    color: $gray-7;
  }

  .verification-code-resend-option-wrapper {
    margin-left: 4px;
    .verification-code-resend {
      color: $blue-6;
      cursor: pointer;
      @include hover() {
        text-decoration: underline;
      }
    }
    .verification-code-resend-disabled {
      color: $gray-6;
      cursor: not-allowed;
    }
    .verification-code-resend-loading {
      margin-left: 5px;
      display: inline-flex;
      align-items: center;
      i.icon-loading {
        animation: spin 2s linear infinite;
      }
    }
  }
}

.ltd-container {
  height: 100vh;
  max-height: 100vh;
  background-color: $gray-19;
  .user-right {
    background-color: transparent;
    .top-welcome {
      margin-top: 2.5rem;
      &--header {
        font-weight: $font-weight-bold;
        font-size: 1rem !important;
        line-height: 1.5rem;
        color: $blue-15 !important;
        margin-bottom: 0.5rem !important;
        margin-top: 0;
      }
      &--body {
        font-size: 0.75rem !important;
        line-height: 1.125rem;
        color: $gray-15;
      }
    }
    .form-section {
      background-color: $gray-1;
      margin-top: 1.625rem;
      margin-bottom: 1.875rem;
      padding: 1.875rem;
      border: 0.0625rem solid $gray-13;
      border-radius: 0.25rem;
      box-sizing: border-box;
      width: 28.875rem;
      min-height: 20.1875rem;
      height: auto;
      form {
        margin-top: 0;
      }
      .bs-input-container {
        margin: 0;
        .bs-input-label-prefix {
          font-weight: $font-weight-semibold;
          font-size: 0.75rem;
          color: $gray-15;
        }
        &:first-of-type {
          margin-bottom: 1.25rem;
        }
        .input-error {
          border-color: $red-16;
          .bs-input-suffix {
            color: #9ca3af !important;
          }
        }
        .bs-input-label-suffix {
          font-weight: $font-weight-normal;
          font-size: 0.625rem;
          line-height: 1rem;
          color: $red-16;
        }
        .bs-input-affix-wrapper input {
          color: $blue-15;
        }
      }
      .btn-solid {
        &:disabled {
          border-color: $blue-11;
          background-color: $blue-11;
          opacity: 0.35;
        }
      }
      .sign-up-btn {
        width: 25rem;
        background-color: $blue-11;
        &:hover {
          background-color: $blue-20;
        }
      }
    }
    .privacy-policy-text {
      width: 28.75rem;
      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
        color: $gray-15;
        a {
          color: $blue-11;
        }
      }
    }
  }
  .plan-summary {
    width: 25rem;
    &--logo {
      width: 3.125rem;
      height: 3.125rem;
      background: #ffffff;
      border: 0.0625rem solid #dbeafe;
      border-radius: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &--description {
      h3 {
        line-height: 1.125rem;
      }
      p {
        line-height: 1.25rem;
      }
    }
  }
  .plan-options {
    width: 25rem;
    margin-top: 1.875rem;
    &--select {
      margin-right: 0.875rem;
    }
    .bs-select-box {
      margin-top: 0.3125rem;
      width: 100px;
      height: 2.25rem;
      background: #ffffff;
      border: 0.0625rem solid #e5e7eb;
      border-radius: 0.25rem;

      &:focus {
        border-color: $blue-11;
        box-shadow: 0 0 0 0.0625rem $blue-11;
      }
    }
    .bs-select-box-icon-wrapper {
      i {
        color: #9ca3af;
      }
    }

    .bs-input-number {
      margin-top: 0.3125rem;
      width: 6.25rem;
      height: 2.25rem;
      background: #ffffff;
      border: 0.0625rem solid #e5e7eb;
      border-radius: 0.25rem;
      input {
        height: 2.25rem;
        padding: 0px 1rem;
        &:focus {
          box-shadow: none !important;
        }
      }
    }

    &--name {
      margin-top: 0.3125rem;
      width: 16.875rem;
      height: 2.25rem !important;
      background: #ffffff;
      border: 0.0625rem solid #e5e7eb;
      border-radius: 0.25rem;

      input {
        padding: 0px 1rem;
        &:focus {
          box-shadow: none !important;
        }
        &:disabled {
          color: #595959;
          background-color: transparent;
          user-select: none;
        }
      }
    }

    &--slots,
    &--name {
      &:focus,
      &:focus-within {
        border-color: $blue-11 !important;
        box-shadow: 0 0 0 1px $blue-11 !important;
      }
    }
  }
  .buy-now-btn {
    width: 25rem;
    background-color: $blue-11;
    &:hover {
      background-color: $blue-20;
    }
  }

  .plan-total {
    width: 25rem;
    h1 {
      line-height: 1.25rem;
    }
  }
  .plan-separator {
    width: 25rem;
    height: 1px;
    background: #f3f4f6;
    border-radius: 4px;
    margin: 20px 0;
  }
  .have-adiscount-code {
    cursor: pointer;
  }
  .plan-note {
    width: 460px;
    height: 40px;
    margin: 0;
    margin-bottom: 30px;
  }
  .discount-code {
    &--input {
      height: 36px !important;
      border-color: #e5e7eb;
      &:focus,
      &:focus-within {
        border-color: $blue-11 !important;
        box-shadow: 0 0 0 1px $blue-11 !important;
      }
      &.input-error {
        border-color: #fecaca;
      }
      span[role='button'] {
        line-height: 26px;
        font-size: 12px;
        font-weight: $font-weight-medium;
      }
      .icon-loading {
        animation: spin 2s linear infinite;
      }
      input::placeholder {
        color: #d1d5db;
        font-size: 0.75rem;
      }
    }
  }
  .coupon-code {
    &--wrapper {
      width: 25rem;
    }
    &--status {
      width: 100%;
      height: 14px;
      line-height: 14px;
      text-align: right;
      margin: 0;
      margin-top: 5px;
    }
  }
}

.ltd-verification-modal {
  .modal-content {
    max-width: 28.125rem;
    .modal-body {
      padding: 1.875rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.875rem;
      max-height: 90vh;
    }
  }
  &--otp-input {
    width: 13rem !important;
    height: 2.25rem !important;
    background: $gray-19 !important;
    border-radius: 0.25rem !important;
    border: 0.0625rem solid $gray-13 !important;

    &:focus,
    &:focus-within {
      border-color: $blue-11 !important;
      box-shadow: 0 0 0 1px $blue-11 !important;
    }

    .bs-input-number-input-wrap {
      input {
        text-align: center;
        height: 2.25rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $gray-10;
      }
    }

    .bs-input-number-handler-wrap {
      display: none;
    }
  }
  .bs-input-number-error {
    .ltd-verification-modal--otp-input {
      border-color: $red-16 !important;
      &:focus,
      &:focus-within {
        border-color: $red-16 !important;
        box-shadow: 0 0 0 1px $red-16 !important;
      }
    }
  }
  .verification-code {
    font-weight: $font-weight-semibold;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $gray-15;
  }
  .verification-code-timer,
  .verification-code-resend {
    font-weight: $font-weight-normal;
  }
  .verification-code-resend {
    color: $blue-11 !important;
  }
  .verification-submit-btn {
    width: 21.25rem;
    height: 2.5rem;
    &:disabled {
      border-color: $blue-11;
      background-color: $blue-11;
      opacity: 0.35;
    }
    &:hover {
      background-color: $blue-20 !important;
    }
  }
  .verification-error-alert {
    width: 21.25rem;
    height: 1.875rem;
    border: 0.0625rem solid $red-11 !important;
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
    display: flex;
    justify-content: center !important;
    align-items: center;
    .content-div {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        line-height: 0.875rem;
      }
    }
  }
}

.denied-container {
  width: 35.5rem;
  height: 80vh;
  img {
    margin-bottom: 3.125rem;
  }
  .text-center {
    margin-top: 0.75rem;
  }
}

.email-container {
  width: 28.75rem !important;
}
